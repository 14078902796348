export const MAX_DURATION = 3

// Types d'abonnements
const SUBSCRIPTION_TYPE_1 = 'Normal'
const SUBSCRIPTION_TYPE_2 = 'Groupé'
const SUBSCRIPTION_TYPE_3 = 'Pluri-annuel'

export const SUBSCRIPTION_OFFER_1 = 'questionnaire'
export const SUBSCRIPTION_OFFER_2 = 'analyse'

export const SUBSCRIPTION_OFFER_1_A = 'Baromètre'
export const SUBSCRIPTION_OFFER_1_B = 'Impact'
export const SUBSCRIPTION_OFFER_1_C = 'Gouvernance'
export const SUBSCRIPTION_OFFER_1_D = 'Olystic forms'

export const SUBSCRIPTION_OFFER_2_A = 'analytics'
export const SUBSCRIPTION_OFFER_2_B = 'predict'

export const SUBSCRIPTION_TYPES = [
  SUBSCRIPTION_TYPE_1,
  SUBSCRIPTION_TYPE_2,
  SUBSCRIPTION_TYPE_3,
]
