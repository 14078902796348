import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'
import { useMemo } from 'react'
import { SUBSCRIPTION_OFFER_1_D } from '@olystic/common/subscription.mjs'

export function useSubscription() {
  const subscriptionQuery = useQuery(['subscription'], async () => {
    const res = await backend.subscriptions.getSubscription()
    return res.data
  })

  const {
    canCreateBase,
    canCreateGroup,
    isFreeSubscription,
    canCreateQuickFeedback,
    categorieLimit,
  } = useMemo(() => {
    let canCreateBase,
      canCreateGroup,
      isFreeSubscription,
      canCreateQuickFeedback,
      categorieLimit
    if (subscriptionQuery.data) {
      const options = subscriptionQuery.data.level.options
      canCreateBase = options.rh.multiple
      canCreateGroup = options.rh.groupe
      canCreateQuickFeedback = options.suivi.quickFeedback
      categorieLimit = options.rh.categorieLimit
      isFreeSubscription =
        subscriptionQuery.data.level.title === SUBSCRIPTION_OFFER_1_D
    }
    return {
      canCreateBase,
      canCreateGroup,
      isFreeSubscription,
      canCreateQuickFeedback,
      categorieLimit,
    }
  }, [subscriptionQuery])

  return {
    subscriptionQuery,
    canCreateBase,
    canCreateGroup,
    canCreateQuickFeedback,
    categorieLimit,
    isFreeSubscription,
  }
}
