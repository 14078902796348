import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
// Components
import { Formik } from 'formik'
import * as yup from 'yup'
import { Card, Form } from 'react-bootstrap'
import { downloadTxtFile } from '../../../../lib/functions'
import { messages } from './default-messages'
import { useAuth } from '@olystic/olysticapp'
import { EMAIL_CONTEXT_ANNONCE } from '../../constant'
import useEmailData from '../../../../hooks/useEmailData'
import { SubmitButton, notification } from '@olystic/design_system_3'
// Translation
import { useTranslation } from 'react-i18next'
import HeaderCommMsg from './HeaderCommMsg'
import MessageCommMsg from './MessageCommMsg'
import ObjectCommMsg from './ObjectCommMsg'

const updateMessageSchema = yup.object().shape({
  object: yup
    .string()
    .min(3, 'component.communicationMessageCard.form.object.error.min')
    .required('component.communicationMessageCard.form.object.error.required'),
  message: yup
    .string()
    .min(3, 'component.communicationMessageCard.form.message.error.min')
    .required('component.communicationMessageCard.form.message.error.required'),
})

CommunicationMessageCard.propTypes = {
  activeView: PropTypes.number.isRequired,
}

export default function CommunicationMessageCard({ activeView }) {
  const { perimeter, user } = useAuth()
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [object, setObject] = useState(null)
  const [message, setMessage] = useState(null)
  const { email, isLoading, createEmail, updateEmail } = useEmailData(
    activeView,
    perimeter
  )

  useEffect(() => {
    if (
      user.role === 'root' ||
      (user.role === 'admin' && activeView === EMAIL_CONTEXT_ANNONCE)
    ) {
      setIsEditable(true)
    } else {
      setIsEditable(false)
      setEditMode(false)
    }
  }, [activeView, user.role])

  const initialValues = useMemo(() => {
    return {
      object: email ? email.obj : messages[activeView].object,
      message: email ? email.html : messages[activeView].message,
    }
  }, [activeView, email])

  async function handleCopy() {
    navigator.clipboard &&
      navigator.clipboard
        .writeText(message)
        .then(() =>
          notification.success(
            t('component.communicationMessageCard.copy'),
            'lg'
          )
        )
        .catch(() => notification.error(t('common.error'), 'lg'))
  }

  function handleSubmit(values, actions) {
    if (email && email.context === 'perimeter') {
      const changes = {}
      if (values.object !== initialValues.object) {
        changes.object = values.object
      }
      if (values.message !== initialValues.message) {
        changes.message = values.message
      }
      if (Object.keys(changes).length > 0) {
        const newEmail = {
          ...email,
          obj: changes.object,
          html: changes.message,
        }
        updateEmail.mutateAsync(newEmail)
      }
    } else {
      const payload = {
        type: activeView,
        obj: values.object,
        html: values.message,
        language: 'fr',
        context: 'perimeter',
        contextRef: perimeter._id,
      }
      createEmail.mutateAsync(payload)
    }
    setEditMode(false)
    actions.setSubmitting(false)
  }

  return (
    <div>
      <Card className="color-card border-info px-6 py-3">
        <HeaderCommMsg
          editMode={editMode}
          onChangeEdit={() => setEditMode(!editMode)}
          isEditable={isEditable}
          onCopy={handleCopy}
          onDownload={() =>
            downloadTxtFile(`Objet:\n\n${object}\n\nMessage:\n\n${message}`)
          }
          auto={activeView !== EMAIL_CONTEXT_ANNONCE}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={updateMessageSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <ObjectCommMsg
                editMode={editMode}
                onChangeObject={(text) => setObject(text)}
                isLoading={isLoading}
              />
              <MessageCommMsg
                editMode={editMode}
                onChangeMessage={(text) => setMessage(text)}
              />
              {editMode && (
                <div className="d-flex justify-content-end mt-3">
                  <SubmitButton>
                    {t('component.communicationMessageCard.form.submit')}
                  </SubmitButton>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}
