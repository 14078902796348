import Center from "../Layout/Center"
import { Spinner } from "react-bootstrap"
import React from "react"

export const withItemList =
  (Item) =>
  ({ items, onEdit, onDelete, isLoading, onEditName }) => {
    if (isLoading)
      return (
        <Center>
          <Spinner animation={"border"} />
        </Center>
      )
    return (
      <div  className="my-6 d-flex gap-4 flex-wrap">
        {items.map((item) => (
          <div key={item._id}>
            <Item base={item} onEdit={onEdit} onDelete={onDelete} onEditName={onEditName} />
          </div>
        ))}
      </div>
    )
  }
