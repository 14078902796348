import { http } from "@olystic/olysticapp"
import { api } from "./constant"

const subscriptions = {
  async getSubscription() {
    return await http.get(`${api}/subscription`)
  },
}

export default subscriptions
