import React from 'react'
import { useCommunication } from '../../../../hooks/useCommunication'
import { Card } from 'react-bootstrap'
import Loading from '../../../../components/loading/loading'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import NoData from '../../../../components/NoData/NoData'

const getPath = (path) => {
  if (path)
    return new URL(
      `${window.location.protocol}//${window.location.hostname}/scheduler/${path}`
    ).toString()
}

export default function DownloadTemplateCard() {
  const { t } = useTranslation()
  const { communicationQuery } = useCommunication()

  if (communicationQuery.isLoading) return <Loading />

  return (
    <Card>
      <Card.Body className="py-5 px-9">
        <h3 className="text-center mt-5 mb-7">
          {t('component.downloadTemplateCard.title')}
        </h3>
        {communicationQuery.data.length === 0 ? (
          <div className="w-xxl-75 mx-auto my-3">
            <NoData
              title={t('component.downloadTemplateCard.noData.title')}
              text={t('component.downloadTemplateCard.noData.text')}
            />
          </div>
        ) : (
          <div className="d-flex flex-wrap gap-4 mt-8 px-7">
            {communicationQuery.data.map((communication) => (
              <div
                key={communication._id}
                onClick={() =>
                  window.open(getPath(communication.link), '_blank')
                }
                className="cursor-pointer d-flex align-items-center flex-column scale-hover"
                style={{ width: '10rem' }}
              >
                <FontAwesomeIcon
                  icon={faFileArrowDown}
                  size="4x"
                  className="text-primary"
                />
                <p className="fs mt-2 text-center">{communication.title}</p>
              </div>
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
