import React from 'react'
import PropTypes from 'prop-types'
// Components
import { Card } from 'react-bootstrap'
import { downloadURI } from '../../../../lib/functions'
// Icons
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons'
import { ReactComponent as IconExcel } from '../../../../assets/icons/icon-excel.svg'
import { useTranslation, Trans } from 'react-i18next'
import { ButtonWithIcon } from '@olystic/design_system_3'

export default function ImportModel({ goNextStep }) {
  const { t } = useTranslation()
  const handleDownloadModel = () => {
    // eslint-disable-next-line no-undef
    downloadURI(`${process.env.PUBLIC_URL}/sondés.xlsx`, 'sondés.xlsx')
    goNextStep()
  }

  return (
    <div className=''>
      <h4>{t('component.importModel.summary')}</h4>
      <ul className='ps-4 text-black-50 mt-3'>
        <li className='marker-primary mb-3'>
          <Trans i18nKey='component.importModel.description1'>
            Pour plus de facilité, conservez le nom des variables dans la
            première ligne. <br />
            Les variables présentes dans le fichier sont celles acceptées par
            OLYSTIC. <br />
            <b>
              Remplissez uniquement celles qui vous sont nécessaires pour vos
              questionnaires.
            </b>
          </Trans>
        </li>
        <li className='marker-primary mb-3'>
          {t('component.importModel.description2')}
        </li>
        <li className='marker-primary mb-4'>
          <Trans i18nKey='component.importModel.description3'>
            <b>Bonnes pratiques</b> : limitez le nombre de modalités des
            variables comme les directions, les sites, les métiers. Un nombre de
            15 modalités est une limite haute selon nous. Vérifiez que les
            modalités de réponses forment des groupes de collaborateurs aux
            effectifs relativement homogènes (par exemple, évitez une
            répartition de la sorte : 124 cadres d’un côté et 12 non-cadres de
            l’autre.)
          </Trans>
        </li>
      </ul>
      <Card className='bg-light'>
        <Card.Body className='center flex-column gap-3'>
          <IconExcel />
          <ButtonWithIcon
            icon={faArrowToBottom}
            onClick={handleDownloadModel}
            className='mx-auto'
          >
            {t('component.importModel.cta1')}
          </ButtonWithIcon>
          <p className='fs '>
            <a
              className='text-blue-300'
              href='./ImportModel#'
              onClick={(e) => {
                e.preventDefault()
                goNextStep()
              }}
            >
              {t('component.importModel.cta2')}
            </a>
          </p>
        </Card.Body>
      </Card>
    </div>
  )
}

ImportModel.propTypes = {
  goNextStep: PropTypes.func,
}
