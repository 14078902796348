import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '../services/backend'
import { notification } from '@olystic/design_system_3'
import { transformRH } from '../features/RH/components/RHTable/rhTransform'
// Translation
import { useTranslation } from 'react-i18next'

export default function useRHData(id) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const respondents = useQuery(['respondent', 'list', id], async () => {
    const resp = await backend.rh.getRespondents(id)
    const base = await backend.rh.getBase(id)
    return transformRH(base, resp)
  })

  const createRespondent = useMutation(
    (resp) => backend.rh.createRespondent(id, resp),
    {
      onSettled: () =>
        queryClient.invalidateQueries(['respondent', 'list', id]),
      onError: (error) => {
        if (error.response) {
          const body = error.response.data
          if (body?.error === 'exists')
            return notification.error(t('notification.exist'), 'lg')
        }
        notification.error(t('notification.exist'), 'lg')
      },
    },
  )

  const addRespondents = useMutation(
    (idList) => backend.rh.addRespondents(id, idList),
    {
      onSettled: () =>
        queryClient.invalidateQueries(['respondent', 'list', id]),
      onError: () => {
        notification.error(t('notification.exist'), 'lg')
      },
    },
  )

  const updateRespondent = useMutation(
    (resp) => backend.rh.updateRespondent(resp),
    {
      onSettled: () =>
        queryClient.invalidateQueries(['respondent', 'list', id]),
      onError: () => {
        notification.error(t('notification.exist'), 'lg')
      },
    },
  )

  const deleteRespondent = useMutation(
    (respId) => backend.rh.deleteRespondent(respId),
    {
      onSettled: () =>
        queryClient.invalidateQueries(['respondent', 'list', id]),
      onError: () => {
        notification.error(t('notification.exist'), 'lg')
      },
    },
  )

  const deleteManyRespondent = useMutation(
    (respIds) => backend.rh.deleteManyRespondent(id, respIds),
    {
      onSettled: () =>
        queryClient.invalidateQueries(['respondent', 'list', id]),
      onError: () => {
        notification.error(t('notification.exist'), 'lg')
      },
    },
  )

  return {
    respondents,
    createRespondent,
    updateRespondent,
    deleteRespondent,
    deleteManyRespondent,
    addRespondents,
  }
}
