import React from 'react'
// Components
import Section from '../components/layout/section'
import CommNavigation from '../features/communication/components/CommNavigation/CommNavigation'
import GoodPraticeExampleCard from '../features/communication/components/GoodPraticeExampleCard/GoodPraticeExampleCard'
import CommunicationMessageCard from '../features/communication/components/CommunicationMessageCard/CommunicationMessageCard'
import HelpingCommunicationCard from '../features/communication/components/HelpingCommunicationCard/HelpingCommunicationCard'
// Translation
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import DownloadTemplateCard from '../features/communication/components/DownloadTemplateCard/DownloadTemplateCard'

export default function Communications() {
  const { t } = useTranslation()
  const [activeView, setActiveView] = React.useState('helper')

  const handleSelectionView = (viewId) => {
    setActiveView(viewId)
  }

  return (
    <>
      <Section className="mt-8">
        <h3>{t('page.communication.title')}</h3>
        <p className="text-width">{t('page.communication.description')}</p>
        <Row className="mt-5">
          <Col sm={12} xxl={4}>
            <CommNavigation
              activeView={activeView}
              onSelectionView={handleSelectionView}
            />
          </Col>
          <Col xxl={8}>
            <div className="mb-4">
              <HelpingCommunicationCard view={activeView} />
            </div>
            {activeView === 'helper' && <GoodPraticeExampleCard />}
            {activeView === 'download' && <DownloadTemplateCard />}
            {activeView !== 'helper' && activeView !== 'download' && (
              <CommunicationMessageCard activeView={activeView} />
            )}
          </Col>
        </Row>
      </Section>
    </>
  )
}
