import moment from 'moment'
import { fixRelance } from './date/fixRelance'

export const datesToSurveyEvent = (events) => {
  const dates = fixRelance(events)
  const sortedEvents = Object.entries(dates).sort((a, b) =>
    moment(a[1]).diff(moment(b[1]))
  )

  let formatEvents = []
  let n = 1

  for (let event of sortedEvents) {
    const date = moment(event[1])
    if (date.isValid()) {
      const eventInfo = typeAndLabel(event[0], date, n)

      if (event[0].includes('xcept')) {
        n = n + 1
      }

      formatEvents.push({
        ...eventInfo,
        date,
      })
    } else console.warn('Invalid date for', event)
  }

  return formatEvents
}

const typeAndLabel = (event, date, n) => {
  const today = moment()
  const state = date.isSame(today, 'day')
    ? date.isAfter(today)
      ? 'now'
      : 'past'
    : date.isBefore(today, 'day')
    ? 'past'
    : 'future'

  if (event === 'Lancement')
    return {
      key: event,
      type: 'lancement',
      label: 'Lancement',
      state,
    }

  if (event === 'Clôture')
    return {
      key: event,
      type: 'cloture',
      label: 'Clôture',
      state,
    }

  let matchResult = event.match(/relance(\d)/i)
  if (matchResult)
    return {
      key: event,
      type: 'relance',
      label: `Relance ${matchResult[1]}`,
      no: parseInt(matchResult[1]),
      state,
    }

  if (event.includes('xcept'))
    return {
      key: `RelanceExceptionel${n}`,
      type: 'except',
      label: `Relance exceptionnelle ${n}`,
      no: parseInt(n),
      state,
    }

  return {
    key: event,
    type: event,
    label: event[0].toUpperCase() + event.slice(1),
    state,
  }
}

export function formatDates(dates) {
  let formatDates = {}
  dates.forEach((event) => {
    formatDates = {
      ...formatDates,
      [event.key]: event.date.format(),
    }
  })
  return formatDates
}

export function translateSurveyEvent(t, event) {
  return `${t(`events.${event.type}`, { number: event?.no })}`
}
