import React from 'react'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { Dropdown, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function SelectFilter({ column }) {
  const { t } = useTranslation()
  const { filterValue, setFilter, filterOptions: options } = column

  const handleChange = (option) => {
    let newFilters = new Set()
    if (filterValue) newFilters = new Set(filterValue)
    if (newFilters.has(option)) newFilters.delete(option)
    else newFilters.add(option)
    setFilter(newFilters)
  }

  function isCheck(option) {
    if (!filterValue) return true
    return !filterValue.has(option)
  }

  function allChecked() {
    return !filterValue?.size
  }

  function selectAll() {
    if (allChecked()) setFilter(new Set(options))
    else setFilter(new Set())
  }

  return (
    <Dropdown as='div' autoClose='outside' drop='down-centered'>
      <Dropdown.Toggle
        as='div'
        className='p-0 border-0 h-100 cursor-pointer text-secondary'
      >
        <FontAwesomeIcon icon={faCaretDown} />
      </Dropdown.Toggle>

      <Dropdown.Menu className='overflow-auto' style={{ maxHeight: '15rem' }}>
        <Dropdown.Item
          onClick={() => selectAll()}
          className='d-flex align-items-center justify-content-start gap-2 cursor-pointer py-3'
        >
          <Form.Check
            checked={allChecked()}
            className='cursor-pointer ps-5'
            style={{ zIndex: -1 }}
            label={'Tous'}
          />
        </Dropdown.Item>
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleChange(option)}
            className='d-flex align-items-center justify-content-start gap-2 cursor-pointer py-3'
          >
            <Form.Check
              checked={isCheck(option)}
              className='cursor-pointer ps-5'
              style={{ zIndex: -1 }}
              label={t(option)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
