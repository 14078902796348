import { http } from '@olystic/olysticapp'
import { gcV2 } from './constant'

const rh = {
  async addRespondents(id, idList) {
    const resp = await http.post(`${gcV2}/rh/${id}/respondents/addMany`, idList)
    return resp.data
  },
  async getAllBase() {
    const resp = await http.get(`${gcV2}/rh`)
    return resp.data
  },
  async getBase(id) {
    const resp = await http.get(`${gcV2}/rh/${id}`)
    return resp.data
  },
  async getBaseGroups(id) {
    const resp = await http.get(`${gcV2}/rh/${id}/groups`)
    return resp.data
  },
  async createBaseGroups(id, groupSpec) {
    const resp = await http.post(`${gcV2}/rh/${id}/groups`, groupSpec)
    return resp.data
  },
  async createBase(base) {
    const resp = await http.post(`${gcV2}/rh`, base)
    return resp.data
  },
  async updateBase(base) {
    const resp = await http.patch(`${gcV2}/rh/${base._id}`, base)
    return resp.data
  },
  async deleteBase(base) {
    const resp = await http.delete(`${gcV2}/rh/${base._id}`)
    return resp.data
  },
  async getRespondents(baseId) {
    if (!baseId) return []
    const resp = await http.get(`${gcV2}/rh/${baseId}/respondents`)
    return resp.data
  },
  async createRespondent(baseId, respondent) {
    const res = await http.post(`${gcV2}/rh/${baseId}/respondents`, respondent)
    return res.data
  },
  async updateRespondent(respondent) {
    const res = await http.put(
      `${gcV2}/rh/respondents/${respondent._id}`,
      respondent,
    )
    return res.data
  },
  async deleteManyRespondent(baseId, respondentIds) {
    const res = await http.delete(`${gcV2}/rh/${baseId}/respondents`, {
      data: respondentIds,
    })
    return res.data
  },

  async getRespondentCount(id) {
    const res = await http.get(`${gcV2}/rh/${id}/respondents/count`)
    return res.data
  },
  async importStatus(status) {
    if (!status.id) return status
    const res = await http.post(`${gcV2}/rh/import/job-status`, status)
    return res.data
  },
  async getQuestionnaireLink(surveyId, respondentId) {
    const res = await http.post(`${gcV2}/rh/questionnaire`, {
      surveyId,
      respondentId,
    })
    return res.data
  },
  async getEmail(type, surveyId, respondentIds) {
    const params = new URLSearchParams({ type, surveyId, respondentIds })
    const res = await http.get(`${gcV2}/rh/email?${params}`)
    return res.data
  },
  async sendEmail(email) {
    const res = await http.post(`${gcV2}/rh/email`, email)
    return res.data
  },
  async getSuivi(respondentId) {
    const params = new URLSearchParams({ id: respondentId })
    const res = await http.get(`${gcV2}/rh/suivi?${params}`)
    return res.data
  },
  async setSuivi(respondentId, state) {
    const res = await http.post(`${gcV2}/rh/suivi`, { respondentId, state })
    return res.data
  },
  async getLinks(respondentIds, surveyId) {
    const res = await http.post(`${gcV2}/rh/liens`, { respondentIds, surveyId })
    return res.data
  },
}

export default rh
