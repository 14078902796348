import React from 'react'
import {Spinner} from 'react-bootstrap'

function Loading(props) {
  return (
      <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
        <Spinner animation={'border'}/>
      </div>
  )
}

export default Loading
