import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
// Components
import { Button, Form, Row, Modal, Col } from 'react-bootstrap'
import {
  datesToSurveyEvent,
  formatDates,
  translateSurveyEvent,
} from '../../../lib/surveyEvents'
import moment from 'moment'
import { formatISO } from 'date-fns/esm'
import backend from '../../../services/backend'
import { useSelector } from 'react-redux'
import { IconButton, notification } from '@olystic/design_system_3'
import { surveySelector } from '../../../redux/reducers/surveys'
import { TYPE_INDIVIDUELLE } from '@olystic/common/survey.mjs'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

export default function UpdateSurveyDatesModal({ show, onHide, surveyId }) {
  const { t } = useTranslation()
  const survey = useSelector((state) =>
    surveySelector.selectById(state, surveyId),
  )

  const { dates } = survey
  const [relance, setRelance] = useState('')
  const [addRelance, setAddRelance] = useState(false)
  const [errors, setErrors] = useState({})
  const [sortedDates, setSortedDates] = useState(datesToSurveyEvent(dates))

  const clotureDate = useMemo(() => {
    return sortedDates.find((d) => d.key === 'Clôture').date
  }, [sortedDates])

  const canAddRelance = useMemo(() => {
    const relanceExeptionnelleKeys = sortedDates.filter((date) =>
      date.key.startsWith('RelanceExcepti'),
    )
    return relanceExeptionnelleKeys.length < 3
  }, [sortedDates])

  function handleChangeDate(e, key) {
    const value = e.currentTarget.value
    let newErrors = { ...errors }
    let cloture = { ...clotureDate }
    const format = formatDates(sortedDates)
    const dayOfWeek = moment(value).day()

    const time = format[key].split('T')

    const isSame = sortedDates.some((d) => d.date.isSame(moment(value), 'day'))

    if (moment(value).isBefore(moment())) {
      newErrors[key] = t('component.updateSurveyDatesModal.error.after_today')
    } else if (
      key !== 'Clôture' &&
      moment(value).isAfter(moment(cloture).subtract(1, 'days'))
    ) {
      newErrors[key] = t(
        'component.updateSurveyDatesModal.error.before_closing',
      )
    } else if (isSame) {
      newErrors[key] = t(
        'component.updateSurveyDatesModal.error.events_same_day',
      )
    } else if (dayOfWeek === 0 || dayOfWeek === 6) {
      newErrors[key] = t('component.updateSurveyDatesModal.error.weekend')
    } else {
      delete newErrors[key]
      setSortedDates(
        datesToSurveyEvent({ ...format, [key]: value + 'T' + time[1] }),
      )
    }

    setErrors(newErrors)
  }

  function handleRelance(e) {
    const value = e.currentTarget.value
    let cloture = { ...clotureDate }
    let newErrors = { ...errors }
    const dayOfWeek = moment(value).day()
    const key = 'Relance exceptionnelle'
    const isSame = sortedDates.some((d) => d.date.isSame(moment(value), 'day'))
    if (moment(value).isBefore(moment())) {
      newErrors[key] = 'component.updateSurveyDatesModal.error.after_today'
    } else if (moment(value).isAfter(moment(cloture).subtract(1, 'days'))) {
      newErrors[key] = 'component.updateSurveyDatesModal.error.before_closing'
    } else if (isSame) {
      newErrors[key] = 'component.updateSurveyDatesModal.error.events_same_day'
    } else if (dayOfWeek === 0 || dayOfWeek === 6) {
      newErrors[key] = t('component.updateSurveyDatesModal.error.weekend')
    } else {
      delete newErrors[key]
      setRelance(value)
    }

    setErrors(newErrors)
  }

  function handleAddRelance() {
    if (relance) {
      const format = formatDates(sortedDates)
      const key = `relance exceptionelle`
      setSortedDates(
        datesToSurveyEvent({
          ...format,
          [key]: relance + 'T10:00:00+01:00',
        }),
      )
      setAddRelance(false)
      setRelance('')
    }
  }

  function handleDelete(key) {
    const format = formatDates(sortedDates)
    delete format[key]
    setSortedDates(datesToSurveyEvent(format))
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const surveyId = survey._id

    const dates = formatDates(sortedDates)

    Object.keys(dates).forEach((key) => {
      dates[key] = formatISO(new Date(dates[key]))
    })

    try {
      await backend.surveys.updateSurvey(surveyId, { dates })
      notification.success(t('notification.change_dates'), 'lg')
    } catch (e) {
      console.error(e)
      notification.error(t('notification.error'), 'lg')
    }

    onHide()
  }

  return (
    <Modal centered show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <FontAwesomeIcon icon={faCalendarAlt} className='me-2' />
            {t('component.updateSurveyDatesModal.title')}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='' onSubmit={(e) => handleSubmit(e)}>
          {survey.questionnaire.type !== TYPE_INDIVIDUELLE && (
            <p className='text-danger mb-4'>
              {t('component.updateSurveyDatesModal.info')}
            </p>
          )}

          <p className='text-decoration-underline'>
            {t('component.updateSurveyDatesModal.dates')}
          </p>
          <div className='d-flex flex-wrap align-items-center justify-content-between gap-3 mb-5'>
            {sortedDates.map((date) => (
              <Form.Group
                as={Row}
                key={date.key}
                controlId={date.key}
                className='align-items-center'
              >
                <Form.Label
                  column
                  className='fs col-sm-6'
                  style={{ width: '10rem' }}
                >
                  {translateSurveyEvent(t, date)} :
                </Form.Label>
                <Col sm={6} className='gap-2 center'>
                  <Form.Control
                    type='date'
                    isInvalid={errors[date.key]}
                    name={date.key}
                    value={date.date.format('YYYY-MM-DD')}
                    onChange={(e) => handleChangeDate(e, date.key)}
                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                    disabled={date.date.isBefore(moment())}
                  />

                  <div style={{ width: '2rem' }}>
                    {date.key !== 'Clôture' && date.date.isAfter(moment()) && (
                      <IconButton
                        icon={faTrashAlt}
                        className='text-danger '
                        onClick={() => handleDelete(date.key)}
                      />
                    )}
                  </div>

                  <Form.Control.Feedback type='invalid'>
                    {t(errors[date.key])}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            ))}
          </div>

          {survey.questionnaire.type !== TYPE_INDIVIDUELLE && canAddRelance && (
            <>
              <p className='text-decoration-underline'>
                {' '}
                {t('component.updateSurveyDatesModal.form.title')}
              </p>
              <Row
                className='mb-3 align-items-center ml-0'
                controlId={'relance-exceptionnelle'}
              >
                <Col sm={5}>
                  <Form.Check
                    id='relance-exceptionnelle'
                    name='relance-exceptionnelle'
                    className='mb-0 fs'
                    checked={addRelance}
                    onChange={() => setAddRelance(!addRelance)}
                    label={t('component.updateSurveyDatesModal.form.label')}
                  />
                </Col>

                <Col>
                  <Form.Control
                    name='relance-exceptionnelle'
                    isInvalid={errors['Relance exceptionnelle']}
                    value={relance}
                    onChange={(e) => handleRelance(e)}
                    disabled={!addRelance}
                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                    max={moment({ ...clotureDate })
                      .subtract(1, 'days')
                      .format('YYYY-MM-DD')}
                    type='date'
                  />
                  <Form.Control.Feedback type='invalid'>
                    {t(errors['Relance exceptionnelle'])}
                  </Form.Control.Feedback>
                </Col>

                <Col>
                  <Button
                    className='ml-3'
                    onClick={handleAddRelance}
                    disabled={!addRelance}
                  >
                    {t('common.add')}
                  </Button>
                </Col>
              </Row>
            </>
          )}

          <div className='mt-5 d-flex gap-3 justify-content-end'>
            <Button variant='secondary' className='mr-3' onClick={onHide}>
              {t('common.cancel')}
            </Button>
            <Button type='submit' disabled={Object.keys(errors).length !== 0}>
              {t('common.apply')}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

UpdateSurveyDatesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
}
