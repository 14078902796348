import React from 'react'
import { useSubscription } from '../hooks/useSubscription'
import Section from '../components/layout/section'
import FormFeedback from '../features/feedback/FeedbackForm/Form'
import { Card } from 'react-bootstrap'
import { SubscriptionBody } from '@olystic/design_system_3'

export default function QuickFeedback() {
  const { isFreeSubscription } = useSubscription()

  return (
    <Section className='my-8'>
      {isFreeSubscription ? (
        <Card className='py-7'>
          <SubscriptionBody />
        </Card>
      ) : (
        <FormFeedback />
      )}
    </Section>
  )
}
