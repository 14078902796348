import React from "react"
import { surveyType } from "@olystic/common/survey.mjs"
// Translation
import { useTranslation } from "react-i18next"

export default function Footer() {
  const { t } = useTranslation()
  const types = surveyType()
  return (
    <div className="fs px-5 mb-4">
      <p className="text-white">{t("component.groundControlPanel.footer.text")}</p>
      <div className="">
        {types.map((type) => (
          <div key={type.id} className="d-flex align-items-center mb-2 gap-2 ms-3">
            <span className={`p-1 rounded-circle bg-${type.color}`} />
            <p className="text-white m-0">{t(`survey.types.${type.title}`)}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
