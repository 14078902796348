import React from 'react'
import PropTypes from 'prop-types'
// Redux
import { useDispatch } from 'react-redux'
import { removeMany, removeOne } from '../../../redux/reducers/waitingSurveys'
import backend from '../../../services/backend'
// Components
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { fixRelance } from '../../../lib/date/fixRelance'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

export default function ConfirmValidationModal({
  show,
  onHide,
  validatedSurveys,
  deletedSurveys,
  closeProgrammingModal,
  onCountSurveys,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const surveysCount = {
    validSurveys: validatedSurveys.length,
    deletedSurveys: deletedSurveys.length,
  }

  const handleClick = () => {
    if (deletedSurveys.length) {
      const ids = deletedSurveys.map((s) => s.id)
      dispatch(removeMany(ids))
    }

    validatedSurveys.forEach(async (survey) => {
      try {
        await backend.surveys.createSurvey({
          name: survey.name,
          questionnaireId: survey.questionnaireId,
          dates: fixRelance(survey.dates),
          respondentGroup: survey.respondentGroup._id,
          completion: survey.type === 'Individuelle' ? 0.3 : 0.75,
        })

        dispatch(removeOne(survey.id))
      } catch (error) {
        console.error(error)
      }
    })

    onHide()
    closeProgrammingModal()
    onCountSurveys(surveysCount)
  }

  return (
    <Modal size='xs' centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('component.confirmValidationModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='d-flex align-items-center justify-content-center mb-3'>
          <Col sm={3} className='text-end'>
            <FontAwesomeIcon icon={faCheck} className='text-success' />
          </Col>
          <Col>
            <p className='m-0 fs'>
              {t('component.confirmValidationModal.validSurvey', {
                count: surveysCount.validSurveys,
              })}
            </p>
          </Col>
        </Row>
        <Row className='d-flex align-items-center justify-content-center'>
          <Col sm={3} className='text-end'>
            <FontAwesomeIcon icon={faTimes} className='text-danger fa-lg' />
          </Col>
          <Col>
            <p className='m-0 fs'>
              {t('component.confirmValidationModal.deleteSurvey', {
                count: surveysCount.deletedSurveys,
              })}
            </p>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button className='m-auto' onClick={handleClick}>
          {t('component.confirmValidationModal.cta')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmValidationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  validatedSurveys: PropTypes.arrayOf(PropTypes.object).isRequired,
  deletedSurveys: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeProgrammingModal: PropTypes.func.isRequired,
  onCountSurveys: PropTypes.func.isRequired,
}
