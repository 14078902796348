import React from "react"
import PropTypes from "prop-types"
// Components
import { LineChart, Line, YAxis, XAxis, CartesianGrid, ResponsiveContainer, Tooltip, ReferenceLine } from "recharts"
// Translation
import { useTranslation } from "react-i18next"

/* eslint-disable react/destructuring-assignment */
function CustomizedDot(node) {
  return (
    <g transform={`translate(${node.cx} ${node.cy || -10})`}>
      <circle r="8" fill="white" />
      <circle r="6" fill={node.fill} />
      <circle r="3" fill="white" />
      <circle r="1" fill={node.fill} />
    </g>
  )
}

/* eslint-disable react/prop-types */
function CustomTooltip({ active, payload, extraLabel }) {
  const { t } = useTranslation()
  if (active && payload && payload.length) {
    return (
      <div className="border rounded-3 bg-white py-2 px-3">
        <p className="border-bottom fs-lg mb-2 ps-3">
          {extraLabel} {payload[0].payload.day}
        </p>
        <div className="d-flex align-items-center fs-sm m-1 text-blue-400">
          <p className="m-0">{t("component.participationRate.chart.tooltip", { count: payload[0].value.toFixed(1) })}</p>
        </div>
      </div>
    )
  }

  return null
}

LineChartParticipationRate.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function LineChartParticipationRate({ data, extraLabel }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 50,
          bottom: 30,
          right: 50,
        }}
      >
        <CartesianGrid strokeDasharray="4" vertical={false} />
        <XAxis
          dataKey="label"
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          interval={0}
          tick={{ fontSize: "0.75rem", fontWeight: 400, fill: "#969AA3" }}
        />
        <YAxis
          domain={[0, 100]}
          tickCount={12}
          tickMargin={10}
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: "0.75rem", fontWeight: 400, fill: "#969AA3" }}
        />

        {data.map(
          (d, i) =>
            d?.event && (
              <ReferenceLine
                key={i}
                x={d.label}
                stroke="#202F4D"
                strokeDasharray="10"
                label={{ position: "top", value: d.event, fill: "#202F4D", fontSize: "0.625rem" }}
              />
            )
        )}

        <Line
          dataKey="enterprise"
          stroke="#245185"
          strokeWidth={2}
          fill="#245185"
          dot={<CustomizedDot />}
          isAnimationActive={false}
        />

        <Tooltip cursor={{ stroke: "#202F4D" }} content={<CustomTooltip extraLabel={extraLabel} />} />
      </LineChart>
    </ResponsiveContainer>
  )
}
