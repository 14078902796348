import React from "react"
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine"
import { useTranslation } from "react-i18next"
import { ButtonWithIcon } from "@olystic/design_system_3"

function ImportButton({ onImport, variant = "secondary" }) {
  const { t } = useTranslation()
  return (
    <ButtonWithIcon variant={variant} icon={faArrowUpToLine} onClick={onImport} id="ImportButton">
      {t("common.import")}
    </ButtonWithIcon>
  )
}

export default ImportButton
