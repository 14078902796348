import React from 'react'
import PropTypes from 'prop-types'
// Redux
import { useSelector } from 'react-redux'
import { surveySelector } from '../../redux/reducers/surveys'
// Components
import { Button, Card, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FormatNumber } from '@olystic/design_system_3'
import { getDaysBeforeClosing, qualityColor } from '../../lib/functions'
import { useParticipation } from '../../hooks/useParticipation'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

CurrentRateCard.propTypes = {
  surveyId: PropTypes.string.isRequired,
}

export default function CurrentRateCard({ surveyId }) {
  const { t } = useTranslation()
  const { rate } = useParticipation(surveyId)
  const survey = useSelector((state) =>
    surveySelector.selectById(state, surveyId),
  )
  const { name: title, dates } = survey

  return (
    <Card
      className='position-relative'
      style={{ width: '25rem', height: '8rem' }}
    >
      <Card.Body className='row g-0 p-3 align-items-center'>
        <Col sm={6} className='border-end pe-2 pt-2'>
          <p className='fs-sm m-0'>{t('component.currentRateCard.label1')}</p>
          <p className='fs text-truncate text-primary mb-2'>{title}</p>
          <p className='m-0 fs-sm'>{t('component.currentRateCard.label2')}</p>
          <p className='fs text-primary'>
            {survey?.respondentGroup?.name ||
              t('component.currentRateCard.default_target')}
          </p>
        </Col>
        <Col sm={6} className='ps-1 text-center pt-3'>
          <p className='fs m-0'>{t('component.currentRateCard.label3')}</p>
          <h4 className={`m-0 text-${qualityColor(rate)}`}>
            {FormatNumber(rate, 1, '%')}
          </h4>
          <p className='fs-sm text-secondary'>
            {t('component.currentRateCard.sub', {
              count: getDaysBeforeClosing(dates.Clôture),
            })}
          </p>
        </Col>

        <Link to='/suivi-questionnaires'>
          <Button
            className='position-absolute top-0 end-0 p-0 text-secondary fs-xsm text-decoration-underline'
            variant=''
          >
            {t('component.currentRateCard.cta')}
            <FontAwesomeIcon icon={farExternalLinkAlt} className='fa-xs mx-1' />
          </Button>
        </Link>
      </Card.Body>
    </Card>
  )
}
