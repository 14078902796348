import React, { useState } from "react"
import backend from "../../../../services/backend"
// Hooks
import useModal from "../../../../hooks/useModal"
import { useAuth } from "@olystic/olysticapp"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
//Components
import Date from "../../../../components/render/Date"
import DeleteBaseModal from "../DeleteBaseModal/DeleteBaseModal"
import { Card } from "react-bootstrap"
import { IconButton } from "@olystic/design_system_3"
import BaseName from "../BaseName/BaseName"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers } from "@fortawesome/pro-duotone-svg-icons"
import { faPenToSquare, faTrash } from "@fortawesome/pro-regular-svg-icons"

function RHBaseItem({ base, onEdit, onDelete, onEditName }) {
  const { t } = useTranslation()
  const { perimeter } = useAuth()
  const count = useQuery(["rhbase", base._id, "count"], () =>
    backend.rh.getRespondentCount(base._id)
  )
  const deleteBaseModal = useModal(() => onDelete(base))
  const deletable = base._id !== perimeter.defaultRespondentGroup
  const [editMode, setEditMode] = useState(false)

  return (
    <Card className="scale-hover" style={{ width: "22rem" }}>
      <Card.Header className="border-bottom">
        <div className="d-flex gap-2 fs">
          <FontAwesomeIcon icon={faUsers} />
          <div className="flex-grow-1">
            {t("component.rhBaseItem.nbRespondent_interval", {
              postProcess: "interval",
              count: count?.data?.count || 0,
            })}
          </div>
          <IconButton
            icon={faPenToSquare}
            onClick={() => setEditMode(!editMode)}
          />
          {deletable && onDelete && (
            <IconButton
              icon={faTrash}
              onClick={() => deleteBaseModal.onShow()}
              className="text-danger"
            />
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="center gap-2 p-2">
          <BaseName
            editMode={editMode}
            base={base}
            onEditName={onEditName}
            onChangeEditMode={() => setEditMode(!editMode)}
            onEdit={onEdit}
          />
        </div>
      </Card.Body>
      <Card.Footer className="border-0">
        <div className="text-blue-500 fs text-center">
          {t("component.rhBaseItem.footer")} : <Date>{base.updatedAt}</Date>
        </div>
      </Card.Footer>
      <DeleteBaseModal base={base} {...deleteBaseModal.modalProps} />
    </Card>
  )
}

RHBaseItem.defaultProps = {
  base: {
    id: "-1",
    count: 0,
    name: "--",
    createdAt: "--",
  },
}

export default RHBaseItem
