import {
  EMAIL_CONTEXT_ANNONCE,
  EMAIL_CONTEXT_CLOTURE,
  EMAIL_CONTEXT_LANCEMENT,
  EMAIL_CONTEXT_RELANCE,
} from '../../constant'

export const messages = {
  [EMAIL_CONTEXT_ANNONCE]: {
    object:
      'Lancement d’une consultation de tous collaborateurs sur la qualité de vie au travail chez [ENTREPRISE]',
    message:
      'Madame, Monsieur,\n\n[ENTREPRISE] souhaite s’engager durablement dans une démarche d’amélioration de la qualité de vie au travail de ses salariés, dans la continuité de ses actions relatives à l’environnement, la santé et la sécurité. Il s’agit d’une étape essentielle pour mieux comprendre votre vécu au travail et proposer à terme des solutions pour améliorer le bien-être au travail de l’ensemble des salariés de l’entreprise.\n\nCette mission a été confiée à un cabinet spécialisé et indépendant, OLYSTIC. Constitué d’experts extérieurs à [ENTREPRISE].\n\nLa démarche élaborée garantira la totale confidentialité des informations recueillies par les experts, tout au long de leur mission. Ceux-ci respectent une charte éthique qui les oblige à ne communiquer à l’entreprise que des informations synthétiques et dépouillées de toute possibilité d’identification des auteurs des avis, remarques ou propos exprimés.\n\nLe cabinet OLYSTIC administrera au mois [MOIS] un questionnaire électronique à l’ensemble des salariés. Le questionnaire présente toutes les garanties en matière de respect de l’anonymat et de la confidentialité. Seules des analyses d’ensemble seront restituées.\n\nÀ l’issue de ce travail d’enquête, un rapport de synthèse et un projet de plan d’action seront remis.\n\nNous comptons sur la participation de toutes et de tous à cette démarche de diagnostic.\n\n[Signataire(s)]',
  },
  [EMAIL_CONTEXT_LANCEMENT]: {
    object: 'Lancement du questionnaire',
    message:
      "Bonjour,\nDans le cadre de l'enquête sur {{nom_questionnaire}}, veuillez trouver les informations utiles pour répondre au questionnaire adressé à l'ensemble des salariés de votre entreprise.\n\nLe questionnaire doit être rempli entre le {{Lancement}} et le {{Cloture}} inclus. Il suffit de 10 minutes maximum pour répondre au questionnaire. Pour un traitement fiable de vos réponses, veuillez répondre à l’ensemble des questions.\n\nPour que les résultats de cette enquête quantitative soient significatifs, nous attendons une large participation de toutes et tous, et vous remercions du temps que vous voudrez bien y consacrer.\n\nVous trouverez le questionnaire ici : {{lien}}\n\nTraitement des données\nVos réponses seront traitées en toute confidentialité dans le respect de l'anonymat et de notre obligation de secret professionnel. Le questionnaire Internet est hébergé sur un site externe et sécurisé et offre toutes les garanties en matière de protection de données. OLYSTIC s’engage à restituer les résultats de telle sorte que l’anonymat soit strictement respecté.\n\nL'équipe d'OLYSTIC\nEn cas de difficulté, vous pouvez joindre OLYSTIC à l'adresse suivante : support@olystic.zendesk.com",
  },
  [EMAIL_CONTEXT_RELANCE]: {
    object: 'Relances',
    message:
      "Bonjour,\nIl vous reste quelques jours pour participer à l'enquête {{nom_questionnaire}}.\n\nVeuillez trouver les informations utiles pour répondre au questionnaire adressé à l'ensemble des salariés de votre entreprise.\n\nLe questionnaire doit être rempli entre le {{Lancement}} et le {{Cloture}} inclus.  Il suffit de 10 minutes maximum pour répondre au questionnaire.\nPour un traitement fiable de vos réponses, veuillez répondre à l’ensemble des questions.\nPour que les résultats de cette enquête quantitative soient significatifs, nous attendons une large participation de toutes et tous, et vous remercions du temps que vous voudrez bien y consacrer.\nVous trouverez le questionnaire ici : {{lien}}\nTraitement des données\nVos réponses seront traitées en toute confidentialité dans le respect de l'anonymat et de notre obligation de secret professionnel. Le questionnaire Internet est hébergé sur un site externe et sécurisé et offre toutes les garanties en matière de protection de données. OLYSTIC s’engage à restituer les résultats de telle sorte que l’anonymat soit strictement respecté.\nL'équipe d'OLYSTIC\nEn cas de difficulté, vous pouvez joindre OLYSTIC à l'adresse suivante : support@olystic.zendesk.com",
  },
  [EMAIL_CONTEXT_CLOTURE]: {
    object: 'Clôture du questionnaire',
    message:
      "Bonjour,\n\nL'enquête {{nom_questionnaire}} est maintenant clôturée.\n\nNous vous remercions pour votre participation.\n\nLe taux de réponses s’établit à {{taux_reponse}}.\n\nIl s’agit d’une base robuste pour travailler à l’amélioration de votre organisation.\n\nDans les prochaines semaines, votre direction ou vos managers vous informeront des résultats du questionnaire et des actions mises en place.\n\nBonne journée,\nL’équipe OLYSTIC",
  },
}
