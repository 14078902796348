import React, { createContext, useState } from "react"

export const SidePanelContext = createContext()

export const SMALL_SCREEN = window.innerWidth < 1200

export default function SidePanelProvider({ children }) {
  const [open, setOpen] = useState(!SMALL_SCREEN)

  const toggleSidePanel = () => {
    setOpen(!open)
  }

  return (
    <SidePanelContext.Provider value={{ open, toggleSidePanel }}>
      {children}
    </SidePanelContext.Provider>
  )
}