import { createEntityAdapter, createSlice, createSelector } from "@reduxjs/toolkit"
import moment from "moment"

/*
 Creation d'un Survey pour l'utilisateur courant
 renvois 201 et le perimetre complet mis a jour avec le nouveau survey
 prend un objet de la forme suivante:
 {
    name: string,   (REQUIS)
    questionnaireId: id du modele pour la creation du questionnaire, (REQUIS)
    dates: {
       Lancement: string,
    }, (REQUIS)
    respondents: liste de respondents (OPTION)
 }
*/

const surveyAdapter = createEntityAdapter({
  selectId: (survey) => survey._id,
})

const surveySlice = createSlice({
  name: "surveys",
  initialState: surveyAdapter.getInitialState({ loading: "void" }),
  reducers: {
    addOne: surveyAdapter.addOne,
    addMany: surveyAdapter.addMany,
    setAll: surveyAdapter.setAll,
    removeOne: surveyAdapter.removeOne,
    removeMany: surveyAdapter.removeMany,
    updateOne: surveyAdapter.updateOne,
    updateMany: surveyAdapter.updateMany,
    upsertOne: surveyAdapter.upsertOne,
    upsertMany: surveyAdapter.upsertMany,
  },
})

export const { addOne, addMany, setAll, removeOne, removeMany, updateOne, updateMany, upsertOne, upsertMany } =
  surveySlice.actions

const selectActiveSurveys = createSelector(
  (state) => surveySelector.selectAll(state),
  (surveys) => surveys.filter((s) => moment.utc().isBetween(s.dates.Lancement, s.dates.Clôture))
)

const selectPlannedAndActiveSurveys = createSelector(
  (state) => surveySelector.selectAll(state),
  (surveys) => surveys.filter((s) => moment.utc().isBefore(s.dates.Clôture))
)

export const surveySelector = {
  ...surveyAdapter.getSelectors((state) => state[surveySlice.name]),
  selectActiveSurveys,
  selectPlannedAndActiveSurveys
}

export default surveySlice.reducer
