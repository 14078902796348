import React from 'react'
import PropTypes from 'prop-types'
// Components
import { Outlet, useLocation } from 'react-router-dom'
import Panel from '../../features/navigation/menu/panel'
import {
  NavBar,
  NotificationContext,
  useFeedbackUser,
} from '@olystic/design_system_3'
import MainLayout from './mainLayout'
import { useAuth } from '@olystic/olysticapp'
// Translation
import { useTranslation } from 'react-i18next'

export default function GroundControlPage() {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { toggleShowModal } = useFeedbackUser()
  const { logout, permissions } = useAuth()

  const path = pathname.slice(1)
  const widthPanel = !path.startsWith('rh') && !path.includes('confirm')
  return (
    <MainLayout>
      <div className='ms-60 z-1032'>{widthPanel && <Panel />}</div>

      <div className='flex-fill position-relative overflow-auto vh-100'>
        <NotificationContext />
        <NavBar
          breadcrumbs={[
            {
              id: path,
              title: t(`navigation.${getBreadcrumb(path)}`),
            },
          ]}
          openFeedbackUserModal={() => toggleShowModal({ feedbackUser: true })}
          logout={() => logout()}
          permissionsTour={permissions}
        />

        <Outlet />
      </div>
    </MainLayout>
  )
}

function getBreadcrumb(pathname) {
  if (pathname.includes('rh')) {
    return 'rh'
  }
  if (pathname.includes('communications/confirm')) {
    return 'general_communication'
  }

  return pathname
}

GroundControlPage.propTypes = {
  children: PropTypes.node,
}

GroundControlPage.defaultProps = {
  children: null,
}
