import email from './email.api'
import feedbackUser from './feedbackUser.api'
import parseRH from './parseRH.api'
import perimeter from './perimeter.api'
import responseRates from './responseRates.api'
import rh from './rh.api'
import steps from './steps.api'
import surveys from './surveys.api'
import subscriptions from './subscription'
import feedback from './feedback.api'
import communication from './communication.api.js'
import settings from './setting.api.js'

const backend = {
  email,
  feedbackUser,
  parseRH,
  perimeter,
  responseRates,
  rh,
  steps,
  surveys,
  subscriptions,
  feedback,
  communication,
  settings,
}

export default backend
