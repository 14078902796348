import React from "react"
import style from "./NotificationRHBase.module.scss"
import { DATE_FORMAT } from "../../../../lib/date/useDateFormat"
// Translation
import { useTranslation } from "react-i18next"
import classNames from "classnames"

const getNotification = (bool) => {
  switch (bool) {
    case false:
      return { text: "component.notificationRHBase.text1", color: "danger" }
    case true:
      return { text: "component.notificationRHBase.text2", color: "success" }
    default:
      return { text: "component.notificationRHBase.text3", color: "danger" }
  }
}

export default function NotificationRHBase({ importDate, upToDate }) {
  const { t } = useTranslation()

  return (
    <div className={classNames(style.container, `bg-${getNotification(upToDate).color}`)}>
      <p className="mb-1">{t(getNotification(upToDate).text)}</p>
      <h4>{importDate ? importDate.format(t(DATE_FORMAT)) : t("component.notificationRHBase.text4")} </h4>
    </div>
  )
}
