import Axios from "axios"

const axios = Axios.create({ withCredentials: false })

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) window.location = "/auth/login"
    }
    return Promise.reject(error)
  }
)

export function setJwt(jwt) {
  if (jwt) {
    localStorage.setItem("jwt", jwt)
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt
  } else {
    localStorage.removeItem("jwt")
    delete axios.defaults.headers.common["Authorization"]
  }
}

export default axios
