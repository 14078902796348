import React from "react"
import PropTypes from "prop-types"
// Components
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

export default function SuccesValidationModal({ show, onHide, countSurveys }) {
  const { t } = useTranslation()

  return (
    <Modal size="xs" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("component.confirmValidationModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex align-items-center justify-content-center mb-3">
          <Col sm={3} className="text-end">
            <FontAwesomeIcon icon={faCheck} className="text-success" />
          </Col>
          <Col>
            <p className="m-0 fs">
              {t("component.confirmValidationModal.validSurvey", { count: countSurveys.validSurveys })}
            </p>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col sm={3} className="text-end">
            <FontAwesomeIcon icon={faTimes} className="text-danger fa-lg" />
          </Col>
          <Col>
            <p className="m-0 fs">
              {t("component.confirmValidationModal.deleteSurvey", { count: countSurveys.deletedSurveys })}
            </p>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Link to="/calendrier-questionnaires">
          <Button>
            {t("component.confirmValidationModal.cta2")}
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  )
}

SuccesValidationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  countSurveys: PropTypes.shape({
    validSurveys: PropTypes.number,
    deletedSurveys: PropTypes.number,
  }).isRequired,
}
