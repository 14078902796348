import React from 'react'
import MainNavigation from '../../features/navigation/mainNavigation'
import useDeleteSurvey from '../../hooks/useDeleteSurvey'
import {
  FeedbackUser,
  FeedbackUserValid,
  ErrorModal,
  ConfirmDeleteModal,
  useFeedbackUser,
  useUpdateModal,
  StepModal,
} from '@olystic/design_system_3'
import backend from '../../services/backend'
import { useAuth } from '@olystic/olysticapp'
import Loading from '../loading/loading'

function MainLayout({ children }) {
  const [loading, setLoading] = React.useState(true)
  const { user, updateUser, perimeter } = useAuth()
  const { handleSubmit, hideFeedback, showModal, toggleShowModal } =
    useFeedbackUser(user, updateUser, backend.feedbackUser.createFeedbackUser)
  const { showConfirmModal, toggleShowConfirmModal, onConfirmDeleteSurvey } =
    useDeleteSurvey()

  const {
    showUpdateModal,
    toggleShowUpdateModal,
    steps,
    title,
    language,
    handleSubmit: handleSubmitUpdate,
  } = useUpdateModal(user, updateUser)

  React.useEffect(() => {
    backend.perimeter
      .getPerimetre(perimeter?._id)
      .then(() => {
        backend.steps
          .getSteps(perimeter._id)
          .then(() => setLoading(false))
          .catch((e) => console.error(e))
      })
      .catch((e) => {
        console.error(e)
      })
  }, [perimeter?._id, user])

  if (loading) return <Loading />

  return (
    <div className='d-flex overflow-hidden'>
      <div className='position-relative z-1033'>
        <MainNavigation />
      </div>
      {children}
      <FeedbackUser
        show={showModal.feedbackUser}
        onHide={hideFeedback}
        onSubmit={handleSubmit}
      />
      <FeedbackUserValid
        show={showModal.feedbackUserValid}
        onHide={() => toggleShowModal({ feedbackUserValid: false })}
      />
      <ErrorModal
        show={showModal.error}
        onHide={() => toggleShowModal({ error: false })}
      />

      <ConfirmDeleteModal
        modalOpen={showConfirmModal}
        closeModal={() => toggleShowConfirmModal(false)}
        plural={false}
        contact={false}
        onConfirm={onConfirmDeleteSurvey}
      />

      <StepModal
        show={showUpdateModal.update}
        onHide={() => toggleShowUpdateModal({ update: false })}
        steps={steps}
        title={title}
        onFinish={handleSubmitUpdate}
        language={language}
      />
    </div>
  )
}

export default MainLayout
