import React, { useState } from 'react'
import { useRHTable } from '../../../../hooks/useRHTable'
import { useRHImport } from '../../../../hooks/useRHImport'
import HeaderLayout from '../Layout/HeaderLayout'
import GlobalFilter from '../RHTable/components/GlobalFilter'
import { RHTable } from '../RHTable'
import NoData from '../../../../components/NoData/NoData'
import RHImport from '../RHManagement/RHImport'
import { RespondentEditModal } from './RespondentEditModal'
import { useEditRespondent } from '../../../../hooks/useEditRespondent'
import CreateRespondentButton from './Buttons/CreateRespondentButton'
import ImportButton from './Buttons/ImportButton'
import ExportButton from './Buttons/ExportButton'
import DeleteAllButton from './Buttons/DeleteAllButton'
import backend from '../../../../services/backend'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../../../redux/reducers/surveys'
import EmailModal from './EmailModal'
import useModal from '../../../../hooks/useModal'
import { useTranslation } from 'react-i18next'
import { ConfirmDeleteModal, notification } from '@olystic/design_system_3'
import { RHStats } from '../RHStats/RHStats'

function RespondentSurveyList({ base, rhData }) {
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState('')
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({
    show: false,
    id: '',
  })
  const [selectedRespondent, setSelectedRespondent] = useState(null)
  const { respondents, deleteManyRespondent } = rhData
  const survey = useSelector((state) =>
    surveySelector.selectById(state, base.isSurvey),
  )
  const { instance, categoriesFilter } = useRHTable(
    respondents.data || {},
    survey?.processOnSubmit,
  )
  const { onImport, rhImportProps } = useRHImport(base.id)
  const editRespondent = useEditRespondent(rhData)
  const hasSelection = !!instance.selectedFlatRows.length
  const [currentEmail, setCurrentEmail] = useState(null)
  const { onShow: onShowEmail, modalProps: emailModelProps } =
    useModal(handleSendEmail)

  function handleShowConfirmDeleteModal(id, respondent) {
    if (respondent) setSelectedRespondent(respondent)
    setShowConfirmDeleteModal({ show: true, id })
  }
  function handleDelete(id) {
    if (id === 'selection') handleDeleteSelection()
    if (id === 'respondent') handleDeleteRespondent(selectedRespondent)
    setShowConfirmDeleteModal({ show: false, id: '' })
  }

  function handleDeleteRespondent(respondent) {
    deleteManyRespondent.mutate([respondent._id])
  }

  function handleDeleteSelection() {
    deleteManyRespondent.mutate(
      instance.selectedFlatRows.map((row) => row.original._id),
    )
  }

  function handleAccessQuestionnaire(respondent) {
    backend.rh.getQuestionnaireLink(survey._id, respondent._id).then((res) => {
      window.open(res.link, '_blank')
    })
  }

  function handleCopyLink(respondent) {
    backend.rh.getQuestionnaireLink(survey._id, respondent._id).then((res) => {
      try {
        navigator.clipboard && navigator.clipboard.writeText(res.link)
        notification.success(t('notification.copy_link'), 'lg')
      } catch (e) {}
    })
  }

  function handleCreateEmail(respondent, template) {
    backend.rh.getEmail(template, survey._id, respondent._id).then((mail) => {
      setCurrentEmail(mail)
      onShowEmail()
    })
  }

  async function handleSendEmail(payload) {
    await backend.rh.sendEmail(payload)
    // setCurrentEmail(null)
  }

  return (
    <>
      <HeaderLayout>
        <HeaderLayout.Group>
          <CreateRespondentButton onEdit={editRespondent.onEdit} />
        </HeaderLayout.Group>
        <HeaderLayout.Group>
          <GlobalFilter value={searchString} onChange={setSearchString} />
        </HeaderLayout.Group>

        <HeaderLayout.Group>
          <RHStats baseId={base.id} />
          <ImportButton onImport={onImport} />
          <ExportButton instance={instance} />
          <DeleteAllButton
            disabled={!hasSelection}
            onClick={() => handleShowConfirmDeleteModal('selection')}
          />
        </HeaderLayout.Group>
      </HeaderLayout>
      <RHTable
        instance={instance}
        globalFilter={searchString}
        loading={respondents.isLoading}
        baseCategory={base.base.data?.category}
        noDataAction={
          <div className='w-50 mx-auto mt-5'>
            <NoData
              title={t('rh.no_data.title')}
              text={t('rh.no_data.text')}
              cta={
                <ImportButton
                  instance={instance}
                  variant='primary'
                  onImport={onImport}
                />
              }
            />
          </div>
        }
        onEdit={editRespondent.onEdit}
        onDelete={(respondent) =>
          handleShowConfirmDeleteModal('respondent', respondent)
        }
        onQuestionnaire={
          survey?.processOnSubmit ? handleAccessQuestionnaire : null
        }
        onLinkCopy={handleCopyLink}
        onSendEmail={handleCreateEmail}
      />
      <RHImport {...rhImportProps} />
      <RespondentEditModal
        {...editRespondent.modalProps}
        categories={categoriesFilter}
      />
      <EmailModal {...emailModelProps} email={currentEmail} />
      <ConfirmDeleteModal
        modalOpen={showConfirmDeleteModal.show}
        closeModal={() => setShowConfirmDeleteModal(false)}
        plural={instance.selectedFlatRows.length > 1}
        contact={false}
        onConfirm={() => handleDelete(showConfirmDeleteModal.id)}
        secureText={t('rh.respondent_surveyList.confirmDeleteText')}
        warningText={t('rh.respondent_surveyList.warningText')}
      />
    </>
  )
}

export default RespondentSurveyList
