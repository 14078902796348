import { http } from '@olystic/olysticapp'
import { gc } from './constant'
import store from '../redux/store'
import {
  addOne as addOneSurvey,
  updateOne as updateOneSurvey,
  removeOne as removeOneSurvey,
} from '../redux/reducers/surveys'

/*
 Creation d'un Survey dans un perimetre
  ATTENTION: si la date de cloture est renseigner on doit aussi renseigner les relances desirer.
  si elle est omise, les dates de relance et de cloture sont determiner selon le type d'enquete.
 prend un objet de la forme suivante:
 {
    name: string,   (REQUIS)
    questionnaireId: id du questionnaire pour la creation du questionnaire, (REQUIS)
    dates: {
       Lancement: date string, (REQUIS)
       Relance1: date string (OPT)
       RelanceExceptionnel1: date string (OPT)
       Clôture: date string (OPT)
    }, (REQUIS)
    respondentGroup: (OPT) id de la base de sondee source pour cette enquete, si omis la base par default du periemtre est utiliser
 }
*/

const surveys = {
  async createSurvey(surveyInfo) {
    const res = await http.post(`${gc}/surveys`, surveyInfo)
    store.dispatch(addOneSurvey(res.data))
    return res
  },

  async updateSurvey(surveyId, survey) {
    const res = await http.patch(`${gc}/surveys/${surveyId}`, survey)
    store.dispatch(
      updateOneSurvey({
        id: surveyId,
        changes: survey,
      }),
    )
    return res
  },

  async deleteSurvey(surveyId) {
    const res = await http.delete(`${gc}/surveys/${surveyId}`)
    store.dispatch(removeOneSurvey(surveyId))
    return res
  },
}

export default surveys
