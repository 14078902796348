import { useCallback } from 'react'
import exportExcel, { defaultSheetFormat } from '../lib/exportExcel/exportCsv'

const formatQuestionnaireData = (questionnaire) => {
  let data = []

  data.push({
    'Questionnaire': '',
    'Questions': '',
    'Modalités de réponses': '',
  })

  data.push({
    'Questionnaire': questionnaire.label,
    'Questions': '',
    'Modalités de réponses': '',
  })

  let mod = 1

  questionnaire.sections.forEach((section) => {
    data.push({
      'Questionnaire': '',
      'Questions': section.title,
      'Modalités de réponses': '',
    })

    section.questions.forEach((question) => {
      data.push({
        'Questionnaire': '',
        'Questions': `            ${question.question}`,
        'Modalités de réponses': '',
      })
      switch (question.type) {
        case 0: // numérique
        case 7:
        case 8:
        case 100:
        case 107:
        case 108:
          for (let i = question.minV; i <= question.maxV; i++) {
            data.push({
              'Questionnaire': '',
              'Questions': '',
              'Modalités de réponses': `${mod++} - ${i}`,
            })
          }
          break
        case 1: // texte
        case 101:
          data.push({
            'Questionnaire': '',
            'Questions': '',
            'Modalités de réponses': '',
          })
          break
        case 2: // choix unique
        case 3: // choix multiple
        case 102:
        case 103:
          question.possibilities.forEach((possibility) => {
            data.push({
              'Questionnaire': '',
              'Questions': '',
              'Modalités de réponses': `${mod++} - ${possibility.title}`,
            })
          })
          break
        case 4: // bloqué
        case 5:
        case 6:
          question.rows.forEach((row) => {
            data.push({
              'Questionnaire': '',
              'Questions': `                    ${row.title}`,
              'Modalités de réponses': '',
            })
            question.columns.forEach((column) => {
              data.push({
                'Questionnaire': '',
                'Questions': '',
                'Modalités de réponses': `${mod++} - ${column.title}`,
              })
            })
          })
          break
        default:
          console.error('Unknown question type')
      }

      mod = 1 // Reset mod for next question
    })

    data.push({
      'Questionnaire': '',
      'Questions': '',
      'Modalités de réponses': '',
    })
  })

  return data
}

export function useExportQuestionnaire(questionnaire) {
  const exportQuestionnaire = useCallback(() => {
    const data = formatQuestionnaireData(questionnaire)

    exportExcel(
      data,
      defaultSheetFormat((cell, ws, i) => {
        if (cell.r === 0) ws[i].s = { font: { bold: true, sz: 16 } } // Style pour le nom du questionnaire
        if (cell.c === 0 || cell.c === 1) ws[i].s = { font: { bold: true } } // Style pour la colonne questionnaire et section
      }),
      questionnaire.label,
    )
  }, [questionnaire])

  return exportQuestionnaire
}
