/* eslint-env node */
import i18n from "i18next"
import Backend from "i18next-http-backend"
import intervalPlural from "i18next-intervalplural-postprocessor"

i18n
  .use(Backend)
  .use(intervalPlural)
  .init({
    ns: ["design-system-3"],
    defaultNS: "translation",
    lng: "fr",
    fallbackLng: "fr",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    backend: {
      loadPath: (lng, ns) =>
        ns[0] === "design-system-3"
          ? `/design-system-3/locales/${lng}/design-system-3.json`
          : `${process.env.PUBLIC_URL}/locales/${lng}/${ns}.json`,
      requestOptions: {
        cache: "no-cache",
      },
      // loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    },
  })
  .catch(console.error)

export default i18n
