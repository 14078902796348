import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/pro-regular-svg-icons'
import { Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
// Translation
import { useTranslation } from 'react-i18next'

const IconDropdownToggle = React.forwardRef(({ onClick }, ref) => {
  const { t } = useTranslation()
  return (
    <div
      ref={ref}
      onClick={(e) => {
        onClick(e)
      }}
      className='cursor-pointer ps-5'
    >
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{t('component.columnDropdown.helper')}</Tooltip>}
      >
        <FontAwesomeIcon icon={faTable} className='fa-lg' />
      </OverlayTrigger>
    </div>
  )
})

export function ColumnDropDown({ instance }) {
  const { t } = useTranslation()
  const categoriesList = instance.allColumns.filter((c) => c.toggleVisibility)
  return (
    <Dropdown
      className='position-relative'
      as='div'
      autoClose='outside'
      drop='down-centered'
    >
      <Dropdown.Toggle id='visible-columns' as={IconDropdownToggle} />
      <Dropdown.Menu
        className='overflow-auto'
        style={{
          maxHeight: '18rem',
          minWidth: '18rem',
        }}
      >
        {categoriesList.length ? (
          categoriesList.map((column) => {
            const toggleProps = column.getToggleHiddenProps()
            return (
              <Dropdown.Item
                key={column.id}
                onClick={(e) => {
                  e.stopPropagation()
                  column.toggleHidden()
                }}
                className='d-flex align-items-center justify-content-start gap-2 cursor-pointer py-3'
              >
                <Form.Check
                  checked={toggleProps.checked}
                  className='cursor-pointer ps-5'
                  style={{ zIndex: -1 }}
                  label={column.Header}
                />
              </Dropdown.Item>
            )
          })
        ) : (
          <Dropdown.Item
            className='gap-2 text-secondary cursor-not-allowed'
            style={{ background: 'rgba(150, 154, 163, .1)' }}
          >
            {t('component.columnDropdown.empty')}
          </Dropdown.Item>
        )}
        {}
      </Dropdown.Menu>
    </Dropdown>
  )
}
