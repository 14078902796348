import { useCallback } from "react";
import exportExcel, {
  defaultSheetFormat
} from "../lib/exportExcel/exportCsv";
// Translation
import { useTranslation } from "react-i18next"

const exportData = (rows, t) => {
  return rows.map((r) => ({
    [t("category.ps_matricule")]: r.values.matricule,
    [t("category.ps_nom")]: r.values["rhData.ps_nom"],
    [t("category.ps_prenom")]: r.values["rhData.ps_prenom"],
    [t("category.ps_statut")]: r.values["rhData.ps_status"],
    [t("category.ps_email")]: r.values.email,
    [t("category.ps_anciennete")]: r.values["rhData.ps_anciennete"]?.date,
    [t("category.ps_direction")]: r.values["rhData.ps_direction"],
    [t("category.ps_age")]: r.values["rhData.ps_age"]?.date,
    [t("category.ps_sortie")]: r.values["rhData.ps_sortie"]?.date,
    [t("category.ps_manager")]: r.values["rhData.ps_manager"],
    [t("category.ps_service")]: r.values["rhData.ps_service"],
    [t("category.ps_site")]: r.values["rhData.ps_site"],
    [t("category.ps_pays")]: r.values["rhData.ps_pays"],
    [t("category.ps_metier")]: r.values["rhData.ps_metier"],
    // [t("category.ps_agence")]: r.values["rhData.ps_agence"],
    [t("category.ps_bu")]: r.values["rhData.ps_bu"],
    [t("category.ps_classification")]: r.values["rhData.ps_classification"],
    [t("category.ps_classification_niv_1")]: r.values["rhData.ps_classification_niv_1"],
    [t("category.ps_classification_niv_2")]: r.values["rhData.ps_classification_niv_2"],
    [t("category.ps_contrat")]: r.values["rhData.ps_contrat"],
    [t("category.ps_division")]: r.values["rhData.ps_division"],
    [t("category.ps_emploi")]: r.values["rhData.ps_emploi"],
    [t("category.ps_entreprise")]: r.values["rhData.ps_entreprise"],
    [t("category.ps_etablissement")]: r.values["rhData.ps_etablissement"],
    [t("category.ps_famille_metiers")]: r.values["rhData.ps_famille_metiers"],
    // [t("category.ps_genre")]: r.values["rhData.ps_genre"],
    [t("category.ps_niveau_managerial")]: r.values["rhData.ps_niveau_managerial"],
    [t("category.ps_perimetre")]: r.values["rhData.ps_perimetre"],
    // [t("category.ps_plaque")]: r.values["rhData.ps_plaque"],
    // [t("category.ps_region")]: r.values["rhData.ps_region"],
    [t("category.ps_sexe")]: r.values["rhData.ps_sexe"],
    // [t("category.ps_societe")]: r.values["rhData.ps_societe"],
  }))
}

export function useExportRespondent(tableInstance) {
  const { t } = useTranslation()
  const exportRespondents = useCallback(() => {
    let data = []
    if (tableInstance.selectedFlatRows.length) data = exportData(tableInstance.selectedFlatRows, t)
    else data = exportData(tableInstance.rows, t)

    exportExcel(
      data,
      defaultSheetFormat((cell, ws, i) => {
        if ([5, 7].includes(cell.c)) ws[i].t = "d" // age et ancienneté au format date
      })
    )
  }, [tableInstance, t])

  return exportRespondents
}
