import { useState } from "react";

export function useEditRespondent(rhData) {
  const [showRespondentModal, setShowRespondentModal] = useState(false)
  const [currentRespondent, setCurrentRespondent] = useState()

  const { createRespondent, updateRespondent } = rhData

  function handleEditRespondent(respondent) {
    setCurrentRespondent(respondent)
    setShowRespondentModal(true)
  }

  function handleCloseRespondentModal() {
    setCurrentRespondent(null)
    setShowRespondentModal(false)
  }

  function handleSubmitNewRespondent(respondent) {
    handleCloseRespondentModal()
    return createRespondent.mutateAsync(respondent)
  }

  function handleUpdateRespondent(respondent) {
    handleCloseRespondentModal()
    return updateRespondent.mutateAsync(respondent)
  }

  return {
    modalProps: {
      show: showRespondentModal,
      onHide: handleCloseRespondentModal,
      respondent: currentRespondent,
      onCreate: handleSubmitNewRespondent,
      onUpdate: handleUpdateRespondent,
    },
    onEdit: handleEditRespondent
  }
}
