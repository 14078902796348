import React from 'react'
import PropTypes from 'prop-types'
// Components
import moment from 'moment'
import { mappingSurvey } from '@olystic/common/survey.mjs'
import {
  Overlay,
  Popover,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
// Translation
import { useTranslation } from 'react-i18next'

const InfoDateSurvey = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { data, show, onHide, onHandleShowPopover, onHandleShowQuestionModal } =
    props

  return (
    <>
      <Overlay
        show={show}
        target={ref.current}
        placement='top'
        rootClose
        rootCloseEvent='mousedown'
        onHide={onHide}
      >
        <Popover id={data._id}>
          <Popover.Body>
            <>
              <header className='d-flex align-items-center mb-3 fs'>
                <div
                  className={`square me-3 bg-${mappingSurvey(data.type).color}`}
                ></div>
                <div style={{ maxWidth: '10rem' }}>
                  <p className='mb-1 text-truncate'>{t(data.name)}</p>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip
                        id='questionCard-tooltip'
                        className='position-fixed'
                      >
                        {t('click_questionnaire')}
                      </Tooltip>
                    }
                  >
                    <p
                      className='m-0 fs-sm text-blue-500 cursor-pointer'
                      onClick={onHandleShowQuestionModal}
                    >
                      {t(`${data.nbQuestions} questions`)}
                    </p>
                  </OverlayTrigger>
                </div>
              </header>

              <div className='fs-sm'>
                <p className='mb-1'>
                  {t('component.planningDate.form.label_launch')} :{' '}
                  {data.launch.format('DD/MM/YYYY')}
                </p>
                <p className='mb-1'>
                  {t('component.planningDate.form.label_closing')} :{' '}
                  {data.closing.format('DD/MM/YYYY')}
                </p>
                <p className='mb-3'>
                  {t('component.planningDate.form.label_respondent')} :{' '}
                  {data.selectedTarget || t('common.all_respondents')}
                </p>
              </div>
              {moment().isBefore(data.closing) && (
                <Button
                  className='mx-auto d-flex'
                  onClick={onHandleShowPopover}
                >
                  {t('common.update')}
                </Button>
              )}
            </>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  )
})

export default InfoDateSurvey

InfoDateSurvey.displayName = 'InfoDateSurvey'

InfoDateSurvey.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    nbQuestions: PropTypes.number,
    launch: PropTypes.object,
    closing: PropTypes.object,
    selectedRecipient: PropTypes.string,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onHandleShowPopover: PropTypes.func.isRequired,
}
