import { useMemo } from 'react'
import { ACCESS_RESULT } from '@olystic/common/user.mjs'
import {
  ACCESS_FROM_SCRATCH,
  ACCESS_PERIMETER,
  ACCESS_QUESTIONNAIRE,
  ADMIN_PERMISSION,
  EDIT_QUESTIONNAIRE,
  ROOT,
  ROOT_ACCESS,
} from '../constants'

export default function usePermissions(user, perimeter) {
  const permissions = useMemo(() => {
    let permissions = []
    if (user && perimeter) {
      // Access result permission
      if (
        ADMIN_PERMISSION.includes(user.role) &&
        user.permissions.includes(ACCESS_RESULT)
      ) {
        permissions.push(ACCESS_RESULT)
      }
      // Access questionnaire permission
      if (
        ADMIN_PERMISSION.includes(user.role) &&
        user.permissions.includes(ACCESS_QUESTIONNAIRE) &&
        perimeter.permissions.includes(ACCESS_QUESTIONNAIRE)
      ) {
        permissions.push(ACCESS_QUESTIONNAIRE)
      }
      // Edit questionnaire permission
      if (
        ADMIN_PERMISSION.includes(user.role) &&
        user.permissions.includes(EDIT_QUESTIONNAIRE) &&
        perimeter.permissions.includes(ACCESS_QUESTIONNAIRE)
      ) {
        permissions.push(EDIT_QUESTIONNAIRE)
      }
      if (
        ADMIN_PERMISSION.includes(user.role) &&
        user.permissions.includes(EDIT_QUESTIONNAIRE) &&
        perimeter.permissions.includes(ACCESS_FROM_SCRATCH)
      ) {
        permissions.push(ACCESS_FROM_SCRATCH)
      }
      // Root permission
      if (user.role === ROOT) {
        permissions.push(ROOT_ACCESS)
      }
      // Access perimeter permission
      if (
        ADMIN_PERMISSION.includes(user.role) &&
        user.permissions.includes(ACCESS_PERIMETER)
      ) {
        permissions.push(ACCESS_PERIMETER)
      }
    }
    return permissions
  }, [perimeter, user])

  return permissions
}
