import React from "react"
import moment from "moment"
// Redux
import { useSelector } from "react-redux"
import { surveySelector } from "../../../redux/reducers/surveys"
// Components
import SuiviActivity from "./SuiviActivity"

const getDate = (surveysList, sort, string) => {
  if (!surveysList.length) return string
  let date

  const sortedDates = surveysList.sort((a, b) => moment(a.dates[sort]).diff(moment(b.dates[sort])))

  if (sort === "Lancement") date = sortedDates[0].dates[sort]
  if (sort === "Clôture") date = sortedDates[sortedDates.length - 1].dates[sort]

  return moment(date).format("DD/MM/YYYY")
}

export default function SuiviActivityContainer() {
  const surveys = useSelector((state) => surveySelector.selectAll(state))

  const dates = React.useMemo(() => {
    const now = moment()
    const nextSurveys = surveys.filter((s) => moment(s.dates.Lancement).isAfter(now))
    const lastSurveys = surveys.filter((s) => moment(s.dates.Clôture).isBefore(now))

    const nextQuestionnaireDate = getDate(nextSurveys, "Lancement", "A programmer")

    const lastQuestionnaireDate = getDate(lastSurveys, "Clôture", "--/--/----")

    return { lastQuestionnaire: lastQuestionnaireDate, nextQuestionnaire: nextQuestionnaireDate }
  }, [surveys])

  return <SuiviActivity dates={dates} />
}
