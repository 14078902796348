import http, { setJwt } from '../axiosService'

const auth = {
  async getMe() {
    return await http.get('/auth/me')
  },

  async logout() {
    const res = await http
      .delete('/auth/me')
      .then(() => {
        setJwt(false)
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })

    return res
  },

  async update(user) {
    const res = await http.patch(`/auth/me`, user)
    return res
  },
}

export default auth
