/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from "react"

export const SchedulerContext = createContext()

export default function SchedulerProvider({ children }) {
  const [openScheduler, setOpenScheduler] = useState(false)
  const [scale, setScale] = useState(50)
  const [height, setHeight] = useState(null)
  const [line, setLine] = useState(1)

  useEffect(() => {
    setHeight(line * 80)
  }, [line])

  const onChangeScale = (int) => {
    setScale(int)
  }

  const toggleScheduler = (bool) => {
    setOpenScheduler(bool)
  }

  const handleChangeLine = (num) => {
    setLine(num)
  }

  return (
    <SchedulerContext.Provider
      value={{ openScheduler, toggleScheduler, scale, onChangeScale, height, line, handleChangeLine }}
    >
      {children}
    </SchedulerContext.Provider>
  )
}
