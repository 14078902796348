import React from 'react'
import PropTypes from 'prop-types'
import CommBtn from './CommBtn/CommBtn'
import { types } from '../../constant'
// Translation
import { useTranslation } from 'react-i18next'

CommNavigation.propTypes = {
  activeView: PropTypes.string.isRequired,
  onSelectionView: PropTypes.func.isRequired,
}

export default function CommNavigation({ activeView, onSelectionView }) {
  const { t } = useTranslation()
  return (
    <div className="d-flex flex-column">
      <h5 className="d-xxl-none">{t('component.commNavigation.title')}</h5>
      <div className="d-flex mb-4 flex-wrap gap-3 flex-xxl-column ">
        <CommBtn
          id="helper"
          title="Aide à la communication"
          activeView={activeView}
          onSelectionView={onSelectionView}
        />
        <CommBtn
          id="download"
          title="Support de communication"
          activeView={activeView}
          onSelectionView={onSelectionView}
        />
        <div
          className="d-none d-xxl-block mt-xx  mt-4 border-top border-dark pt-5"
          style={{ maxWidth: '320px' }}
        >
          <h4>{t('component.commNavigation.title')}</h4>
        </div>
        {types.map((type, i) => (
          <CommBtn
            key={type.id}
            id={type.id}
            title={type.title}
            count={i + 1}
            icon={type.icon}
            activeView={activeView}
            onSelectionView={onSelectionView}
          />
        ))}
      </div>
    </div>
  )
}
