import React from "react"
import PropTypes from "prop-types"
// Components
import moment from "moment"
import { WIDTH, POSITION } from "../constants"

Line.propTypes = {
  scale: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  pixelsByDay: PropTypes.number.isRequired,
}

export default function Line({ scale, position, pixelsByDay }) {
  const currentPosition = React.useMemo(() => {
    const firstDayOfMonth = moment().startOf("month")
    const middleMonth = firstDayOfMonth.daysInMonth() / 2
    const todayInPixel = (moment().diff(firstDayOfMonth, "days") + middleMonth) * pixelsByDay
    const todayLine = todayInPixel + ((WIDTH / 2) * scale) / 100

    return -POSITION + (position + todayLine)
  }, [pixelsByDay, position, scale])

  return <span className="border-start border-dark position-absolute h-100" style={{ left: currentPosition / 16 + "rem" }}></span>
}
