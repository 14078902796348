// import { set } from "date-fns"
import moment from "moment"

/**
 *
 * @param {Date} date
 * @return date en timestamp
 */
export function conversionDateToTimestamp(date) {
  return moment(date).format("X")
}

/**
 *
 * @param {Date} timestamp
 * @returns date en format Date
 */
export function conversionTimestampToDate(timestamp) {
  return moment(timestamp, "X").format()
}

/**
 *
 * @param {Date} begin
 * @param {Date} end
 * @return  Durée en timestamp de la periode voulue
 */
export function timelineRange(begin, end) {
  return end - begin
}

/**
 *
 * @param {Date} date date à positionner
 * @param {Date} begin Début de la timeline
 * @param {Date} end Fin de la timeline
 * @param {number} width largeur de la timeline
 * @param {number} position position de la timeline
 * @return positionnement en pixel
 */
export function conversionDateToPixel(date, begin, end, width, position) {
  let range = timelineRange(begin, end)
  let scale = width / range

  return (date - begin) * scale + position
}

/**
 *
 * @param {Date} begin
 * @param {Date} end
 * @param {number} timeInPixel
 * @return la largeur entre 2 dates
 */
export function conversionTwoDateToPixelWidth(begin, end, timeInPixel) {
  return (end - begin) / timeInPixel
}

/**
 *
 * @param {number} position dans le timeline
 * @param {Date} begin Début de la timeline en timestamp
 * @param {Number} timeInPixel Nombre de timestamp dans un pixel
 * @returns une date en timestamp
 */
export function conversionPositionToTimestamp(position, begin, timeInPixel) {
  return timeInPixel * position + begin
}

export function linePositionToTimeline(items, enquete) {
  let lines = new Set()
  let currentLine = 1
  let enqueteBegin = enquete.x
  let enqueteEnd = enquete.x + enquete.width

  if (!items.length) return 1

  for (let item of items) {
    let begin = item.x
    let end = item.x + item.width
    let lineItem = item.line

    if (
      (enqueteBegin < end && enqueteEnd > end) ||
      (enqueteBegin < begin && enqueteEnd < end && enqueteEnd > begin) ||
      (enqueteBegin < begin && enqueteEnd > end) ||
      (enqueteBegin > begin && enqueteEnd < end)
    ) {
      lines.add(lineItem)
    }
  }

  let uniqueLinesSorted = [...lines]

  while (uniqueLinesSorted.includes(currentLine)) {
    currentLine = currentLine + 1
  }

  return !uniqueLinesSorted.length ? 1 : currentLine
}
