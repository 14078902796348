import { useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import backend from "../services/backend"
// Translation
import { useTranslation } from "react-i18next"
import { notification } from "@olystic/design_system_3"

export default function useEmailData(id, perimeter) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  
  const { data, isLoading } = useQuery(["email", id], () =>
    backend.email.search({
      context: "perimeter",
      contextRef: perimeter._id,
      type: id,
      default: true
    })
  )

  const email = useMemo(() => {
    if (data && data.data.length > 0) {
      return data.data[0]
    }
  }, [data])

  const createEmail = useMutation((resp) => backend.email.create(resp), {
    onSettled: () => queryClient.invalidateQueries(["email", id]),
    onSuccess: () => {
      notification.success(t("notification.message_updated"), "lg")
    },
    onError: () => {
      notification.error(t("notification.error"), "lg")
    },
  })

  const updateEmail = useMutation((resp) => backend.email.update(resp._id, resp), {
    onSettled: () => queryClient.invalidateQueries(["email", id]),
    onSuccess: () => {
      notification.success(t("notification.message_updated"), "lg")
    },
    onError: () => {
      notification.error(t("notification.error"), "lg")
    },
  })

  return {
    email,
    isLoading,
    createEmail,
    updateEmail,
  }
}
