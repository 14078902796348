/* eslint-disable react/display-name */
import React from 'react'
import './RHTable.scss'
import classnames from 'classnames'
import { useAsyncDebounce } from 'react-table'
import UpdatedDropdown from './components/UpdatedDropdown'
import { Spinner } from 'react-bootstrap'
import { ColumnDropDown } from './ColumnDropDown'
import {
  ArrowPagination,
  DropdownPagination,
  InfoPagination,
  SelectorPagination,
} from '@olystic/design_system_3'
import { useTranslation } from 'react-i18next'

export default function RHTable({
  instance,
  globalFilter,
  loading,
  noDataAction,
  onEdit,
  onDelete,
  onQuestionnaire,
  onLinkCopy,
  onSendEmail,
  showAction = true,
  scrollable = false,
  baseCategory,
}) {
  const { t } = useTranslation()
  const [selectedRow, setSelectedRow] = React.useState(null)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, options },
    setGlobalFilter,
  } = instance

  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  React.useEffect(() => {
    onFilterChange(globalFilter)
  }, [onFilterChange, globalFilter])

  const renderTable = () => {
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {showAction && (
                <th style={{ width: '7rem' }}>
                  <ColumnDropDown instance={instance} />
                </th>
              )}
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps()}
                  style={{ width: i === 0 && '3rem' }}
                >
                  <div className='d-flex align-items-center text-nowrap'>
                    <span
                      {...column.getSortByToggleProps()}
                      className={classnames(column.isSorted && 'text-primary')}
                    >
                      {column.render('Header')}
                    </span>
                    <span className='ms-2 justify-content-start'>
                      {column.canFilter ? column.render('Filter') : null}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                key={i}
                {...row.getRowProps()}
                className={`${row.isSelected && 'bg-secondary bg-opacity-10'}`}
              >
                {showAction && (
                  <UpdatedDropdown
                    item={row.original}
                    show={row.original._id === selectedRow}
                    onHide={() => setSelectedRow(null)}
                    onShow={(item) => setSelectedRow(item._id)}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onQuestionnaire={onQuestionnaire}
                    onLinkCopy={onLinkCopy}
                    onSendEmail={onSendEmail}
                  />
                )}
                {row.cells.map((cell, i) => {
                  const cellValue = cell.value
                  return (
                    <React.Fragment key={i}>
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header ===
                            t(`category.${baseCategory}`) &&
                          'bg-primary bg-opacity-10'
                        } ${cellValue === 'undefined' && 'text-secondary'}`}
                      >
                        {cellValue === 'undefined'
                          ? t('undefined')
                          : cell.render('Cell')}{' '}
                      </td>
                    </React.Fragment>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div className='RHTable__container' id='RHTable'>
      <div
        className={classnames('RHTable__tableWrap', {
          'RHTable__tableWrap-scrollable': scrollable,
        })}
      >
        {renderTable()}
        {!instance.data.length && !loading && noDataAction}
        {loading && (
          <div className='d-flex justify-content-center w-100 mt-5'>
            <Spinner animation={'border'} />
          </div>
        )}
      </div>

      {instance.data.length > 0 && (
        <div className='center my-3 gap-5 flex-wrap'>
          <DropdownPagination
            options={options}
            pageSize={pageSize}
            onChangePageSize={(option) => setPageSize(option)}
          />

          <SelectorPagination
            numberOfPages={pageCount}
            currentPage={pageIndex + 1}
            gotoPage={(page) => gotoPage(page - 1)}
          />

          <div className='gap-3 d-flex align-items-center'>
            <InfoPagination
              firstItemInCurrentPage={pageIndex * pageSize + 1}
              lastItemInCurrentPage={pageIndex * pageSize + page.length}
              totalItem={rows.length}
            />

            <ArrowPagination
              goToFirstPage={() => gotoPage(0)}
              goToLastPage={() => gotoPage(pageCount - 1)}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              previousPage={() => previousPage()}
              nextPage={() => nextPage()}
            />
          </div>
        </div>
      )}
    </div>
  )
}
