import React from 'react'
import PropTypes from 'prop-types'
// Components
import { Card } from 'react-bootstrap'
import { ButtonWithIcon } from '@olystic/design_system_3'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../redux/reducers/surveys'
// Icons
import { faPen } from '@fortawesome/pro-light-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

TargetCard.propTypes = {
  surveyId: PropTypes.string.isRequired,
}

export default function TargetCard({ surveyId }) {
  const { t } = useTranslation()
  const survey = useSelector((state) =>
    surveySelector.selectById(state, surveyId),
  )

  return (
    <Card className='{style.container}'>
      <Card.Header className='border-bottom'>
        <h4 className='m-0 text-center'>{t('component.targetCard.title')}</h4>
      </Card.Header>
      <Card.Body className='text-center'>
        <p className='mb-5 fs-lg'>{survey?.respondentGroup?.name}</p>
        <ButtonWithIcon
          icon={faPen}
          variant=''
          className='text-primary p-0 fs'
          onClick={() =>
            (window.location.href = `/rh/${survey.respondentGroup._id}`)
          }
        >
          {t('component.targetCard.cta')}
        </ButtonWithIcon>
      </Card.Body>
    </Card>
  )
}
