import React from "react"
import PropTypes from "prop-types"
// Components
import { InfoButton } from "@olystic/design_system_3"
import { Badge, Button, Modal } from "react-bootstrap"
import { mappingSurvey } from "@olystic/common/survey.mjs"
// Translation
import { useTranslation } from "react-i18next"

PurchasingQuestionnaireModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  questionnaire: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default function PurchasingQuestionnaireModal({ show, onHide, questionnaire }) {
  const { t } = useTranslation()
  const [secondStep, setSecondStep] = React.useState(false)

  const { type, label, nbQuestions } = questionnaire
  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("component.purchasingQuestionnaireModal.title")}</Modal.Title>
        <InfoButton />
      </Modal.Header>
      <Modal.Body>
        <Badge bg={mappingSurvey(type).color}>{t(`survey.types.${mappingSurvey(type).title}`)}</Badge>
        <p className="fs-lg mt-2">{label}</p>
        <p className="fs-sm mb-2 mt-5 w-50">
          {t("component.purchasingQuestionnaireModal.description", {
            count: t("survey.nb_questions.nb", { count: nbQuestions }),
            label,
          })}
        </p>

        <div
          className="d-inline-flex flex-column align-items-end position-absolute"
          style={{ bottom: "1rem", right: "2rem" }}
        >
          {secondStep ? (
            <>
              <p className="mb-1 fs-lg">
                {t("component.purchasingQuestionnaireModal.amountWithCurrency", { val: 500 })}
              </p>
              <div className="d-flex align-items-center">
                {/* <Button className="me-2">{t("Carte bleue")}</Button> */}
                <Button variant="blue" onClick={() => window.open("https://olystic.zendesk.com/hc/fr", "_blank")}>
                  {t("common.contact_support")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="mb-1">{t("component.purchasingQuestionnaireModal.amountWithCurrency", { val: 500 })}</p>
              <Button className="p-2" onClick={() => setSecondStep(true)}>
                {t("common.order")}
              </Button>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
