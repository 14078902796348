import React from "react"
import PropTypes from "prop-types"
// Components
import { Overlay, Popover } from "react-bootstrap"
import { surveyProps } from "@olystic/common/proptypes/survey"
import PlanningChangeForm from "./PlanningChangeForm"

const PlanningDate = React.forwardRef((props, ref) => {
  const { survey, show, onHide, onClose, onHandleShowQuestionModal } = props

  return (
    <Overlay show={show} target={ref.current} placement="top" rootClose rootCloseEvent="mousedown" onHide={onHide}>
      <Popover id={survey._id} style={{maxWidth: "17rem"}}>
        <Popover.Body>
          <div className="py-3 px-2">
            <PlanningChangeForm survey={survey} onClose={onClose} onHandleShowQuestionModal={onHandleShowQuestionModal} />
          </div>
        </Popover.Body>
      </Popover>
    </Overlay>
  )
})

export default PlanningDate

PlanningDate.displayName = "PlanningDate"

PlanningDate.propTypes = {
  survey: surveyProps.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
