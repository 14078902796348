import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { surveySelector } from '../../../redux/reducers/surveys'
import {
  waitingSurveySelector,
  removeOne,
} from '../../../redux/reducers/waitingSurveys'
import backend from '../../../services/backend'
// Components
import { notification } from '@olystic/design_system_3'
import { mappingSurvey } from '@olystic/common/survey.mjs'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import PlanningDate from '../../../components/popovers/PlanningDate/PlanningDate'
import moment from 'moment'
// Translation
import { useTranslation } from 'react-i18next'
import useModal from '../../../hooks/useModal'
import QuestionModal from '../../../components/modals/QuestionsModal/QuestionModal'

SchedulerSurvey.propTypes = {
  enquete: PropTypes.shape({
    id: PropTypes.string,
    isNewSurvey: PropTypes.bool,
    name: PropTypes.string,
    nbQuestions: PropTypes.number,
    type: PropTypes.string,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
}

export default function SchedulerSurvey({ enquete }) {
  const { t } = useTranslation()
  const { id, type, isNewSurvey, name, x, y, width } = enquete
  const ref = useRef()
  const dispatch = useDispatch()
  const surveys = useSelector((state) => surveySelector.selectAll(state))
  const waitingSurveys = useSelector((state) =>
    waitingSurveySelector.selectAll(state),
  )
  const [showPlanningDate, setShowPlanningDate] = useState(isNewSurvey)
  const [ghostId, setGhostId] = useState('')
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const questionModal = useModal()

  useEffect(() => {
    const allEnquetes = [...surveys, ...waitingSurveys]
    setSelectedSurvey(allEnquetes.find((s) => s._id === id))
  }, [id, surveys, waitingSurveys])

  function handleDragStart(e, id) {
    const isASurvey = surveys.some((s) => s._id === id)
    if (isASurvey && moment().isAfter(moment(selectedSurvey.dates.Lancement)))
      return

    let dragImage = document.createElement('div')
    dragImage.id = 'ghostEnquete--' + type
    setGhostId(dragImage.id)
    document.body.appendChild(dragImage)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setDragImage(dragImage, 0, 0)
    e.dataTransfer.setData('text/questionnaire', id)
    e.dataTransfer.setData('text/questionnaireType', type)
  }

  async function handleDragEnd(e, id) {
    if (e.dataTransfer.dropEffect === 'none') {
      const isASurvey = surveys.some((s) => s._id === id)
      if (isASurvey) {
        try {
          await backend.surveys.deleteSurvey(id)
          notification.success(t('notification.delete_survey'), 'lg')
        } catch (error) {
          console.error(error)
        }
      } else {
        dispatch(removeOne(id))
      }
    }

    let ghostEnquete = document.getElementById(ghostId)
    ghostEnquete && ghostEnquete.remove()
  }

  function handleHide() {
    // Si les dates ne sont pas validées, l'enquête est supprimée et la notification apparait sinon cela ferme le popover.
    const noValidSurveyId = waitingSurveys.find((s) => s.isValid === false)?._id
    if (noValidSurveyId) {
      dispatch(removeOne(noValidSurveyId))
      notification.error(t('notification.no_save_survey'), 'lg')
    }
    setShowPlanningDate(false)
  }

  function handleShowQuestionModal() {
    questionModal.onShow()
    setShowPlanningDate(false)
  }
  return (
    <>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id={`SchedulerSurvey-tooltip-${id}`}>{name}</Tooltip>}
      >
        <div
          className={`d-flex align-items-center justify-content-center position-absolute rounded-2 user-select-none cursor-pointer bg-${
            mappingSurvey(type).color
          }`}
          style={{
            left: x,
            width: width || 0,
            top: `${y / 16}rem`,
            height: '2.875rem',
          }}
          onClick={() => setShowPlanningDate(true)}
          draggable={moment().isBefore(moment(selectedSurvey?.dates.Lancement))}
          onDragStart={(e) => handleDragStart(e, id)}
          onDragEnd={(e) => handleDragEnd(e, id)}
          ref={ref}
        >
          <p className='w-75 m-0 text-white text-center text-truncate fs'>
            {name}
          </p>
        </div>
      </OverlayTrigger>

      {showPlanningDate && selectedSurvey && (
        <PlanningDate
          survey={selectedSurvey}
          show={showPlanningDate}
          onHide={handleHide}
          onClose={() => setShowPlanningDate(false)}
          onHandleShowQuestionModal={handleShowQuestionModal}
          ref={ref}
        />
      )}

      {questionModal.modalProps.show &&
        selectedSurvey &&
        selectedSurvey.questionnaire.sections && (
          <QuestionModal
            {...questionModal.modalProps}
            questionnaire={selectedSurvey.questionnaire}
            canView={false}
          />
        )}
    </>
  )
}
