import React, { useState } from "react";
import style from "./copyClipboardId.module.scss";
import { useTranslation } from "react-i18next"

export const withCopyToClipboard = (Button, hocProps) => ({value, children, ...props}) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  async function copyClipboard() {
    try {
      navigator.clipboard && navigator.clipboard.writeText(value)
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 1000)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="position-relative">
      <Button onClick={() => copyClipboard()} {...hocProps} {...props}>
        {children}
      </Button>
      {show && <div className={style.copyTooltip}>{t("component.icon_button.tooltip")}</div>}
    </div>
  )
}
