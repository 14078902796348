import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { surveyType } from '@olystic/common/survey.mjs'
import { useSelector } from 'react-redux'
import { questionnaireSelectors } from '../redux/reducers/questionnaires'

export default function useFilterQuestionnaire() {
  const surveyTypes = surveyType()
  const questionnaires = useSelector((state) =>
    questionnaireSelectors.selectAll(state)
  )

  const [packs, setPacks] = useState([])
  const [types, setTypes] = useState([])
  const [themes, setThemes] = useState([])
  const [query, setQuery] = useState('')

  const [selectedPacks, setSelectedPacks] = useState(new Set())
  const [selectedTypes, setSelectedTypes] = useState(new Set())
  const [selectedThemes, setSelectedThemes] = useState(new Set())
  const [selectedNew, setSelectedNew] = useState(false)

  const now = moment()
  const delay = now.subtract(6, 'months')

  useEffect(() => {
    const allPacks = new Set(questionnaires.map((q) => q.pack).sort())
    setPacks([...allPacks].map((p) => ({ id: p, title: p })))
    setSelectedPacks(allPacks)
  }, [questionnaires])

  useEffect(() => {
    const filterPackQuestionnaire = questionnaires.filter((q) =>
      [...selectedPacks].includes(q.pack)
    )

    const allTypes = new Set(filterPackQuestionnaire.map((q) => q.type))
    const filterTypes = surveyTypes.filter((type) =>
      [...allTypes].includes(type.id)
    )
    setTypes(filterTypes)
    setSelectedTypes(allTypes)

    const allThemes = new Set(
      filterPackQuestionnaire.map((q) => q.thematique?.name).sort()
    )
    setThemes([...allThemes].map((t) => ({ id: t, title: t })))
    setSelectedThemes(allThemes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPacks])

  const data = useMemo(() => {
    const keys = ['label', 'type']
    const filterPackQuestionnaire = questionnaires.filter((q) =>
      [...selectedPacks].includes(q.pack)
    )
    const filterTypesQuestionnaires = filterPackQuestionnaire.filter((q) =>
      [...selectedTypes].includes(q.type)
    )
    const filterThemeQuestionnaires = filterTypesQuestionnaires.filter((q) =>
      [...selectedThemes].includes(q.thematique?.name)
    )
    const filterNewQuestionnaires = filterThemeQuestionnaires.filter(
      (q) => moment(q.createdAt).isAfter(delay) === true
    )

    const selectedQuestionnaires = selectedNew
      ? filterNewQuestionnaires
      : filterThemeQuestionnaires

    const search = selectedQuestionnaires.filter((q) =>
      keys.some((key) => q[key].toLowerCase().includes(query))
    )

    return search
  }, [
    questionnaires,
    selectedNew,
    selectedPacks,
    selectedTypes,
    selectedThemes,
    delay,
    query,
  ])

  return {
    packs,
    selectedPacks,
    onChangeSelectedPacks: setSelectedPacks,
    types,
    selectedTypes,
    onChangeSelectedTypes: setSelectedTypes,
    themes,
    selectedThemes,
    onChangeSelectedThemes: setSelectedThemes,
    selectedNew,
    onChangeSelectedNew: setSelectedNew,
    query,
    onChangeQuery: setQuery,
    data,
  }
}
