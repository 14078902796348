import React, { useState } from 'react'
import RHStatsModal from './RHStatsModal'
import { ButtonWithIcon } from '@olystic/design_system_3'
// Icons
import {
  faCircleExclamation,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { useStatRH } from '../../../../hooks/useStatRH'

export function RHStats({ baseId }) {
  const { t } = useTranslation()
  const [showReport, setShowReport] = useState(false)
  const { data, quality } = useStatRH()
  return (
    <>
      <ButtonWithIcon
        onClick={() => setShowReport(true)}
        icon={quality === 'success' ? faCircleCheck : faCircleExclamation}
        variant={quality}
        className={Object.keys(data).length && 'text-white'}
        disabled={!Object.keys(data).length}
      >
        {t('rh.detail.quality.cta')}
      </ButtonWithIcon>

      {showReport && (
        <RHStatsModal
          onHide={() => setShowReport(false)}
          show={showReport}
          baseId={baseId}
        />
      )}
    </>
  )
}
