import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faCog,
  faList,
  faCopy,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons'
import { EmailTypes } from '../../RespondentsList/EmailTypes'
// Translation
import { useTranslation } from 'react-i18next'

export default function UpdatedDropdown({
  item,
  onShow,
  onEdit,
  onQuestionnaire,
  onLinkCopy,
  onSendEmail,
  onDelete,
  show,
  onHide,
}) {
  const { t } = useTranslation()

  function handleToggle() {
    if (show) onHide()
    else onShow(item)
  }

  return (
    <td key='dropdown'>
      <Dropdown
        show={show}
        onToggle={handleToggle}
        style={{ position: 'inherit' }}
      >
        <Dropdown.Toggle
          as='div'
          bsPrefix='dropdown-toggle-RHTable'
          onClick={handleToggle}
          className='ps-2 cursor-pointer'
        >
          <Button variant='dark' className='p-2'>
            Actions
          </Button>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ minWidth: 'fit-content' }}>
          {onQuestionnaire && (
            <>
              <Dropdown.Item
                className='my-0 py-2 d-flex align-items-center fs-lg'
                onClick={() => onQuestionnaire(item)}
              >
                <FontAwesomeIcon className='me-2' icon={faList} />
                <span>{t('component.updatedDropdown.survey')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                className='my-0 py-2 d-flex align-items-center fs-lg'
                onClick={() => onLinkCopy(item)}
              >
                <FontAwesomeIcon className='me-2' icon={faCopy} />
                <span>{t('component.updatedDropdown.copy_link')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                className='my-0 py-2 d-flex align-items-center fs-lg'
                onClick={() =>
                  onSendEmail(
                    [item._id],
                    EmailTypes.ENQUETE_INDIVIDUELLE_LANCEMENT,
                  )
                }
              >
                <FontAwesomeIcon className='me-2' icon={faEnvelope} />
                <span>{t('component.updatedDropdown.send_invitation')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                className='my-0 py-2 d-flex align-items-center fs-lg'
                onClick={() =>
                  onSendEmail(
                    [item._id],
                    EmailTypes.ENQUETE_INDIVIDUELLE_RELANCE,
                  )
                }
              >
                <FontAwesomeIcon className='me-2' icon={faEnvelope} />
                <span>{t('component.updatedDropdown.send_relance')}</span>
              </Dropdown.Item>
              <Dropdown.Item
                className='my-0 py-2 d-flex align-items-center fs-lg'
                onClick={() =>
                  onSendEmail(
                    [item._id],
                    EmailTypes.ENQUETE_INDIVIDUELLE_CLOTURE,
                  )
                }
              >
                <FontAwesomeIcon className='me-2' icon={faEnvelope} />
                <span>{t('component.updatedDropdown.send_thank')}</span>
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item
            className='my-0 py-2 d-flex align-items-center fs-lg'
            onClick={() => onEdit(item)}
          >
            <FontAwesomeIcon className='me-2' icon={faCog} />
            <span>{t('component.updatedDropdown.cta1')}</span>
          </Dropdown.Item>
          <Dropdown.Item
            className='my-0 py-2 d-flex align-items-center text-danger fs-lg'
            onClick={() => onDelete(item)}
          >
            <FontAwesomeIcon className='me-2 ' icon={faTrash} />
            <span>{t('component.updatedDropdown.cta2')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </td>
  )
}
