import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

// Persistent tab states. Keep tab states when navigating between main view and detail view.

// Bases states
export const VIEW_BASES = 'view_bases'
export const VIEW_SURVEY = 'view_survey'

// Respondent states
export const VIEW_SONDES = 'view_sondes'
export const VIEW_GROUP = 'view_group'

export const RhContext = createContext({
  home: {
    view: VIEW_BASES,
    setView: () => {},
  },
  details: {
    view: VIEW_SONDES,
    setView: () => {},
  },
})

function RhPage() {
  const [baseView, setBaseView] = useState(VIEW_BASES)
  const [respondentView, setRespondentView] = useState(VIEW_SONDES)
  return (
    <RhContext.Provider
      value={{
        home: {
          view: baseView,
          setView: setBaseView,
        },
        details: {
          view: respondentView,
          setView: setRespondentView,
        },
      }}
    >
      <Outlet />
    </RhContext.Provider>
  )
}

export default RhPage
