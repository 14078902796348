import React from 'react'
// Components
import { qualityColor } from '../../../../lib/functions'
import { Card } from 'react-bootstrap'
import { useParticipation } from '../../../../hooks/useParticipation'
import { FormatNumber } from '@olystic/design_system_3'
// Translation
import { useTranslation } from 'react-i18next'

export default function RateCard({ survey }) {
  const { t } = useTranslation()
  const { rate } = useParticipation(survey._id)
  const type = survey?.questionnaire?.type
  const variant = type === 'Individuelle' ? 'dark' : qualityColor(rate)

  return (
    <Card
      className={`m-0 p-2 rounded-2 border border-2 border-${variant}`}
      style={{ maxWidth: '14rem' }}
    >
      <Card.Header className={`text-${variant} text-center`}>
        <h6 className='m-0'>{t('Taux de participation')}</h6>
      </Card.Header>
      <Card.Body className={`text-${variant} text-center`}>
        <h2 className='m-0'>{FormatNumber(rate, 1, '%')}</h2>
      </Card.Body>
    </Card>
  )
}
