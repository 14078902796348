import { OlysticApp } from '@olystic/olysticapp'
import config from './olystic.conf.json'
import { Provider } from 'react-redux'
import store from './redux/store'
import SidePanelProvider from './context/SidePanelContext'
import SchedulerProvider from './context/SchedulerContext'
import DeleteProvider from './context/DeleteContext.jsx'
import Router from './features/router/router'

function App() {
  return (
    <OlysticApp basepath='/' config={config}>
      <Provider store={store}>
        <SidePanelProvider>
          <SchedulerProvider>
            <DeleteProvider>
              <Router />
            </DeleteProvider>
          </SchedulerProvider>
        </SidePanelProvider>
      </Provider>
    </OlysticApp>
  )
}

export default App
