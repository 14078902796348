import surveys from "./surveys"
import questionnaires from "./questionnaires"
import respondents from "./respondents"
import steps from "./steps"
import waitingSurveys from "./waitingSurveys"

import navigation from "./navigation"

const rootReducer = {
  surveys,
  questionnaires,
  respondents,
  steps,
  navigation,
  waitingSurveys,
}

export default rootReducer
