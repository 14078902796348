import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

export function useRHImport(baseId) {
  const [showImportModelModal, setImportFileModal] = useState(false)
  const queryClient = useQueryClient()

  function handleReload() {
    queryClient.invalidateQueries(["respondent", "list", baseId]).catch(console.error)
    queryClient.invalidateQueries(["rhbase", baseId]).catch(console.error)
  }

  return {
    onImport() {
      setImportFileModal(true)
    },
    rhImportProps: {
      showImportModelModal,
      setImportFileModal,
      onReload: handleReload,
      baseId,
    },
  }
}
