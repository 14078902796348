import React from "react"
import PropTypes from "prop-types"
// Components
import { IconButton } from "@olystic/design_system_3"
import { useSelector } from "react-redux"
import { surveySelector } from "../../redux/reducers/surveys"
import { datesToSurveyEvent, translateSurveyEvent } from "../../lib/surveyEvents"
// Icons
import { faEdit } from "@fortawesome/pro-regular-svg-icons"
import { ReactComponent as ValidFollowUp } from "../../assets/icons/followUp-valid.svg"
import { ReactComponent as WaitingFollowUp } from "../../assets/icons/followUp-waiting.svg"
// Translation
import { useTranslation } from "react-i18next"

FollowUpSurvey.propTypes = {
  surveyId: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
}

const MAPPING_STATE = {
  past: { component: <ValidFollowUp />, color: "success" },
  now: { component: <WaitingFollowUp />, color: "warning" },
  future: {
    component: (
      <div className="center" style={{ width: "2rem", height: "2rem" }}>
        <span className="rounded-circle bg-secondary" style={{ width: "0.75rem", height: "0.75rem" }} />
      </div>
    ),
    color: "secondary",
  },
}

export default function FollowUpSurvey({ surveyId, onEdit }) {
  const { t } = useTranslation()
  const survey = useSelector((state) => surveySelector.selectById(state, surveyId))
  const surveyEvent = datesToSurveyEvent(survey.dates)

  return (
    <div className="ms-8">
      {surveyEvent.map((event, i) => (
        <div key={event.key} className="d-flex align-items-center gap-4 mb-4">
          <div className="position-relative">
            {MAPPING_STATE[event.state].component}
            {i !== 0 && (
              <div
                className={`position-absolute start-50 translate-middle bg-${MAPPING_STATE[event.state].color}`}
                style={{ height: "1rem", width: "1px", top: "-0.5rem" }}
              ></div>
            )}
          </div>
          <div>
            <p className="mb-1 fs">{translateSurveyEvent(t, event)}</p>
            <div className="d-flex">
              <p className="fs-sm text-blue-600 m-0">{event.date.format("DD/MM/YY")}</p>
              {event.state !== "past" && (
                <IconButton className="text-secondary fa-sm ms-1" icon={faEdit} onClick={onEdit} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
