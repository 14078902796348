import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { questionnairesProps } from '@olystic/common/proptypes/questionnaire.mjs'
import useScheduler from '../../hooks/useScheduler'
import { QuestionnaireCard as QuestionnaireCardUI } from '@olystic/design_system_3'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../redux/reducers/surveys'
import { isWithinInterval, parseISO } from 'date-fns'

QuestionnaireCard.propTypes = {
  data: questionnairesProps.isRequired,
  onShowPurchingModal: PropTypes.func,
  onShowQuestionsModal: PropTypes.func,
  isDraggable: PropTypes.bool,
}

QuestionnaireCard.defaultProps = {
  onShowPurchingModal: () => {},
  onShowQuestionsModal: () => {},
  isDraggable: false,
}

/**
 * Counts the number of similar surveys that have been launched within the current period.
 * @param {Array} surveys - List of available surveys.
 * @param {Object} data - Data of the current questionnaire.
 * @param {Date} periodStart - Start date of the current period.
 * @param {Date} periodEnd - End date of the current period.
 * @returns {number} - Number of similar surveys within the period.
 */
function countSurveyInCurrentPeriod(surveys, data, periodStart, periodEnd) {
  return surveys.filter((survey) => {
    const surveyLaunchDate = parseISO(survey.dates.Lancement)
    const isSameTitle = survey.name === data.name
    const isLaunchDateWithinPeriod = isWithinInterval(surveyLaunchDate, {
      start: periodStart,
      end: periodEnd,
    })
    return isSameTitle && isLaunchDateWithinPeriod
  }).length
}

export default function QuestionnaireCard({
  data,
  onShowPurchingModal,
  onShowQuestionsModal,
  isDraggable,
  limit,
  period,
}) {
  const { toggleScheduler } = useScheduler()

  const surveys = useSelector((state) => surveySelector.selectAll(state))
  const [dragging, setDragging] = React.useState(false)
  const [ghostId, setGhostId] = React.useState('')

  const { actif, nbQuestions } = data

  const isBlocked = useMemo(() => {
    if (!limit || !period) {
      return false // Do not block if there is no limit specified for the type of survey
    }

    const countSurvey = countSurveyInCurrentPeriod(
      surveys,
      data,
      period.startOfCurrentPeriod,
      period.endOfCurrentPeriod,
    )

    // Block if the number of similar surveys in the period exceeds the limit
    return countSurvey >= limit
  }, [data, limit, period, surveys])

  const handleDragStart = (e, id, label, type) => {
    if (!actif || !isDraggable || isBlocked) return
    setDragging(true)
    toggleScheduler(true)
    let dragImage = document.createElement('div')
    dragImage.id = 'ghostEnquete--' + type
    setGhostId(dragImage.id)
    document.body.appendChild(dragImage)
    e.dataTransfer.setDragImage(dragImage, 0, 0)
    e.dataTransfer.setData('text/questionnaire', id)
    e.dataTransfer.setData('text/questionnaireName', label)
    e.dataTransfer.setData('text/questionnaireType', type)
    e.dataTransfer.setData('text/nbQuestions', nbQuestions)
  }

  const handleDragEnd = () => {
    setDragging(false)
    let ghostEnquete = document.getElementById(ghostId)
    ghostEnquete && ghostEnquete.remove()
  }

  return (
    <QuestionnaireCardUI
      data={data}
      onShowPurchingModal={onShowPurchingModal}
      onShowQuestionsModal={onShowQuestionsModal}
      isDraggable={isDraggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      dragging={dragging}
      blocked={isBlocked}
    />
  )
}
