import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '../services/backend'
import { useMemo } from 'react'
import { notification } from '@olystic/design_system_3'
// Translation
import { useTranslation } from 'react-i18next'

export function useRHBase(id) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const groups = useQuery(['rhbase', id, 'groups'], () =>
    backend.rh.getBaseGroups(id),
  )
  const base = useQuery(['rhbase', id], () => backend.rh.getBase(id))

  const isSurvey = useMemo(() => {
    return base.data && base.data.survey
  }, [base])

  const isGroup = useMemo(() => {
    return !!(base.data && base.data.parentGroup)
  }, [base])

  const createGroup = useMutation(
    (payload) => backend.rh.createBaseGroups(id, payload),
    {
      onSettled: () => queryClient.invalidateQueries(['rhbase', id, 'groups']),
      onError: (error) => {
        console.log(error)
        notification.error(t('notification.try_again'), 'lg')
      },
    },
  )

  const deleteGroup = useMutation((base) => backend.rh.deleteBase(base), {
    onSuccess: () => {
      queryClient.invalidateQueries(['rhbase', id, 'groups'])
    },
    onError: (error) => {
      console.log(error)
      notification.error(t('notification.try_again'), 'lg')
    },
  })

  const updateGroup = useMutation((base) => backend.rh.updateBase(base), {
    onSuccess: () => {
      queryClient.invalidateQueries(['rhbase', id, 'groups'])
      queryClient.invalidateQueries(['rhbase'])
      queryClient.invalidateQueries(['respondent', 'list', id])
    },
    onError: (error) => {
      console.log(error)
      notification.error(t('notification.try_again'), 'lg')
    },
  })

  return {
    id,
    base,
    groups,
    createGroup,
    deleteGroup,
    updateGroup,
    isSurvey,
    isGroup,
  }
}
