import { createSlice } from "@reduxjs/toolkit"

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    title: "Entreprise",
    breadcrumbs: [],
  },
  reducers: {
    setTitle: (state, { payload }) => {
      state.title = payload
      return state
    },
  },
})

export const { setTitle, setBreadcrumbs } = navigationSlice.actions

export default navigationSlice.reducer
