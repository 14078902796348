import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BackButton } from '@olystic/design_system_3'

export function RHLayout({ children }) {
  return <div id='RHPage'>{children}</div>
}

function RHPageHeader({ title, subtitle, navigateBack, onBack, children }) {
  const { t } = useTranslation()

  return (
    <div
      className='border-bottom bg-white pt-7 px-9 pb-0 position-relative'
      id='BaseHeader'
    >
      {navigateBack && (
        <h3 className='mt-0'>
          <BackButton onClick={onBack} />
        </h3>
      )}
      <h2 className='mb-3 mt-0'>{title ? title : t('rh.page.title')}</h2>
      {subtitle && (
        <p className='text-width text-secondary'>
          {subtitle ? subtitle : t('rh.page.subtitle')}
        </p>
      )}
      {children}
    </div>
  )
}

RHPageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
}

function RHPageBody({ children }) {
  return <div>{children}</div>
}

RHLayout.Header = RHPageHeader
RHLayout.Body = RHPageBody
