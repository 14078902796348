import React from "react"
// Components
import moment from "moment"
import { surveyProps } from "@olystic/common/proptypes/survey"
import { mappingSurvey } from "@olystic/common/survey.mjs"
import InfoDateSurvey from "../../../popovers/InfoDateSurvey/InfoDateSurvey"
import PlanningDate from "../../../popovers/PlanningDate/PlanningDate"
import { useSurveyTarget } from "../../../../hooks/useSurveyTarget"
import useModal from "../../../../hooks/useModal"
import QuestionModal from "../../../modals/QuestionsModal/QuestionModal"

PeriodSurvey.propTypes = {
  survey: surveyProps.isRequired,
}

export default function PeriodSurvey({ survey }) {
  const ref = React.useRef()
  const [showInfoPopover, setShowInfoPopover] = React.useState(false)
  const [showPlanningDate, setShowPlanningDate] = React.useState(false)
  const { selectedTarget } = useSurveyTarget(survey)
  const questionModal = useModal()

  const data = React.useMemo(() => {
    let launch, closing

    if (survey.dates.Lancement) {
      launch = moment(survey.dates.Lancement)
    }
    if (survey.dates.Clôture) {
      closing = moment(survey.dates.Clôture)
    }

    return {
      _id: survey._id,
      name: survey.name,
      nbQuestions: survey?.nbQuestions || survey.questionnaire?.nbQuestions,
      launch,
      closing,
      selectedTarget: selectedTarget?.name,
      type: survey.questionnaire.type,
    }
  }, [selectedTarget?.name, survey])

  function handleShowInfoPopover() {
    showPlanningDate === false && setShowInfoPopover(!showInfoPopover)
  }

  function handleShowPopover() {
    setShowInfoPopover(false)
    setShowPlanningDate(true)
  }

  function handleShowQuestionModal() {
    questionModal.onShow()
    setShowInfoPopover(false)
    setShowPlanningDate(false)
  }

  return (
    <>
      <div
        className={`position-absolute rounded-2 cursor-pointer bg-${
          mappingSurvey(survey.questionnaire.type).color
        }`}
        style={{
          left: survey.positionSurvey / 16 + "rem",
          width: survey.size / 16 + "rem",
          height: "1.3rem",
        }}
        onClick={handleShowInfoPopover}
        ref={ref}
      />
      {showInfoPopover && (
        <InfoDateSurvey
          data={data}
          onHide={() => setShowInfoPopover(false)}
          show={showInfoPopover}
          ref={ref}
          onHandleShowPopover={handleShowPopover}
          onHandleShowQuestionModal={handleShowQuestionModal}
        />
      )}
      {showPlanningDate && (
        <PlanningDate
          survey={survey}
          show={showPlanningDate}
          onHide={() => setShowPlanningDate(false)}
          onClose={() => setShowPlanningDate(false)}
          onHandleShowQuestionModal={handleShowQuestionModal}
          ref={ref}
        />
      )}

      {questionModal.modalProps.show && (
        <QuestionModal
          {...questionModal.modalProps}
          questionnaire={survey.questionnaire}
          canView={false}
        />
      )}
    </>
  )
}
