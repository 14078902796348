import React, { useMemo } from 'react'
import { parseJSON } from 'date-fns'
import { differenceInCalendarDays } from 'date-fns/esm'
import { useParticipation } from '../../../hooks/useParticipation'
import ParticipationRate from './ParticipationRate'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../../redux/reducers/surveys'
// Translation
import { useTranslation } from 'react-i18next'

export default function ParticipationRateContainer({ surveyId }) {
  const { t } = useTranslation()
  const { participation } = useParticipation(surveyId)
  const survey = useSelector((state) =>
    surveySelector.selectById(state, surveyId),
  )

  const info = {
    title: '',
    summary: null,
    description: null,
  }

  const data = useMemo(() => {
    const { Lancement, Clôture, ...relances } = { ...survey.dates }
    let data = []
    let rate = 0
    let maxDays = 25
    let p = []

    const launch = parseJSON(Lancement)
    const nbDays = differenceInCalendarDays(parseJSON(Clôture), launch) + 1
    const now = differenceInCalendarDays(parseJSON(new Date()), launch)

    participation?.daily.forEach((r) => {
      const index = differenceInCalendarDays(r.date, launch)
      const newRate = (r.nbResponse / (r.nbRespondent || 1)) * 100

      const item = {
        _id: r._id,
        enterprise: newRate,
        day: index + 1,
      }

      p.push(item)
    })

    data = Array(nbDays)
      .fill(null)
      .map((item, i) => {
        let object = {
          _id: i,
          enterprise: rate,
          day: i + 1,
          label: `j${i + 1}`,
        }
        const haveParticipation = p.find((part) => part.day - 1 === i)
        if (haveParticipation) {
          rate = haveParticipation.enterprise
          object = { ...object, ...haveParticipation }
        }

        // if (i === now) {
        //   object = { ...object, label: `Aujourd'hui` }
        // }

        if (i > now) {
          object = { ...object, enterprise: null }
        }

        return object
      })

    data[data.length - 1].event = t('events.cloture')

    if (relances) {
      for (const [key, value] of Object.entries(relances)) {
        const index = differenceInCalendarDays(parseJSON(value), launch)
        data[index].event = key.includes('xcept')
          ? t('events.except', { number: key.slice(-1) })
          : t('events.relance', { number: key.slice(-1) })
      }
    }

    let bigData = []

    if (nbDays > maxDays) {
      let rate
      for (let [index, value] of Object.entries(data)) {
        const rules =
          value.event ||
          (value.enterprise && value.enterprise !== rate) ||
          Number(index) === now
        if (rules) {
          rate = value.enterprise
          bigData.push(value)
        }
      }

      data = bigData
    }

    return data
  }, [survey.dates, participation?.daily, t])

  const dataByCategory = useMemo(() => {
    const data = []
    participation?.details.forEach((item) => {
      if (item.category !== 'all')
        data.push({
          name: t(item.title),
          id: item.category,
          responses: item.population.map((p) => ({
            id: p._id,
            name: t(p.title),
            response: p.response,
            total: p.total,
            value: Number.parseFloat(((p.response / p.total) * 100).toFixed(0)),
          })),
        })
    })

    return data
  }, [participation?.details, t])

  return (
    <ParticipationRate
      data={data}
      dataByCategory={dataByCategory}
      info={info}
      surveyId={surveyId}
    />
  )
}
