import React from "react"
import style from "./EmptyGantt.module.scss"
import PropTypes from "prop-types"
// Components
import { Card } from "react-bootstrap"
import { downloadChartWithCanva } from "../../../lib/functions"
import InteractionIcons from "../../cards/interactionIcons"
import { ButtonWithIcon, Center } from "@olystic/design_system_3"
import { Link } from "react-router-dom"
// Icons
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

export default function EmptyGantt({ info }) {
  const { t } = useTranslation()
  const ref = React.createRef()

  return (
    <Card className="overflow-hidden" ref={ref}>
      <Card.Header className={style.header}>
        <div className={style.corner}>
          <p className="position-relative text-center fs-lg top-50 translate-middle-y">{t("component.gantt.title")}</p>
        </div>
        <div className={style.headerRight}>
          <div></div>
          <Link to="/">
            <ButtonWithIcon icon={faPlus} variant="" className="p-0 text-primary">
              {t("component.gantt.cta")}
            </ButtonWithIcon>
          </Link>

          <InteractionIcons download={() => downloadChartWithCanva(ref.current)} description={info.description} />
        </div>
      </Card.Header>
      <Card.Body className={style.body}>
        <Center className="flex-column">
          <p className="mb-4 text-center">{t("component.gantt.empty.text")}</p>
          <Link to="/">
            <ButtonWithIcon icon={faPlus}> {t("component.gantt.cta")}</ButtonWithIcon>
          </Link>
        </Center>
      </Card.Body>
    </Card>
  )
}

EmptyGantt.propTypes = {
  summary: PropTypes.string,
  description: PropTypes.string,
}
