import React from "react"
import PropTypes from "prop-types"
import backend from "../../../services/backend"
import { useAuth } from "@olystic/olysticapp"
// Components
import { StepModal } from "@olystic/design_system_3"
// Icons
import welcome1 from "../../../assets/images/welcome1.svg"
import welcome1EN from "../../../assets/images/welcome1EN.png"
import welcome2 from "../../../assets/images/welcome2.svg"
import welcome2EN from "../../../assets/images/welcome2EN.png"
import welcome3 from "../../../assets/images/welcome3.svg"
// Translation
import { useTranslation } from "react-i18next"

export default function OnBoarding({ show, onHide, isFirstConnection }) {
  const { t } = useTranslation()
  const { user } = useAuth()

  const steps = [
    {
      id: 1,
      step: 1,
      text: t("component.onBoardingModal.step1"),
      image: { fr: welcome1, others: welcome1EN },
    },
    {
      id: 2,
      step: 2,
      text: t("component.onBoardingModal.step2"),
      image: { fr: welcome2, others: welcome2EN },
    },
    {
      id: 3,
      step: 3,
      text: t("component.onBoardingModal.step3"),
      image: { fr: welcome3, others: welcome3 },
    },
  ]

  const closeModal = async () => {
    if (isFirstConnection) {
      try {
        await backend.steps.putSteps({ firstConnection: false })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <StepModal
      show={show}
      onHide={onHide}
      steps={steps}
      onFinish={closeModal}
      title={t("component.onBoardingModal.title")}
      language={user.language}
    />
  )
}

OnBoarding.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  isFirstConnection: PropTypes.bool.isRequired,
}

OnBoarding.defaultProps = {
  language: "fr",
}
