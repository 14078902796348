import React from "react"
import style from "./Gantt.module.scss"
import classnames from "classnames"
import PropTypes from "prop-types"
// Components
import moment from "moment"
import { Card } from "react-bootstrap"
import { capitalizeFirstLetter, downloadChartWithCanva } from "../../../lib/functions"
import Line from "./components/Line"
import PeriodSurvey from "./components/PeriodSurvey"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { faSearchMinus, faSearchPlus } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ButtonWithIcon, Slider } from "@olystic/design_system_3"
import InteractionIcons from "../../cards/interactionIcons"

export default function Gantt({
  summary,
  description,
  scale,
  onHandleScale,
  months,
  data,
  width,
  position,
  onPositionChange,
  minimum,
  pixelsByDay,
}) {
  const { t } = useTranslation()
  const ref = React.useRef(null)

  const [currentMouseMoveHandler, setCurrentMouseMoveHandler] = React.useState(() => () => {})

  let currentPosition = 0

  function handleMouseDown(event) {
    currentPosition = event.clientX
    setCurrentMouseMoveHandler(() => handleMouseMove)
  }

  function handleMouseMove(event) {
    let scroll = position + (event.clientX - currentPosition)

    if (scroll <= 0 && scroll >= minimum) {
      onPositionChange(scroll)
    }
  }

  function handleMouseUp() {
    setCurrentMouseMoveHandler(() => () => {})
  }

  return (
    <Card className="overflow-hidden" ref={ref}>
      <Card.Header className={style.header}>
        <div className={style.corner}>
          <p className="position-relative text-center fs-lg top-50 translate-middle-y">{t("component.gantt.title")}</p>
        </div>
        <div className={style.headerRight}>
          <div
            className="d-flex align-items-center justify-content-between px-4 border-bottom"
            style={{ height: "4.5rem" }}
          >
            <div className={style.zoom}>
              <FontAwesomeIcon icon={faSearchMinus} className="fa-sm text-secondary" />
              <Slider marks={false} min={0} max={100} value={scale} onChange={onHandleScale} />
              <FontAwesomeIcon icon={faSearchPlus} className="fa-sm text-secondary" />
            </div>

            <Link to="/">
              <ButtonWithIcon icon={faPlus} variant="" className="p-0 text-primary">
                {t("component.gantt.cta")}
              </ButtonWithIcon>
            </Link>

            <InteractionIcons
              summary={summary}
              description={description}
              download={() => downloadChartWithCanva(ref.current)}
            />
          </div>
          <div className={style.months_wrapper}>
            <div
              className={style.months}
              style={{ width: width / 16 + "rem", left: position / 16 + "rem" }}
              onMouseMove={(e) => currentMouseMoveHandler(e)}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {months.map((m, i) => (
                <div className={style.month} key={i}>
                  <p>{capitalizeFirstLetter(moment(m).format("MMMM")[0])}</p>
                  <span></span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body className={style.body}>
        <div className={style.titles_container}>
          {data.map((survey, i) => (
            <div key={survey._id} className={classnames(style.title, { "border-top-0": i === 0 })}>
              <p>{survey.name}</p>
            </div>
          ))}
        </div>
        <div className={style.timeline_container}>
          <Line scale={scale} position={position} pixelsByDay={pixelsByDay} />

          {data.map((survey, i) => (
            <div
              key={survey._id}
              className={classnames(style.timeline, { [style.firstTimeline]: i === 0 })}
              style={{ width: width / 16 + "rem", left: position / 16 + "rem" }}
              onMouseMove={(e) => currentMouseMoveHandler(e)}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <PeriodSurvey survey={survey} />
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  )
}

Gantt.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  onHandleScale: PropTypes.func.isRequired,
  months: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  onPositionChange: PropTypes.func.isRequired,
  minimum: PropTypes.number.isRequired,
  pixelsByDay: PropTypes.number.isRequired,
}
