import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import authAPI from './backend/auth.api'
import perimeterAPI from './backend/perimeter.api'
import { setJwt } from './axiosService'
import Loading from './loading'
import usePermissions from './hooks/usePermissions'

export const AuthContext = React.createContext()

function Auth({ redirectTo, roles, children }) {
  const [user, setUser] = useState()
  const [perimeter, setPerimeter] = useState()
  const permissions = usePermissions(user, perimeter)

  async function updateUser(user) {
    try {
      const res = await authAPI.update(user)
      setUser(res.data)
    } catch (e) {
      try {
        await authAPI.logout()
        // eslint-disable-next-line no-empty
      } catch (e) {}
      window.location.href = '/'
    }
  }

  function redirect() {
    window.location.href = redirectTo
  }

  useEffect(
    () => {
      let isCanceled = false
      if (!user) {
        const token = localStorage.getItem('jwt')
        if (token) setJwt(token)
        authAPI
          .getMe()
          .then((res) => {
            if (!isCanceled) {
              if (roles.includes(res.data.role)) setUser(res.data)
              else redirect()
            }
          })
          .catch(() => {
            if (!isCanceled) redirect()
          })
      }
      if (!perimeter) {
        perimeterAPI
          .getPerimeter()
          .then((res) => {
            if (!isCanceled) {
              setPerimeter(res.data)
            }
          })
          .catch(() => {
            if (!isCanceled) redirect()
          })
      }
      return () => {
        isCanceled = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        perimeter,
        permissions,
        logout: authAPI.logout,
      }}
    >
      {user && perimeter ? children : <Loading />}
    </AuthContext.Provider>
  )
}

Auth.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
}

Auth.defaultProps = {
  redirectTo: null,
  roles: ['root'],
  children: null,
}

export default Auth
