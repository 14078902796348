import React from "react"
import PropTypes from "prop-types"
import { format as _format, isValid, parseISO } from "date-fns"

Date.propTypes = {
  children: PropTypes.string,
  format: PropTypes.string,
}

function Date({ format, children, ...props }) {
  const date = parseISO(children)
  if (!isValid(date)) return null

  const dateText = _format(date, format)

  return <span {...props}>{dateText}</span>
}

Date.defaultProps = {
  format: "dd/MM/yyyy",
}

export default Date
