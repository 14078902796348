import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import classNames from "classnames"

// eslint-disable-next-line react/prop-types
function Section({ className, children }) {
  return (
    <Container className="w-xxl" id="PageContainer">
      <Row className={classNames("gap-3 mb-4 px-8", className)}>
        <Col className="d-flex align-items-center align-items-xl-start flex-column">
          <div className="w-100">{children}</div>
        </Col>
      </Row>
    </Container>
  )
}

export default Section
