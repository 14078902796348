/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTable, useRowSelect, useSortBy, usePagination } from 'react-table'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt as falTrashAlt } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import { PaginationContainer } from '@olystic/design_system_3'

export default function ProgrammingValidationTable({
  data,
  columns,
  onDeleteSurvey,
  onSelectedSurveys,
}) {
  const { t } = useTranslation()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { pageIndex },
    selectedFlatRows,
  } = useTable(
    { columns, data, initialState: { pageSize: 5 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="ms-n3 mt-2">
              <Form.Check {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className="ms-n3">
              <Form.Check {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  useEffect(() => {
    if (selectedFlatRows) {
      onSelectedSurveys(selectedFlatRows.map((d) => d.original))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows])

  return (
    <>
      <div>
        <table {...getTableProps()} className="w-100">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, id) => (
                  <th key={id} {...column.getHeaderProps()}>
                    <div className="d-flex align-items-center justify-content-center text-center mb-5 px-3 fs-lg">
                      <span
                        {...column.getSortByToggleProps()}
                        className={classnames(
                          column.isSorted && 'color-orange'
                        )}
                      >
                        {column.render('Header')}
                      </span>
                    </div>
                  </th>
                ))}
                <th>
                  <div className="d-flex align-items-center d-flex align-items-center justify-content-center text-center mb-5 px-3 fs-lg">
                    <span>{t('common.deleted')}</span>
                  </div>
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, id) => {
                    return (
                      <td key={id} {...cell.getCellProps()}>
                        <div className="d-flex align-items-center d-flex align-items-center justify-content-center text-center mb-3 px-3 fs-lg text-secondary">
                          {cell.render('Cell')}
                        </div>
                      </td>
                    )
                  })}
                  <td>
                    <div className="d-flex align-items-center d-flex align-items-center justify-content-center text-center mb-3 px-3 fs text-danger">
                      <FontAwesomeIcon
                        icon={falTrashAlt}
                        onClick={() => onDeleteSurvey(row.original.id)}
                        className="cursor-pointer fa-lg"
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <PaginationContainer
          numberOfPages={pageCount}
          activePage={pageIndex + 1}
          onPageClick={(page) => gotoPage(page - 1)}
        />
      </div>
    </>
  )
}

ProgrammingValidationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  onDeleteSurvey: PropTypes.func.isRequired,
  onSelectedSurveys: PropTypes.func.isRequired,
}

ProgrammingValidationTable.defaultProps = {
  data: [],
  columns: [],
}
