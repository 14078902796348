/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'
import { useAuth } from '@olystic/olysticapp'
import useModal from '../hooks/useModal'
// Components
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import Section from '../components/layout/section'
import {
  ButtonWithIcon,
  Scrollbar,
  MultiSelectionDropdown,
} from '@olystic/design_system_3'
import Scheduler from '../features/scheduler'
import QuestionnaireCard from '../components/cards/QuestionnaireCard'
import QuestionModal from '../components/modals/QuestionsModal/QuestionModal'
import PurchasingQuestionnaireModal from '../components/modals/PurchasingQuestionnaireModal/PurchasingQuestionnaireModal'
import ProgrammingValidationModal from '../components/modals/ProgrammingValidationModal'
import useFilterQuestionnaire from '../hooks/useFilterQuestionnaire'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faSearch, faGear } from '@fortawesome/pro-solid-svg-icons'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { useSubscription } from '../hooks/useSubscription'
import {
  addYears,
  getYear,
  isBefore,
  parseISO,
  setYear,
  subYears,
} from 'date-fns'

export default function SchedulerPage() {
  const { t } = useTranslation()
  const { permissions } = useAuth()
  const {
    packs,
    selectedPacks,
    onChangeSelectedPacks,
    types,
    selectedTypes,
    onChangeSelectedTypes,
    themes,
    selectedThemes,
    onChangeSelectedThemes,
    selectedNew,
    onChangeSelectedNew,
    query,
    onChangeQuery,
    data,
  } = useFilterQuestionnaire()
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState({})
  const programmingValidationModal = useModal()
  const [showQuestionModal, setShowQuestionModal] = useState(false)
  const [showPurchasingModal, setShowPurchasingModal] = useState(false)
  const canEditQuestionnaire = permissions.includes('editQuestionnaire')
  const { subscriptionQuery } = useSubscription()

  const period = useMemo(() => {
    if (subscriptionQuery.data) {
      const subscription = subscriptionQuery.data
      const currentDate = new Date()
      const subscriptionStartDate = parseISO(subscription.date)
      const subscriptionEndDate = addYears(
        subscriptionStartDate,
        subscription.duration,
      )

      // Block if current date is past the subscription end date
      if (isBefore(subscriptionEndDate, currentDate)) {
        return true
      }

      // Define the current period based on the anniversary date
      const currentYear = getYear(currentDate)
      let startOfCurrentPeriod = setYear(subscriptionStartDate, currentYear)
      let endOfCurrentPeriod = addYears(startOfCurrentPeriod, 1)

      // Adjust period to the previous year if current date is before this year's anniversary
      if (isBefore(currentDate, startOfCurrentPeriod)) {
        startOfCurrentPeriod = subYears(startOfCurrentPeriod, 1)
        endOfCurrentPeriod = subYears(endOfCurrentPeriod, 1)
      }

      return { startOfCurrentPeriod, endOfCurrentPeriod }
    }
  }, [data, subscriptionQuery])

  function handleShowModal(id, modifier) {
    const questionnaire = data.filter((d) => d._id === id)
    setSelectedQuestionnaire(...questionnaire)
    modifier(true)
  }

  function handleHideModal(modifier) {
    modifier(false)
    setSelectedQuestionnaire({})
  }

  if (subscriptionQuery.isLoading) return null

  const subscription = subscriptionQuery.data

  return (
    <Section className='mt-5'>
      <ButtonWithIcon
        icon={farChevronRight}
        className='float-end mt-6'
        onClick={programmingValidationModal.onShow}
        id='NextButton'
      >
        {t('common.next')}
      </ButtonWithIcon>

      <h3 className='pt-5'>{t('page.scheduler.title')}</h3>
      <p className='text-width'>{t('page.scheduler.description')}</p>

      <Row className='mt-5 d-flex gap-3'>
        <Col id='FilterSurveys' xxl='9'>
          <div className='d-flex align-items-center gap-3 flex-wrap'>
            <MultiSelectionDropdown
              items={packs}
              selectedItems={selectedPacks}
              onSelectionChange={(item) => onChangeSelectedPacks(item)}
              title={t('survey.pack')}
              id='packs'
            />
            <MultiSelectionDropdown
              items={types}
              selectedItems={selectedTypes}
              onSelectionChange={(item) => onChangeSelectedTypes(item)}
              title={t('survey.type')}
              id='types'
            />
            <MultiSelectionDropdown
              items={themes}
              selectedItems={selectedThemes}
              onSelectionChange={(item) => onChangeSelectedThemes(item)}
              title={t('survey.theme')}
              icon={<FontAwesomeIcon icon={faBook} className='mr-2' />}
              id='themes'
            />
            <Form.Check
              checked={selectedNew}
              onChange={() => onChangeSelectedNew(!selectedNew)}
              label={t('survey.new')}
            />
          </div>

          <InputGroup className='mt-3'>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              style={{ maxWidth: '25rem' }}
              name={'search'}
              label='Rechercher'
              onChange={(e) => {
                onChangeQuery(e.target.value.toLowerCase())
              }}
              value={query}
              type='text'
              placeholder={t('page.scheduler.search-placeholder')}
              id='SearchSurveys'
            />
          </InputGroup>
        </Col>

        <Col id='editQuestionnaireButton' className='text-xxl-end'>
          <ButtonWithIcon
            icon={faGear}
            variant='success'
            onClick={() => (window.location.href = '/editeur')}
          >
            {t('page.scheduler.editQuestionnaire')}
          </ButtonWithIcon>
        </Col>
      </Row>

      <div>
        <Scrollbar
          style={{ maxHeight: '60vh', marginTop: '2rem', marginRight: '-2rem' }}
        >
          <div
            className='d-flex align-items-center flex-wrap gap-4'
            id='Surveys'
          >
            {data.map((item) => (
              <QuestionnaireCard
                key={item._id}
                data={item}
                onShowPurchingModal={(id) =>
                  handleShowModal(id, setShowPurchasingModal)
                }
                onShowQuestionsModal={(id) =>
                  handleShowModal(id, setShowQuestionModal)
                }
                isDraggable={true}
                limit={
                  subscription.level.options.barometre?.[`limit${item.type}`]
                }
                period={period}
              />
            ))}
          </div>
        </Scrollbar>
      </div>

      <Scheduler />

      {showQuestionModal && (
        <QuestionModal
          show={showQuestionModal}
          onHide={() => handleHideModal(setShowQuestionModal)}
          questionnaire={selectedQuestionnaire}
          canEdit={canEditQuestionnaire && selectedQuestionnaire.actif}
        />
      )}

      {showPurchasingModal && (
        <PurchasingQuestionnaireModal
          show={showPurchasingModal}
          onHide={() => handleHideModal(setShowPurchasingModal)}
          questionnaire={selectedQuestionnaire}
        />
      )}

      <ProgrammingValidationModal {...programmingValidationModal.modalProps} />
    </Section>
  )
}
