import React from 'react'
// Components
import Section from '../components/layout/section'
import FeedbackLauncher from '../features/feedback/FeedbackLauncher'

export default function Feedback() {
  return (
    <Section className='mt-9'>
      <FeedbackLauncher />
    </Section>
  )
}
