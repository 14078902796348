import { http } from "@olystic/olysticapp"
import { gc } from "./constant"
import { parseJSON, compareAsc } from "date-fns"

const responseRates = {
  async getResponseRates(surveyId) {
    const res = await http.get(`${gc}/surveys/${surveyId}/rates`)
    const rates = res.data
    rates.daily = rates.daily.map((r) => ({ ...r, date: parseJSON(r.date) })).sort((a, b) => compareAsc(a.date, b.date))
    return rates
  },
}

export default responseRates
