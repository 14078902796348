import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Formik, Form as FormikForm } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Scrollbar, TextInputFormik } from '@olystic/design_system_3'

const FORM_ID = '5dd673d0-9bed-11ed-9697-7b23fc8fb9b9'
function EmailModal({ show, onHide, email, onSuccess }) {
  const { t } = useTranslation()
  const [html, setHtml] = useState('')

  const validationSchema = yup.object({
    to: yup.string().required(t('component.emailModal.error_to_email')),
    from: yup.object({
      email: yup
        .string()
        .email()
        .required(t('component.emailModal.error_from_email')),
      name: yup.string().required(t('component.emailModal.error_to_name')),
    }),
    subject: yup.string().required(),
  })

  useEffect(() => {
    if (email) {
      setHtml(email.html)
    }
  }, [email])

  async function handleSubmit(values) {
    const payload = {
      ...values,
      html,
    }
    if (email.template === 'interviewer') {
      payload.links = [{ to: values.to }]
    }
    delete payload.to
    await onSuccess(payload)
  }

  if (!email) return null

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{t('component.emailModal.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{ ...email }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <FormikForm id={FORM_ID}>
            <Row className='mb-3'>
              <Col>
                <TextInputFormik
                  name={'from.email'}
                  label={t('component.emailModal.label_email_from')}
                />
              </Col>
              <Col>
                <TextInputFormik
                  name={'from.name'}
                  label={t('component.emailModal.label_name_from')}
                />
              </Col>
            </Row>
            <TextInputFormik
              className='my-3'
              name={'to'}
              label={t('component.emailModal.label_email_to')}
              disabled={!!email.to}
            />
            <TextInputFormik
              className='my-3'
              name={'subject'}
              label={t('component.emailModal.subject')}
            />
          </FormikForm>
        </Formik>
        <Form.Label>{t('component.emailModal.label_message')}</Form.Label>
        <Scrollbar
          style={{
            maxHeight: '30vh',
            paddingRight: '1.5rem',
          }}
        >
          <ReactQuill theme='snow' value={html} onChange={setHtml} />
        </Scrollbar>
        <div className='d-flex justify-content-end mt-4 gap-3'>
          <Button variant='secondary' onClick={onHide}>
            {t('common.cancel')}
          </Button>
          <Button form={FORM_ID} type={'submit'}>
            {t('common.send')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EmailModal
