import { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DeleteContext } from "../context/DeleteContext"
import { surveySelector } from "../redux/reducers/surveys"
import { removeOne } from "../redux/reducers/waitingSurveys"
import backend from "../services/backend"

export default function useDeleteSurvey() {
  const { id, handleChangeId, showConfirmModal, toggleShowConfirmModal } = useContext(DeleteContext)
  const surveys = useSelector((state) => surveySelector.selectAll(state))
  const dispatch = useDispatch()

  async function handleConfirmDeleteSurvey() {
    const isASurvey = surveys.some((s) => s._id === id)

    if (isASurvey) {
      try {
        await backend.surveys.deleteSurvey(id)
      } catch (error) {
        console.error(error)
      }
    } else {
      await dispatch(removeOne(id))
    }
    toggleShowConfirmModal(false)
  }

  return {
    id,
    handleChangeId,
    showConfirmModal,
    toggleShowConfirmModal,
    onConfirmDeleteSurvey: handleConfirmDeleteSurvey,
  }
}
