import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'

export function useSettings(context, contextRef) {
  const { data: setting, isLoading } = useQuery(
    ['settings', context, contextRef],
    () =>
      backend.settings.search({ context, contextRef }).then((res) => res.data),
    { enabled: !!context && !!contextRef },
  )

  return {
    setting,
    isLoading,
  }
}
