import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useQuery } from '@tanstack/react-query'
import backend from '../../../../services/backend'
import { useTranslation } from 'react-i18next'
import { notification } from '@olystic/design_system_3'

function ImportStatus({ status, setStatus, goNextStep, goPrevStep }) {
  const { t } = useTranslation()
  useQuery(
    ['import-status', status?.id],
    () => backend.rh.importStatus(status),
    {
      initialData: status,
      enabled: !!status,
      retry: false,
      refetchInterval: (data) => {
        setStatus(data)
        if (!data?.status) return false
        if (data?.status === 'completed') {
          setStatus()
          goNextStep(data.result)
          return false
        }
        if (data?.status === 'failed') {
          setStatus()
          notification.error(t('notification.error'), 'lg')
          goPrevStep()
          return false
        }
        return 1000
      },
      onError: (err) => {
        setStatus()
        goPrevStep()
      },
    },
  )

  return (
    <div>
      <p>{t('component.importStatus.text')}</p>
      <ProgressBar
        animated={status?.progress === 0}
        now={status?.progress || 100}
      />
    </div>
  )
}

export default ImportStatus
