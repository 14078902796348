import React, { useState } from "react"
import useSidePanel from "../../../hooks/useSidePanel"
// Redux
import { useSelector } from "react-redux"
// Components
import { Button } from "react-bootstrap"
import Footer from "./Footer"
import { ButtonWithIcon, Scrollbar, SidePanel } from "@olystic/design_system_3"
import Menu from "./Menu/Menu"
import OnBoarding from "../../../components/modals/OnBoarding/OnBoarding"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHistory as farHistory } from "@fortawesome/pro-regular-svg-icons"
import { faRocket as falRocket } from "@fortawesome/pro-light-svg-icons"


export default function Panel() {
  const { open, toggleSidePanel } = useSidePanel()
  const firstConnection = useSelector((state) => state.steps.firstConnection)
  const headerTitle = useSelector((state) => state.navigation.title)
  const [showOnBoardingModal, setShowOnBoardingModal] = useState(firstConnection)

  return (
    <>
      <SidePanel openSidePanel={open} onToggleMenu={toggleSidePanel}>
        <Scrollbar style={{ maxHeight: window.innerHeight }}>
          <div className="d-flex flex-column justify-content-between vh-100">
            <div className="px-5">
              <h4 className="text-white text-center text-wrap m-0 py-6">{headerTitle}</h4>
              <div className="w-100 border-bottom opacity-25" />
              <div className="d-flex flex-column pt-4 pb-2">
                <div className="d-flex justify-content-between align-items-center pt-2 pb-3">
                  <ButtonWithIcon
                    variant="blue"
                    icon={farHistory}
                    className="px-2"
                    onClick={() => (window.location.href = "/dashboard/historique")}
                  >
                    component.groundControlPanel.cta
                  </ButtonWithIcon>

                  <Button
                    className="small"
                    onClick={() => setShowOnBoardingModal(true)}
                    style={{ width: "2.2rem", height: "2.2rem" }}
                  >
                    <FontAwesomeIcon icon={falRocket} />
                  </Button>
                </div>
              </div>
              <Menu />
            </div>
            <Footer />
          </div>
        </Scrollbar>
      </SidePanel>

      <OnBoarding
        show={showOnBoardingModal}
        onHide={() => setShowOnBoardingModal(false)}
        isFirstConnection={firstConnection}
      />
    </>
  )
}
