import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { ERROR_MESSAGES } from '@olystic/common/error.mjs'
// Icons
import { ReactComponent as Olystic } from './olystic-logo.svg'

Error.propTypes = {
  status: PropTypes.number.isRequired,
}

export default function Error({ status }) {
  return (
    <div className='w-100 vh-100 d-flex align-items-center justify-content-center mt-n45'>
      <div className='m-5 text-center'>
        <Olystic className='mb-5' />
        <h3 className='mb-3'>{`Erreur ${status}`}</h3>
        <h4 className='color-grey'>{ERROR_MESSAGES[status]}</h4>
        <Button
          className='m-auto mt-4'
          variant='secondary'
          onClick={() => (window.location.href = '/accueil')}
        >
          Retour à l'accueil
        </Button>
      </div>
    </div>
  )
}
