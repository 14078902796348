import React from "react"
import PropTypes from "prop-types"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faArrowToBottom } from "@fortawesome/pro-light-svg-icons"
import { faCopy } from "@fortawesome/pro-solid-svg-icons"
import { faUndoAlt } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

HeaderCommMsg.propTypes = {
  editMode: PropTypes.bool,
  onChangeEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDownload: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

HeaderCommMsg.defaultProps = {
  editMode: false,
  onChangeEdit: null,
  onCopy: null,
}

export default function HeaderCommMsg({ editMode, onChangeEdit, onDownload, onCopy, isEditable, auto }) {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center justify-content-between">
      {auto ? (
        <p className="fs-lg text-blue-400 m-0 user-select-none">{t("component.communicationMessageCard.text1")}</p>
      ) : (
        <p className="fs-lg text-blue-400 m-0 user-select-none">{t("component.communicationMessageCard.text2")}</p>
      )}
      <div className="d-flex align-items-center gap-4">
        {isEditable ? (
          editMode ? (
            <FontAwesomeIcon icon={faUndoAlt} onClick={onChangeEdit} className="cursor-pointer text-primary-hover" />
          ) : (
            <FontAwesomeIcon icon={faEdit} onClick={onChangeEdit} className="cursor-pointer text-primary-hover" />
          )
        ) : (
          <></>
        )}

        <FontAwesomeIcon icon={faCopy} className="cursor-pointer text-primary-hover" onClick={onCopy} />
        <FontAwesomeIcon icon={faArrowToBottom} onClick={onDownload}  className="cursor-pointer text-primary-hover" />
      </div>
    </div>
  )
}
