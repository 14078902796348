import React, { useMemo } from "react"
import moment from "moment"
import ImportRHBase from "./ImportRHBase/ImportRHBase"
import NotificationRHBase from "./NotificationRHBase/NotificationRHBase"
import backend from "../../../services/backend"
import { useQuery } from "@tanstack/react-query"

export default function RHBaseCard() {
  const allBases = useQuery(["rhbases"], backend.rh.getAllBase)

  const primaryBase = useMemo(() => {
    if (allBases.data) {
      return allBases?.data?.[0]
    }
  }, [allBases.data])

  const formatedData = useMemo(() => {
    const now = moment()
    const notUpload = moment(primaryBase?.updatedAt).isSame(moment(primaryBase?.createdAt), "second")
    let upToDate, importDate
    const latestUpload = moment(primaryBase?.updatedAt)
    const nextUpload = moment(primaryBase?.updatedAt).add(2, "months")

    // Si une base a déjà été importé
    if (!notUpload) {
      upToDate = now.diff(nextUpload, "day") < 0
      importDate = upToDate ? nextUpload : latestUpload
    }

    return {
      latestUpload,
      importDate,
      upToDate,
    }
  }, [primaryBase])

  return (
    <div className="mt-5 d-flex flex-column flex-xl-row">
      <NotificationRHBase upToDate={formatedData.upToDate} importDate={formatedData.importDate} />
      <ImportRHBase upToDate={formatedData.upToDate} latestUpload={formatedData.latestUpload} base={primaryBase} />
    </div>
  )
}
