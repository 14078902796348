import PropTypes from "prop-types"

export const surveyProps = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  nbQuestions: PropTypes.number,
  dates: PropTypes.objectOf(PropTypes.string),
  recipients: PropTypes.arrayOf(PropTypes.string),
  selectedRecipient: PropTypes.string,
})
