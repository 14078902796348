import {I18nextProvider} from 'react-i18next'
import i18n from '../i18n'
import {useAuth} from './useAuth'
import React, {useEffect} from 'react'

export function Translation({children}) {
  const {user} = useAuth()

  useEffect(() => {
    if (i18n.language !== user.language)
      i18n.changeLanguage(user.language).catch(console.error)
  }, [user])

  return (
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
  )
}
