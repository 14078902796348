import React, { useState } from "react"
import GlobalFilter from "../RHTable/components/GlobalFilter"
import { RHTable } from "../RHTable"
import NoData from "../../../../components/NoData/NoData"
import HeaderLayout from "../Layout/HeaderLayout"
import { useTranslation } from "react-i18next"

export function RespondentSelectionList({ table, loading }) {
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState("")

  return (
    <>
      <HeaderLayout>
        <HeaderLayout.Group>
          <GlobalFilter
            value={searchString}
            onChange={setSearchString}
            style={{ minWidth: "40ch" }}
            className="flex-grow-1"
          />
        </HeaderLayout.Group>
      </HeaderLayout>
      <RHTable
        instance={table}
        globalFilter={searchString}
        loading={loading}
        noDataAction={
          <div className="w-50 mx-auto mt-5">
            <NoData title={t("rh.no_data.title")} text={t("rh.no_data.text")} />
          </div>
        }
        showAction={false}
      />
    </>
  )
}
