import React from "react"
import PropTypes from "prop-types"
// Components
import { Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

SuiviActivity.propTypes = {
  dates: PropTypes.shape({
    nextQuestionnaire: PropTypes.string,
    lastQuestionnaire: PropTypes.string,
  }),
}

SuiviActivity.defaultProps = {
  dates: null,
}

export default function SuiviActivity({ dates }) {
  const { t } = useTranslation()
  const { nextQuestionnaire, lastQuestionnaire } = dates

  return (
    <Card className="color-card border-primary ps-6 py-3" style={{minWidth: "25rem", height: "8rem"}}>
      <div className="d-flex align-items-center mb-3 gap-5">
        <FontAwesomeIcon icon={faCalendarAlt} className="fa-xl text-secondary" />
        <div className="fs">
          <p className="mb-2">
            {t("component.suiviActivity.label1")} : <span className="text-primary">{nextQuestionnaire}</span>
          </p>
          <p className="mb-1">
            {t("component.suiviActivity.label2")} : <span className="text-primary">{lastQuestionnaire}</span>
          </p>
        </div>
      </div>
      <Link to="/">
        <Button variant="" className="p-0 text-primary border-0">
          <FontAwesomeIcon icon={faCirclePlus} className="me-5 fa-xl" />
          {t("component.suiviActivity.cta")}
        </Button>
      </Link>
    </Card>
  )
}
