import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { ReactComponent as Olystic } from '../../assets/icons/olystic_icon.svg'

export const EMAIL_CONTEXT_ANNONCE = 'config-questionnaire-annonce'
export const EMAIL_CONTEXT_LANCEMENT = 'config-questionnaire-lancement'
export const EMAIL_CONTEXT_RELANCE = 'config-questionnaire-relance'
export const EMAIL_CONTEXT_CLOTURE = 'config-questionnaire-cloture'

export const types = [
  {
    id: EMAIL_CONTEXT_ANNONCE,
    title: 'Annonce de la démarche',
    icon: (
      <FontAwesomeIcon icon={faEnvelope} className="text-primary fa-lg me-1" />
    ),
  },
  {
    id: EMAIL_CONTEXT_LANCEMENT,
    title: 'Lancement du questionnaire',
    icon: <Olystic className="mb-1" />,
  },
  {
    id: EMAIL_CONTEXT_RELANCE,
    title: 'Relances',
    icon: <Olystic className="mb-1" />,
  },
  {
    id: EMAIL_CONTEXT_CLOTURE,
    title: 'Clôture du questionnaire',
    icon: <Olystic className="mb-1" />,
  },
]
