import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
// Components
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowToBottom,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

export default function InteractionIcons({
  summary,
  description,
  download,
  className,
  placement,
}) {
  const { t } = useTranslation()

  const MAPPING_ICONS = [
    { name: t("common.download"), icon: faArrowToBottom, handle: download },
  ]

  return (
    <div
      className={classnames(
        "d-flex justify-content-end text-secondary gap-4 align-items-center",
        className
      )}
    >
      {MAPPING_ICONS.map((icon, i) => (
        <div key={i}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="InteractionButtons-tooltip" className="position-fixed">{icon.name}</Tooltip>
            }
          >
            <FontAwesomeIcon icon={icon.icon} onClick={icon.handle} className="text-primary-hover cursor-pointer"/>
          </OverlayTrigger>
        </div>
      ))}

      <OverlayTrigger
        placement={placement}
        overlay={
          <Popover id={"InteractionButtonsPopover"} className="position-fixed">
            <Popover.Header>
              {summary ? summary : t("information")}
            </Popover.Header>
            <Popover.Body>
              {description ? description : t("description")}
            </Popover.Body>
          </Popover>
        }
      >
        <FontAwesomeIcon icon={faInfoCircle} className="text-primary-hover"/>
      </OverlayTrigger>
    </div>
  )
}

InteractionIcons.propTypes = {
  summary: PropTypes.string,
  description: PropTypes.string,
  download: PropTypes.func,
  className: PropTypes.string,
  placement: PropTypes.string,
}

InteractionIcons.defaultProps = {
  summary: "Information",
  description: "Description sur le composant",
  placement: "top",
  className: "",
  download: null,
}
