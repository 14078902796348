import React, { useCallback, useEffect, useState } from "react"
import { useAuth } from "@olystic/olysticapp"
import { useQuery } from "@tanstack/react-query"
import backend from "../services/backend"
import moment from "moment"

// Determine et gere la selection d'une cible d'enquete (la base rh a utiliser)
// Seulement pour les enquetes planifier.
// Les enquetes en cours ou terminer on leur propre base qui ne doit pas etre changer.
export function useSurveyTarget(survey) {
  const { perimeter } = useAuth()
  const [selectedTarget, setSelectedTarget] = React.useState()
  const [targets, setTargets] = useState([])
  const [disabled, setDisabled] = useState(false)
  const allBases = useQuery(["rhbases"], backend.rh.getAllBase)

  useEffect(
    () => {
      if (!allBases.isSuccess) return
      // la cible d'une enquete en cours ne peut etre modifier
      if (moment().isAfter(survey.dates.Lancement)) {
        setDisabled(true)
        setTargets([survey.respondentGroup])
        setSelectedTarget(survey.respondentGroup)
        return
      }

      // toutes les bases et les groupes excluant les bases d'enquetes en cours ou terminer
      const baseChoices = allBases.data.filter((base) => !base.survey)

      // determiner la selection initiale
      if (!selectedTarget) {
        let selected // la base (respondentGroup) de l'enquete s'il exsite sinon le default du perimetre
        if (survey && survey.respondentGroup) {
          let groupId = survey.respondentGroup
          if (typeof survey.respondentGroup === "object") groupId = survey.respondentGroup._id
          selected = baseChoices.find((base) => base._id === groupId)
        }
        if (!selected) selected = baseChoices.find((base) => base._id === perimeter.defaultRespondentGroup)
        // la selection peut etre undefined. C'est la responsabilite de l'UI de gerer ce cas.
        setSelectedTarget(selected)
      }

      // regroupe les groupe par bases
      let grouped = baseChoices
        .sort((a, b) => Number(!!a.parentGroup > !!b.parentGroup))
        .reduce((acc, base) => {
          if (!base.parentGroup) acc[base._id] = { ...base, children: [] }
          else {
            acc[base.parentGroup]?.children.push({ ...base })
          }
          return acc
        }, {})

      //remet a plat
      grouped = Object.values(grouped).flatMap((base) => [base, ...base.children])

      setTargets(grouped)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [perimeter, allBases.isSuccess, survey]
  )

  const handleSelectTarget = useCallback((target) => {
    setSelectedTarget(target)
  }, [])

  return {
    targets, // liste des cibles
    selectedTarget, // la cible selectionner
    selectTarget: handleSelectTarget,
    isSelected: (target) => target._id === selectedTarget?._id,
    disabled,
  }
}
