import React from 'react'
import PropTypes from 'prop-types'
// Components
import { qualityColor } from '../../lib/functions'
import { useParticipation } from '../../hooks/useParticipation'
// Translation
import { useTranslation } from 'react-i18next'

export default function RespondentsCard({ surveyId }) {
  const { t } = useTranslation()
  const { rate, rh } = useParticipation(surveyId)

  return (
    <div
      className={` border-${qualityColor(
        rate
      )} border rounded-2 border-2 bg-white text-center center py-3 px-7 gap-3 flex-column`}
      style={{ width: '18rem' }}
    >
      <div className="border-bottom w-100">
        <p>{t('component.respondentsCard.label1')}</p>
        <p>{rh?.respondent || 0}</p>
      </div>
      <div className="">
        <p>{t('component.respondentsCard.label2')}</p>
        <p className="m-0">{rh?.response || 0}</p>
      </div>
    </div>
  )
}

RespondentsCard.propTypes = {
  surveyId: PropTypes.string.isRequired,
}
