import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function CreateBaseModal({ onSuccess, type = 'base', ...props }) {
  const [baseName, setBaseName] = useState('')
  const { t } = useTranslation()
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [])

  function handleSubmit(e) {
    e.preventDefault()
    onSuccess({ name: baseName })
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t(`component.createBaseModal.title.${type}`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              {t(`component.createBaseModal.label.${type}`)}
            </Form.Label>
            <Form.Control
              ref={inputRef}
              value={baseName}
              onChange={(e) => setBaseName(e.currentTarget.value)}
            />
          </Form.Group>
        </Form>
        <div className='d-flex justify-content-end mt-4'>
          <Button disabled={!baseName.length} onClick={handleSubmit}>
            {t('component.createBaseModal.cta')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CreateBaseModal
