import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

const questionnaireAdapter = createEntityAdapter({
  selectId: (item) => item._id,
})

const questionnaireSlice = createSlice({
  name: "questionnaires",
  initialState: questionnaireAdapter.getInitialState({ loading: false }),
  reducers: {
    addOne: questionnaireAdapter.addOne,
    addMany: questionnaireAdapter.addMany,
    setAll: questionnaireAdapter.setAll,
    removeOne: questionnaireAdapter.removeOne,
    removeMany: questionnaireAdapter.removeMany,
    updateOne: questionnaireAdapter.updateOne,
    updateMany: questionnaireAdapter.updateMany,
    upsertOne: questionnaireAdapter.upsertOne,
    upsertMany: questionnaireAdapter.upsertMany,
  },
})

export const { addOne, addMany, setAll, removeOne, removeMany, updateOne, updateMany, upsertOne, upsertMany } =
  questionnaireSlice.actions

export const questionnaireSelectors = questionnaireAdapter.getSelectors((state) => state[questionnaireSlice.name])

export default questionnaireSlice.reducer
