import React from "react"
import PropTypes from "prop-types"
import style from "./NavButton.module.scss"
import { NavLink } from "react-router-dom"
import useSidePanel from "../../../../../hooks/useSidePanel"
import { SMALL_SCREEN } from "../../../../../context/SidePanelContext"
// Translation
import { useTranslation } from "react-i18next"

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  beforeIcon: PropTypes.node,
  title: PropTypes.string.isRequired,
  afterIcon: PropTypes.node,
}

NavButton.defaultProps = {
  beforeIcon: null,
  afterIcon: null,
}

export default function NavButton({ to, beforeIcon, title, afterIcon }) {
  const { t } = useTranslation()
  const { toggleSidePanel } = useSidePanel()

  const handleClick = () => {
    if (window.innerWidth < SMALL_SCREEN) {
      toggleSidePanel()
    }
  }

  return (
    <NavLink
      className={({ isActive }) => (isActive ? style.navLink_active : style.navLink)}
      to={to}
      onClick={() => handleClick()}
    >
      {beforeIcon && <div className={style.navIcon}>{beforeIcon}</div>}
      {!beforeIcon && <span className={style.circle} />} <p className="m-0 ms-3">{t(title)}</p>
      {afterIcon && <div className={style.afterNavIcon}>{afterIcon}</div>}
    </NavLink>
  )
}
