export const ACCESS_QUESTIONNAIRE = 'accessQuestionnaire'
export const EDIT_QUESTIONNAIRE = 'editQuestionnaire'
export const ACCESS_PERIMETER = 'accessPerimeter'
export const ROOT_ACCESS = 'rootAccess'
export const ACCESS_RESULT = 'accessResult'
export const ACCESS_FROM_SCRATCH = 'accessFromScratch'

export const ROOT = 'root'
export const ADMIN = 'admin'
export const BASIC = 'basic'

export const BASIC_PERMISSION = [ROOT, ADMIN, BASIC]
export const ADMIN_PERMISSION = [ROOT, ADMIN]
