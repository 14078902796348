import React from "react"
import useScheduler from "../../../hooks/useScheduler"
import { useTranslation } from "react-i18next"

export default function SchedulerOpenButton() {
  const { t } = useTranslation()
  const { openScheduler, toggleScheduler } = useScheduler()

  return (
    <div
      className="position-absolute cursor-pointer start-50 translate-middle-x"
      onClick={() => toggleScheduler(!openScheduler)}
      style={{ top: "-1rem" }}
    >
      <span
        className="position-absolute start-0 end-0 text-center text-white"
        style={{ top: "4px" }}
      >
        {t("component.schedulerButton.text")}
      </span>
      <svg
        width="607"
        height="33"
        viewBox="0 0 607 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M603.432 32.0182C605.402 32.0182 607 30.4206 607 28.4499V28.4499C607 26.4791 605.402 24.8815 603.432 24.8815L555.784 24.8815C533.635 24.8815 512.476 0.917507 490.328 0.992357V0.992357L106.974 2.28792V2.28792C85.6724 2.35991 65.4107 24.8815 44.1089 24.8815L3.56837 24.8815C1.59761 24.8815 -1.11406e-06 26.4791 -1.20021e-06 28.4498V28.4498C-1.28635e-06 30.4206 1.59761 32.0182 3.56836 32.0182L603.432 32.0182Z"
          fill="#202F4D"
        />
      </svg>

      {/* <svg fill="none" height="25" viewBox="0 0 149 25" width="149" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M145 25C147.209 25 149 23.2091 149 21V21C149 18.7909 147.209 17 145 17L119.191 17C103.884 17 89.2284 -2.6127e-06 73.922 -3.28176e-06V-3.28176e-06V-3.28176e-06C58.666 -3.94863e-06 43.9993 17 28.7433 17L4 17C1.79086 17 -1.53663e-06 18.7909 -1.6332e-06 21V21C-1.72976e-06 23.2091 1.79086 25 4 25L145 25Z"
          fill="#202F4D"
          fillRule="evenodd"
        />
         <path
          d="M73.4697 16.5303C73.7626 16.8232 74.2374 16.8232 74.5303 16.5303L79.3033 11.7574C79.5962 11.4645 79.5962 10.9896 79.3033 10.6967C79.0104 10.4038 78.5355 10.4038 78.2426 10.6967L74 14.9393L69.7574 10.6967C69.4645 10.4038 68.9896 10.4038 68.6967 10.6967C68.4038 10.9896 68.4038 11.4645 68.6967 11.7574L73.4697 16.5303ZM73.25 15L73.25 16L74.75 16L74.75 15L73.25 15Z"
          fill="white"
        /> 
      </svg> */}
    </div>
  )
}
