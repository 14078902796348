import React from 'react'
import NavButton from './NavButton/NavButton'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus as farPlus,
  faChartLine as farChartLine,
  faCalendarAlt as farCalendarAlt,
  faExternalLinkAlt as farExternalLinkAlt,
  faSliders as farSliders,
  faBullhorn,
} from '@fortawesome/pro-regular-svg-icons'
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons'
import { faUsers } from '@fortawesome/pro-duotone-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

export default function Menu() {
  const { t } = useTranslation()
  return (
    <div className='fs'>
      <NavButton
        to='/'
        beforeIcon={<FontAwesomeIcon icon={farPlus} />}
        title={t(`navigation.`)}
      />
      <NavButton
        to='planifier-feedbacks'
        beforeIcon={<FontAwesomeIcon icon={faCommentDots} />}
        title={t(`navigation.planifier-feedbacks`)}
      />
      <div className='w-100 border-bottom opacity-25' />
      <NavButton
        to='calendrier-questionnaires'
        beforeIcon={<FontAwesomeIcon icon={farCalendarAlt} />}
        title={t(`navigation.calendrier-questionnaires`)}
      />
      <div className='w-100 border-bottom opacity-25' />
      <NavButton
        to='suivi-questionnaires'
        beforeIcon={<FontAwesomeIcon icon={farChartLine} />}
        title={t(`navigation.suivi-questionnaires`)}
      />

      <div className='w-100 border-bottom opacity-25' />
      <NavButton
        to='parametrage-technique'
        beforeIcon={<FontAwesomeIcon icon={farSliders} />}
        title={t(`navigation.parametrage-technique`)}
      />

      <div className='w-100 border-bottom opacity-25' />
      <NavButton
        to='communications'
        beforeIcon={<FontAwesomeIcon icon={faBullhorn} />}
        title={t(`navigation.communications`)}
      />
      <NavButton
        to='rh'
        title={t(`navigation.rh`)}
        beforeIcon={<FontAwesomeIcon icon={faUsers} />}
        afterIcon={<FontAwesomeIcon icon={farExternalLinkAlt} />}
      />
      <div className='w-100 border-bottom opacity-25' />
    </div>
  )
}
