import React, { Fragment } from 'react'
import style from './GoodPraticeExampleCard.module.scss'
// Components
import { Badge, Card } from 'react-bootstrap'
import { ANNONCE, COMMUNICATION, goodPratices } from './goodPratices'
import { mappingSurvey } from '@olystic/common/survey.mjs'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faTimes, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
// Icons
import { ReactComponent as Olystic } from '../../../../assets/icons/olystic_icon.svg'
// Translation
import { useTranslation } from 'react-i18next'

export default function GoodPraticeExampleCard() {
  const { t } = useTranslation()

  const [...legend] = new Set(
    goodPratices.map((elt) => Object.keys(elt.steps)).flat()
  )
  return (
    <Card>
      <Card.Body className="py-5 px-6">
        <h3 className="text-center mt-5">
          {t('component.goodPraticeCard.title')}
        </h3>
        <div className="mt-5 mb-4 overflow-auto">
          <div className={style.thead}>
            <div></div>
            {legend.map((elt, i) => (
              <div key={elt} className="d-flex align-items-center flex-column">
                <p
                  className="mb-2 d-flex align-items-center"
                  style={{ flex: 'auto' }}
                >
                  {elt.includes('Relance')
                    ? t('events.relance', { number: i - 1 })
                    : t(`events.${elt}`)}
                </p>
                {elt === ANNONCE ? (
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-primary fa-lg justify-"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                ) : elt === COMMUNICATION ? (
                  <div className="d-flex align-items-center gap-2">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-primary fa-lg justify-"
                      style={{ width: '1rem', height: '1rem' }}
                    />{' '}
                    <span className="fs-sm">{t('common.or')}</span>
                    <Olystic style={{ width: '1rem', height: '1rem' }} />
                  </div>
                ) : (
                  <Olystic style={{ width: '1rem', height: '1rem' }} />
                )}
              </div>
            ))}
          </div>

          <div>
            {goodPratices.map((item) => (
              <div key={item.type} className={style.row}>
                <div className="px-4">
                  <Badge bg={mappingSurvey(item.type).color}>
                    {t(`survey.types.${mappingSurvey(item.type).title}`)}
                  </Badge>
                </div>
                {legend.map((elt, i) => (
                  <Fragment key={i}>
                    {item.type === 'Court' && elt === ANNONCE ? (
                      <div className="d-flex gap-1 justify-content-center align-items-center">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className={style.check}
                        />
                        <span className="fs-sm">/</span>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={style.error}
                        />
                      </div>
                    ) : (
                      <div className="m-auto">
                        {item.steps[elt] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className={style.check}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={style.error}
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
