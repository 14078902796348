import {
  TYPE_COURT,
  TYPE_FEEDBACK,
  TYPE_INDIVIDUELLE,
  TYPE_LONG,
  TYPE_USER,
} from '@olystic/common/survey.mjs'

export const DURATION_BY_TYPE = {
  [TYPE_LONG]: { duration: 3, unit: 'weeks' },
  [TYPE_COURT]: { duration: 1, unit: 'weeks' },
  [TYPE_FEEDBACK]: { duration: 3, unit: 'days' },
  [TYPE_INDIVIDUELLE]: { duration: 1, unit: 'months' },
  [TYPE_USER]: { duration: 1, unit: 'weeks' },
}
