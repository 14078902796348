import {
  add,
  addBusinessDays,
  addDays,
  formatISO,
  isSameDay,
  parseISO,
  setHours,
  setMinutes,
} from 'date-fns'

export const updateDatesWithType = (type, lancement, cloture, diffDays) => {
  const launch = lancement.hour(10).format()
  const closure =
    cloture?.hour(23).format() || add(lancement.hour(23).format(), diffDays)
  let newDate
  if (type === 'Court' || type === 'Utilisateur')
    newDate = {
      Lancement: launch,
      Relance1: formatISO(addBusinessDays(parseISO(launch), 2)),
      Relance2: formatISO(addBusinessDays(parseISO(launch), 4)),
      Clôture: closure,
    }
  else if (type === 'Long')
    newDate = {
      Lancement: launch,
      Relance1: formatISO(addBusinessDays(parseISO(launch), 5)),
      Relance2: formatISO(addBusinessDays(parseISO(launch), 10)),
      Relance3: formatISO(addBusinessDays(parseISO(launch), 14)),
      Clôture: closure,
    }
  else if (type === 'Individuelle') {
    const now = new Date()
    const isToday = isSameDay(parseISO(launch), new Date())
    newDate = {
      Lancement: isToday
        ? formatISO(setHours(setMinutes(now, now.getMinutes()), now.getHours()))
        : launch,
      Relance1: formatISO(addDays(parseISO(launch), 2)),
      Clôture: closure,
    }
  } else
    newDate = {
      Lancement: launch,
      Relance1: formatISO(addDays(parseISO(launch), 2)),
      Clôture: closure,
    }

  return newDate
}
