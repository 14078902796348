import React from "react"
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus"
import { ButtonWithIcon } from "@olystic/design_system_3"
// Translation
import { useTranslation } from "react-i18next"

function CreateRespondentButton({ onEdit, ...props }) {
  const { t } = useTranslation()
  return (
    <ButtonWithIcon className="text-nowrap" icon={faPlus} onClick={() => onEdit(null)} {...props} id="AddRHButton" >
      {t("rh.add_respondent")}
    </ButtonWithIcon>
  )
}

export default CreateRespondentButton
