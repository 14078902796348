import { React } from 'react'
import Form from 'react-bootstrap/Form'
// Translation
import { useTranslation } from 'react-i18next'

export default function GlobalFilter({ value, onChange, ...props }) {
  const { t } = useTranslation()
  return (
    <Form.Group>
      <Form.Label className='fs-lg'>{t('rh.global_filter_text')}</Form.Label>
      <Form.Control
        name='search'
        value={value || ''}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        placeholder={t('rh.global_filter_placeholder')}
        style={{ width: '70ch' }}
        className='flex-grow-1'
        {...props}
      />
    </Form.Group>
  )
}
