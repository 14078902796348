import React from "react"
import { ButtonWithIcon } from "@olystic/design_system_3"
import { faBallotCheck } from "@fortawesome/pro-regular-svg-icons/faBallotCheck"
import { useTranslation } from "react-i18next"

function AddRespondentButton({ onClick, ...props }) {
  const { t } = useTranslation()
  return (
    <ButtonWithIcon icon={faBallotCheck} onClick={onClick} {...props}>
      {t("component.addRespondentButton.cta")}
    </ButtonWithIcon>
  )
}

export default AddRespondentButton
