import React from "react"
import PropTypes from "prop-types"
// Components
import { Modal } from "react-bootstrap"
// Icons
import { ReactComponent as IconError } from "../../../assets/icons/icon-error.svg"
// Translation
import { useTranslation } from "react-i18next"

export default function ErrorProgrammingModal({ show, onHide }) {
  const { t } = useTranslation()
  return (
    <Modal size="xs" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("component.errorProgrammingModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <IconError style={{ width: "5.5rem", height: "auto" }} />
        </div>

        <p className="text-center mt-4 my-1">{t("component.errorProgrammingModal.text1")}</p>
        <p className="text-center">{t("component.errorProgrammingModal.text2")}</p>
      </Modal.Body>
    </Modal>
  )
}

ErrorProgrammingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}
