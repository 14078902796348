import React from "react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { ButtonWithIcon } from "@olystic/design_system_3";

function DeleteAllButton(props) {
  return (
    <ButtonWithIcon
      variant="secondary"
      icon={faTrash}
      {...props}
    />
  );
}

export default DeleteAllButton;
