import React, { useState } from 'react'
// Components
import { Card, Col, Row } from 'react-bootstrap'
import { useAuth } from '@olystic/olysticapp'
import { Link } from 'react-router-dom'
import { ButtonWithIcon, SubscriptionModal } from '@olystic/design_system_3'
// Icons
import {
  faCircleExclamation,
  faComments,
} from '@fortawesome/pro-solid-svg-icons'
import { faListUl } from '@fortawesome/pro-solid-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { EDIT_QUESTIONNAIRE } from '@olystic/common/user.mjs'
import { ACCESS_FROM_SCRATCH } from '@olystic/common/perimeter.mjs'
import { useSubscription } from '../../hooks/useSubscription'

const MAPPING_MINI_QUESTIONNAIRE = [
  'component.feedbackLauncher.option1.text1',
  'component.feedbackLauncher.option1.text2',
  'component.feedbackLauncher.option1.text3',
  'component.feedbackLauncher.option1.text4',
]

const MAPPING_FEEDBACK = [
  'component.feedbackLauncher.option2.text1',
  'component.feedbackLauncher.option2.text2',
  'component.feedbackLauncher.option2.text3',
  'component.feedbackLauncher.option2.text4',
]

export default function FeedbackLauncher() {
  const { t } = useTranslation()
  const { user, perimeter } = useAuth()
  const { canCreateQuickFeedback } = useSubscription()

  const canEditQuestionnaire =
    user.permissions.includes(EDIT_QUESTIONNAIRE) &&
    perimeter.permissions.includes(ACCESS_FROM_SCRATCH)

  const [show, setShow] = useState(false)

  function handleCreateMiniQuestionnaire() {
    localStorage.setItem('create', true)
    window.location = '/editeur'
  }

  function handleClickButton() {
    return canEditQuestionnaire
      ? handleCreateMiniQuestionnaire()
      : setShow(true)
  }
  return (
    <>
      <Card className='mx-auto p-6' style={{ maxWidth: '75rem' }}>
        <header className='text-center mb-4'>
          <h3 className='mb-3'>{t('component.feedbackLauncher.title1')}</h3>
          <h3 className='mb-3'>{t('component.feedbackLauncher.title2')}</h3>
          <h4>{t('component.feedbackLauncher.subtitle')}</h4>
        </header>

        <Row className='gap-4 justify-content-center'>
          <Col lg={5} className='text-center fs-lg '>
            <p className='text-primary'>
              {t('component.feedbackLauncher.option2.title')}
            </p>

            <ul className='text-start my-4'>
              {MAPPING_FEEDBACK.map((item, i) => (
                <li key={i} className='mb-2 marker-primary'>
                  {t(item)}
                </li>
              ))}
            </ul>
            <Link to='/creer-feedback'>
              {canCreateQuickFeedback ? (
                <ButtonWithIcon icon={faComments} className='m-auto mt-4'>
                  {t('component.feedbackLauncher.option2.cta')}
                </ButtonWithIcon>
              ) : (
                <ButtonWithIcon
                  icon={faCircleExclamation}
                  className='m-auto mt-4'
                  disabled
                >
                  {t('common.levelup')}
                </ButtonWithIcon>
              )}
            </Link>
          </Col>

          <Col lg={5} className='text-center fs-lg'>
            <p className='text-primary'>
              {t('component.feedbackLauncher.option1.title')}
            </p>

            <ul className='text-start my-4'>
              {MAPPING_MINI_QUESTIONNAIRE.map((item, i) => (
                <li key={i} className='mb-2 marker-primary'>
                  {t(item)}
                </li>
              ))}
            </ul>

            <ButtonWithIcon
              icon={faListUl}
              className='m-auto mt-4'
              onClick={handleClickButton}
            >
              {t('component.feedbackLauncher.option1.cta')}
            </ButtonWithIcon>
          </Col>
        </Row>
      </Card>
      <SubscriptionModal show={show} onHide={() => setShow(false)} />
    </>
  )
}
