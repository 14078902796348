import React from 'react'

function HeaderLayout({ children, className }) {
  return (
    <div
      className={`mb-3 d-flex flex-wrap justify-content-between gap-3 ${className}`}
    >
      {children}
    </div>
  )
}

export function HeaderLayoutGroup({ children, className }) {
  return (
    <div className={`d-flex gap-3 align-items-end flex-sm-wrap ${className}`}>
      {children}
    </div>
  )
}

HeaderLayout.Group = HeaderLayoutGroup

export default HeaderLayout
