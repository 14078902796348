import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSuivi } from './useSuivi'
import { Badge } from 'react-bootstrap'

const States = [
  { id: 'todo', title: 'common.todo', color: 'info' },
  { id: 'relance', title: 'common.progress', color: 'primary' },
  { id: 'done', title: 'common.end', color: 'success' },
]

function getState(no) {
  return States[no] || States[0]
}

function SuiviToggle({ respondentId }) {
  const { t } = useTranslation()
  const { query: q } = useSuivi(respondentId)
  const current = useMemo(() => {
    if (q.isSuccess && q.data) return getState(q.data.suivi)
    return getState(0)
  }, [q])

  // function handleChange() {
  //   let nextState = q.data.suivi + 1
  //   if (nextState >= States.length) nextState = 0
  //   mutation.mutate(nextState)
  // }

  if (q.isSuccess) {
    return (
      <div
        className='user-select-none'
        // onClick={handleChange}
      >
        <Badge bg={t(current.color)}>{t(current.title)}</Badge>
      </div>
    )
  }
  return null
}

export default SuiviToggle
