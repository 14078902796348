import { http } from "@olystic/olysticapp"

const feedbackUser = {
  async createFeedbackUser(payload) {
    const res = await http.post(`/scheduler/api/v2/feedbackUsers`, payload)
    return res.data
  },
}

export default feedbackUser
