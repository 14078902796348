/* eslint-disable react/display-name */
import React from "react"
import PropTypes from "prop-types"
import style from "./GanttListTable.module.scss"
import classnames from "classnames"
import { useTable, useSortBy } from "react-table"
import { surveyProps } from "@olystic/common/proptypes/survey"
// Icons
import {
  faCaretDown,
  faCaretUp,
  faSort,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function GanttListTable({ data, columns }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  return (
    <div className={style.container}>
      <table {...getTableProps()} className="w-100">
        <thead className="border-bottom" style={{ height: "4rem" }}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps()}>
                  <div className="d-flex align-items-center">
                    <span
                      {...column.getSortByToggleProps()}
                      className={classnames("user-select-none m-auto fs-lg", {
                        "text-primary": column.isSorted,
                      })}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="text-primary ms-1 fa-xs"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className="text-primary ms-1 fa-xs"
                          />
                        )
                      ) : (
                        index !== 0 && (
                          <FontAwesomeIcon
                            icon={faSort}
                            className="ms-1 fa-xs"
                          />
                        )
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

GanttListTable.propTypes = {
  data: PropTypes.arrayOf(surveyProps).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
}
