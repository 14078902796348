import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Modal } from "react-bootstrap"

function DeleteBaseModal({ base, onSuccess, ...props }) {
  const { t } = useTranslation()
  return (
    <Modal centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{t("component.deleteBaseModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="text-center fs-lg m-0">{t("component.deleteBaseModal.text")}</p>
          <p className="text-center text-danger fs-lg">{t("component.deleteBaseModal.warning")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSuccess(base)} variant="danger" className="mx-auto">
          {t("component.deleteBaseModal.cta")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteBaseModal
