import React, { Suspense } from "react"
import Auth from "./auth"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Translation } from "./Translation"
import { BrowserRouter } from "react-router-dom"
import Loading from "./loading"
import ErrorBoundary from "./ErrorBoundary"
import { GeneralModalProvider } from "@olystic/design_system_3"

const queryClient = new QueryClient()

export function OlysticApp({ children, basepath, config }) {
  return (
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <Auth redirectTo={config.loginRedirect} roles={config.allowRoles}>
          <Translation>
            <ErrorBoundary>
              <GeneralModalProvider>
                <BrowserRouter basename={basepath}>{children}</BrowserRouter>
              </GeneralModalProvider>
            </ErrorBoundary>
          </Translation>
        </Auth>
      </QueryClientProvider>
    </Suspense>
  )
}
