import { http } from "@olystic/olysticapp"
import { gcV2 } from "./constant"

const parseRH = {
  preparse(formData) {
   return http.post(`${gcV2}/rh/preparse`, formData)
  },

  parse(id, formData) {
    return http.post(`${gcV2}/rh/${id}/parse`, formData)
  },
}

export default parseRH
