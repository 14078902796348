import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as Picture } from "../../assets/icons/no-data-logo.svg"
import { Col, Row } from "react-bootstrap"

NoData.propTypes = {
  cta: PropTypes.node,
  title: PropTypes.string,
}

function NoData({ title, text, cta }) {
  return (
    <Row className="gap-5 g-0">
      <Col xl={4} className="center text-center">
        <Picture />
      </Col>
      <Col className="d-flex flex-column justify-content-center align-items-center align-items-xl-start">
        <h4 className="">{title}</h4>
        <p className="text-width fs">{text}</p>
        {cta}
      </Col>
    </Row>
  )
}

export default NoData
