import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

const waitingSurveyAdapter = createEntityAdapter({
  selectId: (survey) => survey._id,
})

const waitingSurveySlice = createSlice({
  name: "waitingSurveys",
  initialState: waitingSurveyAdapter.getInitialState({ loading: "void" }),
  reducers: {
    addOne: waitingSurveyAdapter.addOne,
    addMany: waitingSurveyAdapter.addMany,
    setAll: waitingSurveyAdapter.setAll,
    removeOne: waitingSurveyAdapter.removeOne,
    removeMany: waitingSurveyAdapter.removeMany,
    updateOne: waitingSurveyAdapter.updateOne,
    updateMany: waitingSurveyAdapter.updateMany,
    upsertOne: waitingSurveyAdapter.upsertOne,
    upsertMany: waitingSurveyAdapter.upsertMany,
  },
})

export const { addOne, addMany, setAll, removeOne, removeMany, updateOne, updateMany, upsertOne, upsertMany } =
  waitingSurveySlice.actions

export const waitingSurveySelector = {
  ...waitingSurveyAdapter.getSelectors((state) => state[waitingSurveySlice.name]),
}

export default waitingSurveySlice.reducer
