import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

const updateNameSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, 'rhBaseItem.form.name.error.min')
    .required('rhBaseItem.form.name.error.required'),
})

export default function BaseName({
  editMode,
  base,
  onChangeEditMode,
  onEditName,
  onEdit,
}) {
  const { t } = useTranslation()

  const handleSubmit = async (values) => {
    await onEditName({ ...base, ...values })
    onChangeEditMode()
  }

  return (
    <Formik
      initialValues={{ name: base.name }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={updateNameSchema}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit}>
          {editMode ? (
            <div className='d-flex align-items-center justify-content-between'>
              <FormGroup controlId={'name'} className='pe-3'>
                <InputGroup>
                  <Form.Control
                    name='name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={t(`category.${values.name}`, values.name)}
                    type='text'
                    placeholder={t('rhBaseItem.form.name.placeholder')}
                    autoComplete='off'
                    isInvalid={touched.name && errors.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {t(errors.name)}
                  </Form.Control.Feedback>
                </InputGroup>
              </FormGroup>

              <Button
                className='small'
                type='submit'
                disabled={!(isValid && dirty && !isSubmitting)}
                style={{ width: '1rem', height: '1rem' }}
              >
                <FontAwesomeIcon icon={faCheck} className='text-white' />
              </Button>
            </div>
          ) : (
            <p
              className='h4 text-center m-0 text-underline-hover m-0 cursor-pointer '
              onClick={() => onEdit(base)}
            >
              {base.name}
            </p>
          )}
        </Form>
      )}
    </Formik>
  )
}

BaseName.propTypes = {
  editMode: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onChangeEditMode: PropTypes.func,
  onUpdateQuestion: PropTypes.func,
}
