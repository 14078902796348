import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import style from "./Scheduler.module.scss"
// Components
import SchedulerOpenButton from "./Components/SchedulerOpenButton"
import SchedulerMonths from "./Components/SchedulerMonths"
import SchedulerSurvey from "./Components/SchedulerSurvey"
import useScheduler from "../../hooks//useScheduler"
import { Slider } from "@olystic/design_system_3"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearchMinus, faSearchPlus } from "@fortawesome/pro-light-svg-icons"


export default function Scheduler({ onDrop, onChangePosition, width, months, position, min, items, onDragOver }) {
  const { openScheduler, scale, onChangeScale, height } = useScheduler()

  const calcHeight =  height <= 160 ? 216 : height + 40

  return (
    <div
      className={classnames(style.container, { [style.containerUp]: openScheduler })}
      style={{ bottom: openScheduler ? 0 : -calcHeight / 16 + "rem" }}
    >
      <SchedulerOpenButton />
      <div onDragOver={(e) => onDragOver(e)} onDrop={(e) => onDrop(e)}>
        <SchedulerMonths
          width={width}
          months={months}
          position={position}
          onPositionChange={(pos) => onChangePosition(pos)}
          minimum={min}
        />

        {items.map((i) => (
          <SchedulerSurvey key={i.id} enquete={i} />
        ))}
      </div>

      <div className="mx-auto d-flex text-white gap-3 mt-3" style={{width: "12.5rem"}}>
        <FontAwesomeIcon icon={faSearchMinus} />
        <Slider min={0} max={100} marks={false} value={scale} onChange={(e) => onChangeScale(parseInt(e))} />
        <FontAwesomeIcon icon={faSearchPlus} />
      </div>
    </div>
  )
}

Scheduler.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      month: PropTypes.object,
      width: PropTypes.number,
    })
  ).isRequired,
  position: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      x: PropTypes.number,
      width: PropTypes.number,
    })
  ).isRequired,
  onDragOver: PropTypes.func.isRequired,
}
