import React, { useState } from "react"
// Redux
import { useSelector } from "react-redux"
import { waitingSurveySelector } from "../redux/reducers/waitingSurveys"
import { surveySelector } from "../redux/reducers/surveys"
// Components
import { Button, Tab, Tabs } from "react-bootstrap"
import moment from "moment"
import Section from "../components/layout/section"
import Gantt from "../components/Gantts/Gantt"
import GanttList from "../components/Gantts/GanttList"
import EmptyGantt from "../components/Gantts/EmptyGantt/EmptyGantt"
import SuiviActivity from "../components/cards/suiviActivity"
import SurveyCounterCard from "../components/cards/surveyCounterCard"
import { NotificationCard } from "@olystic/design_system_3"
import NavProgressSurvey from "../components/NavProgressSurvey/NavProgressSurvey"
import CurrentRateCard from "../components/cards/CurrentRateCard"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"
import FollowUpCard from "../components/cards/FollowUpCard"

const GANTT = "gantt"
const GANT_LIST = "gantt_list"

const renderGantt = (surveys, id, t) => {
  const info = {
    summary: null,
    description: null,
  }

  if (surveys.length === 0) {
    return <EmptyGantt info={info} />
  }

  if (id === GANTT) {
    return <Gantt surveys={surveys} info={info} />
  }

  if (id === GANT_LIST) {
    return <GanttList surveys={surveys} info={info} />
  }
}

export default function Calendar() {
  const { t } = useTranslation()
  const surveys = useSelector((state) => surveySelector.selectAll(state))
  const selectActiveSurveys = useSelector((state) =>
    surveySelector.selectActiveSurveys(state)
  )
  const waitingSurveys = useSelector((state) =>
    waitingSurveySelector.selectAll(state)
  )

  const [numberOfMonths, setNumberOfMonths] = React.useState(null)
  const [activeId, setActiveId] = React.useState(GANTT)
  const [selectedSurveyId, setSelectedSurveyId] = React.useState("")
  const [enquete, setEnquete] = useState([])

  React.useEffect(() => {
    const now = moment()

    setEnquete([...surveys, ...waitingSurveys])
    const recentSurvey = surveys.sort(
      (a, b) =>
        moment(b.dates.Clôture).valueOf() - moment(a.dates.Clôture).valueOf()
    )[0]

    recentSurvey &&
      setNumberOfMonths(now.diff(moment(recentSurvey.dates.Clôture), "months"))

    selectActiveSurveys && setSelectedSurveyId(selectActiveSurveys[0]?._id)
  }, [surveys, selectActiveSurveys, waitingSurveys])

  return (
    <>
      <Section className="mt-8">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="m-0">{t("page.calendar.title1")}</h3>
          <Button
            variant="link"
            onClick={() => (window.location.href = "dashboard/historique")}
            className="fs text-blue-600 p-0"
          >
            {t("page.calendar.cta")}
            <FontAwesomeIcon icon={faExternalLink} className="ms-2 fa-sm" />
          </Button>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-5">
          <div className="me-8">
            {numberOfMonths !== null && numberOfMonths < 1 ? (
              <SuiviActivity />
            ) : (
              <NotificationCard
                numberOfMonths={numberOfMonths}
                onClick={() => (window.location.href = "/")}
              />
            )}
          </div>

          <div className="flex-fill">
            <SurveyCounterCard />
          </div>
        </div>
      </Section>

      {selectedSurveyId && selectActiveSurveys.length > 0 && (
        <Section>
          <h3 className="mt-5 mb-3">{t("page.calendar.title2")}</h3>

          {selectActiveSurveys.length > 1 && (
            <NavProgressSurvey
              surveys={selectActiveSurveys}
              selectedSurveyId={selectedSurveyId}
              onChangeSurvey={setSelectedSurveyId}
            />
          )}

          <div className="d-flex flex-wrap align-items-center justify-content-between gap-5 mt-5">
            <div className="me-8">
              <CurrentRateCard surveyId={selectedSurveyId} />
            </div>
            <div className="flex-fill">
              <FollowUpCard surveyId={selectedSurveyId} />
            </div>
          </div>
        </Section>
      )}

      <Section className="mb-5">
        <h3 className="mt-5 mb-4">{t("page.calendar.title3")}</h3>

        {enquete.length !== 0 && (
          <Tabs activeKey={activeId} onSelect={setActiveId}>
            <Tab eventKey={GANTT} title={t("page.calendar.tabs.tab1")}></Tab>
            <Tab
              eventKey={GANT_LIST}
              title={t("page.calendar.tabs.tab2")}
            ></Tab>
          </Tabs>
        )}

        <div className="mt-5">{renderGantt(enquete, activeId)}</div>
      </Section>
    </>
  )
}
