// User's roles
export const ADMIN = 'admin'
export const BASIC = 'basic'
export const ROOT = 'root'

// User's permissions
export const EDIT_QUESTIONNAIRE = 'editQuestionnaire'
export const ACCESS_PERIMETER = 'accessPerimeter'
export const ROOT_ACCESS = 'rootAccess'
export const ACCESS_RESULT = 'accessResult'
export const ACCESS_QUESTIONNAIRE = 'accessQuestionnaire'

export const PASSWORD_REGEX =
  /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!@#$%^&*-_+=~?]).{8,}/
