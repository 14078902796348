import React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { faArrowToTop } from "@fortawesome/pro-regular-svg-icons"
import { useDropzone } from "react-dropzone"
import { ReactComponent as IconExcel } from "../../../../assets/icons/excel_incon.svg"
import { ReactComponent as IconExcelGrey } from "../../../../assets/icons/excel_incon_gray.svg"
import { useTranslation } from "react-i18next"
import { ButtonWithIcon } from "@olystic/design_system_3"

export default function Dropzone({ onDrop }) {
  const { t } = useTranslation()
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: ".xlsx",
    onDrop: onDrop,
  })

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Card className="bg-light">
      <Card.Body {...getRootProps({ className: "dropzone text-center" })}>
        <input {...getInputProps()} />
        {/*<FontAwesomeIcon icon={faFileCsv} size='4x' className={`${files.length > 0 ? 'text--light-green' : 'text--input'} mb-3`} />*/}
        {files.length > 0 ? <IconExcel /> : <IconExcelGrey />}
        {files.length > 0 ? (
          <>
            <p className="fs">{t("component.dropzone.text1")}</p>
            <p className="fs">
              <a className="text-blue-300" href="./Dropzone#" onClick={open}>
                {t("component.dropzone.text2")}
              </a>
            </p>
          </>
        ) : (
          <>
            <p className="fs">{t("component.dropzone.text3")}</p>
            <p className="fs">{t("common.or")}</p>
            <ButtonWithIcon icon={faArrowToTop} className="" onClick={open}>
              {t("component.dropzone.cta")}
            </ButtonWithIcon>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func,
}
