import { http } from '@olystic/olysticapp'
import { api } from './constant'

const feedback = {
  create(payload) {
    return http.post(`${api}/feedback`, payload)
  },
}

export default feedback
