import React from "react"
import { faFolderPlus, faLock } from "@fortawesome/pro-regular-svg-icons"
import { useTranslation } from "react-i18next"
import { ButtonWithIcon } from "@olystic/design_system_3"

function CreateGroupButton({ onCreate, ...props }) {
  const { t } = useTranslation()
  return (
    <ButtonWithIcon
      className="text-nowrap"
      icon={props.canCreateGroup ? faFolderPlus : faLock}
      onClick={onCreate}
      {...props}
      id="CreateRHGroupButton"
    >
      {t("component.createGroupeButton.cta")}
    </ButtonWithIcon>
  )
}

export default CreateGroupButton
