import { http } from "@olystic/olysticapp"
import { api } from "./constant"

const email = {
  create(email) {
    return http.post(api + 'email/', email)
  },
  update(emailId, email) {
    return http.patch(api + 'email/' + emailId, email)
  },
  search(query) {
    const params = new URLSearchParams(query)
    return http.get(`${api}emails?${params}`)
  },
}

export default email


