import React, { useEffect, useState } from 'react'
import { nanoid } from '@reduxjs/toolkit'
// Redux
import backend from '../../services/backend'
import { useDispatch, useSelector } from 'react-redux'
import { surveySelector } from '../../redux/reducers/surveys'
import {
  waitingSurveySelector,
  addOne,
  updateOne,
} from '../../redux/reducers/waitingSurveys'
// Components
import Scheduler from './Scheduler'
import { SIZE, PERIOD, HEIGHT } from './constants'
import {
  conversionDateToTimestamp,
  conversionDateToPixel,
  conversionTwoDateToPixelWidth,
  timelineRange,
  conversionPositionToTimestamp,
  conversionTimestampToDate,
  linePositionToTimeline,
} from '../../lib/date/conversionDate'
import moment from 'moment'
import { updateDatesWithType } from '../../lib/date/updateDateWithType'
import useScheduler from '../../hooks/useScheduler'
import { getStandardSurveyDates } from '../../lib/surveyDates/surveyDates'
import { fixRelance } from '../../lib/date/fixRelance'
import { useSubscription } from '../../hooks/useSubscription'
import { notification } from '@olystic/design_system_3'
import { doDatesOverlap } from '../../lib/functions'

export default function SchedulerContainer() {
  const { scale, handleChangeLine } = useScheduler()
  const surveys = useSelector((state) => surveySelector.selectAll(state))
  const waitingSurveys = useSelector((state) =>
    waitingSurveySelector.selectAll(state),
  )
  const dispatch = useDispatch()

  const { isFreeSubscription } = useSubscription()

  const [width, setWidth] = useState(SIZE)
  const [months, setMonths] = useState([])
  const [position, setPosition] = useState(0)
  const [min, setMin] = useState(0)
  const [items, setItems] = useState([])

  // Calcul des mois visibles du Scheduler par rapport à la date du jour.
  const begin = Number(conversionDateToTimestamp(moment().startOf('month')))
  const end = Number(
    conversionDateToTimestamp(moment().add(PERIOD, 'months').endOf('month')),
  )
  const range = timelineRange(begin, end)
  const timeInPixel = range / width

  // Redimensionnement du Scheduler par rapport au zoom
  useEffect(() => {
    let coeff = (SIZE * scale) / 100
    setMin(-coeff)
    setWidth(SIZE + coeff)
    if (position <= -coeff) {
      setPosition(-coeff)
    }
  }, [position, scale])

  // Création des 11 mois visibles du Scheduler (taille des mois mis à jour dynamiquement)
  useEffect(() => {
    let refDay = moment().startOf('month')
    let months = []
    for (let i = 1; i <= PERIOD + 1; i++) {
      let nextMonth = moment().startOf('month').add(i, 'months')
      months.push({
        month: refDay,
        width: conversionTwoDateToPixelWidth(
          conversionDateToTimestamp(refDay),
          conversionDateToTimestamp(nextMonth),
          timeInPixel,
        ),
      })
      refDay = nextMonth
    }
    setMonths(months)
  }, [timeInPixel, width])

  // Transformation de la donnée venant de Redux pour créer la représentation visuelle des enquêtes dans le scheduler (enquêtes déjà programmées + enquêtes en attente)
  useEffect(() => {
    const enquetes = [...surveys, ...waitingSurveys]

    let items = []
    let uniqueLines = new Set()

    for (let enquete of enquetes) {
      let formatData = {
        id: enquete._id,
        type: enquete.questionnaire.type,
        name: enquete.name,
        x: conversionDateToPixel(
          conversionDateToTimestamp(enquete.dates.Lancement),
          begin,
          end,
          width,
          position,
        ),
        width: conversionTwoDateToPixelWidth(
          conversionDateToTimestamp(enquete.dates.Lancement),
          conversionDateToTimestamp(enquete.dates.Clôture),
          timeInPixel,
        ),
        isNewSurvey: enquete.isNewSurvey || null,
        nbQuestions: enquete.nbQuestions,
      }

      formatData.line = linePositionToTimeline(items, formatData)
      formatData.y = formatData.line * HEIGHT

      items.push(formatData)
      uniqueLines.add(formatData.line)
    }

    const lines = [...uniqueLines]
    handleChangeLine(lines[lines.length - 1] || 1)
    setItems(items)
  }, [
    width,
    position,
    surveys,
    waitingSurveys,
    handleChangeLine,
    begin,
    end,
    timeInPixel,
  ])

  function handleDragOver(e) {
    const isQuestionnaire = e.dataTransfer.types.includes('text/questionnaire')
    isQuestionnaire && e.preventDefault()
  }

  // Dépôt d'une enquête dans le scheduler : create + update
  async function handleDrop(e) {
    const id = e.dataTransfer.getData('text/questionnaire')
    const name = e.dataTransfer.getData('text/questionnaireName')
    const type = e.dataTransfer.getData('text/questionnaireType')
    const nbQuestions = e.dataTransfer.getData('text/nbQuestions')

    const survey = surveys.find((s) => s._id === id)
    const waitingSurvey = waitingSurveys.find((s) => s._id === id)
    const currentPosition = e.clientX - 45 + Math.abs(position)
    const tomorrow = moment()
      .add(1, 'days')
      .set({ hour: 10, minute: 0, second: 0 })
      .format()
    const date = conversionTimestampToDate(
      conversionPositionToTimestamp(currentPosition, begin, timeInPixel),
    )

    const launch = moment(date).isBefore(tomorrow) ? tomorrow : date

    // const { duration, unit } = DURATION_BY_TYPE[type]

    if (isFreeSubscription) {
      // Vérification si les dates se chevauchent avec une enquête existante
      const dates = getStandardSurveyDates(launch, type)
      const overlap = surveys.concat(waitingSurveys).some((existingSurvey) => {
        return doDatesOverlap(
          dates.Lancement,
          dates.Clôture,
          existingSurvey.dates.Lancement,
          existingSurvey.dates.Clôture,
        )
      })

      if (overlap) {
        notification.error(
          'Vous ne pouvez pas placer deux enquêtes sur les mêmes périodes avec un abonnement gratuit.',
          'lg',
        )
        return // On bloque le dépôt de l'enquête
      }
    }

    if (survey) {
      let diffDays = moment(survey.dates.Clôture).diff(
        moment(survey.dates.Lancement),
        'days',
      )
      let dates = updateDatesWithType(
        survey.questionnaire.type,
        moment(launch),
        null,
        diffDays,
      )
      try {
        await backend.updateSurvey(survey._id, { dates: fixRelance(dates) })
      } catch (error) {
        console.error(error)
      }
    } else if (waitingSurvey) {
      let diffDays = moment(waitingSurvey.dates.Clôture).diff(
        moment(waitingSurvey.dates.Lancement),
        'days',
      )
      let dates = updateDatesWithType(
        waitingSurvey.questionnaire.type,
        moment(launch),
        null,
        diffDays,
      )
      dispatch(updateOne({ id: waitingSurvey._id, changes: { dates: dates } }))
    } else {
      dispatch(
        addOne({
          _id: nanoid(),
          name: name,
          questionnaire: { id: id, type: type },
          dates: getStandardSurveyDates(launch, type),
          // dates: { Lancement: launch, Clôture: moment(launch).add(duration, unit).format() },
          isNewSurvey: true,
          nbQuestions: Number(nbQuestions),
          isValid: false,
        }),
      )
    }
  }

  return (
    <Scheduler
      onDrop={handleDrop}
      onChangePosition={setPosition}
      width={width}
      months={months}
      position={position}
      min={min}
      items={items}
      onDragOver={handleDragOver}
    />
  )
}
