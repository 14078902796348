import { Scrollbar } from '@olystic/design_system_3'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ImportSetting({ setting, confirm, onConfirm }) {
  const { t } = useTranslation()

  if (!setting) {
    return <p>{t('component.importSetting.noSetting')}</p>
  }

  return (
    <div>
      <h4>{t('component.importSetting.heading')}</h4>
      <p className='pb-3 text-black-50'>{t('component.importSetting.text')}</p>

      <Scrollbar style={{ maxHeight: '40rem', paddingRight: '1rem' }}>
        <Row className='mt-3'>
          {setting.variables.map((variable, index) => (
            <Col sm='6' key={index} className='mb-5'>
              <div className='p-3 border bg-secondary bg-opacity-25'>
                <p className='m-0'>{t(`category.${variable.name}`)}</p>
              </div>
              <div className='d-flex justify-content-between align-items-center border border-1 border-top-0 p-3'>
                {variable.modalities && variable.modalities.length > 0 ? (
                  <div>
                    <p className='mb-2'>{t('component.importSetting.list')}</p>
                    <div className='d-flex align-items-center gap-5 flex-wrap'>
                      {variable.modalities.map((modality, modIndex) => (
                        <div
                          key={modIndex}
                          className='text-decoration-underline fw-semibold text-primary'
                        >
                          {modality}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p className='mb-0 fw-semibold'>
                    {['ps_age', 'ps_anciennete'].includes(variable.name)
                      ? t('component.importSetting.date_modalities')
                      : t('component.importSetting.no_modalities')}
                  </p>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Scrollbar>

      <Form.Check
        id='confirm'
        name='confirm'
        className='mb-0 mt-5 fs-lg fw-semibold'
        checked={confirm}
        onChange={onConfirm}
        label={t('component.importSetting.confirm')}
      />
    </div>
  )
}
