import { http } from "@olystic/olysticapp"
import { gc } from "./constant"
import store from "../redux/store"
import { setSteps, updateSteps as updateStepsAction } from "../redux/reducers/steps"

const steps = {
  async getSteps() {
    const res = await http.get(`${gc}/steps`)
    store.dispatch(setSteps(res.data))
    return res.data
  },

  async putSteps(steps) {
    try {
      await http.put(`${gc}/steps`, steps)
      store.dispatch(updateStepsAction(steps))
    } catch (e) {
      console.log(e)
    }
  },
}

export default steps
