import React from 'react'
import PropTypes from 'prop-types'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { Card } from 'react-bootstrap'

HelpingCommunicationCard.propTypes = {
  view: PropTypes.string.isRequired,
}

export default function HelpingCommunicationCard({ view }) {
  const { t } = useTranslation()

  const renderView1 = () => {
    return (
      <>
        <p>{t('component.helpingCommunicationCard.view1.text1')}</p>
        <p>{t('component.helpingCommunicationCard.view1.text2')}</p>
      </>
    )
  }

  const renderView2 = () => {
    return <p>{t('component.helpingCommunicationCard.view2.text1')}</p>
  }

  const renderView3 = () => {
    return (
      <>
        <p>{t('component.helpingCommunicationCard.view3.text1')}</p>
        <p>{t('component.helpingCommunicationCard.view3.text2')}</p>
      </>
    )
  }

  const render = () => {
    switch (view) {
      case 'helper':
        return renderView1()

      case 'download':
        return renderView3()

      default:
        return renderView2()
    }
  }

  return (
    <Card className="color-card border-warning px-6 py-3">
      <div className="d-flex align-items-center fs mb-3">
        <FontAwesomeIcon icon={faInfoCircle} className="me-1 fa-xs" />
        <span>{t('component.helpingCommunicationCard.sub')}</span>
      </div>
      <div>{render()}</div>
    </Card>
  )
}
