import { http } from '@olystic/olysticapp'
import { api } from './constant'

const communication = {
  async getCommunications() {
    return await http.get(`${api}/communications`)
  },
}

export default communication
