import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useRHData from './useRHData'
import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'
import { dateFilterMapping } from '../features/RH/components/RHTable/dateFilterMapping'
import { useTranslation } from 'react-i18next'

export function useStatRH() {
  const { t } = useTranslation()
  const { id } = useParams()
  const base = useQuery(['rhbase', id], () => backend.rh.getBase(id))
  const { respondents } = useRHData(id)

  const data = useMemo(() => {
    const modalities = base.data?.modalities
    const processedData = respondents.data?.processedData || []
    const categoriesList = getCategoryList(processedData)
    const categoriesData = getCategoriesCount(
      processedData,
      categoriesList,
      modalities,
      t,
    )

    for (const category in categoriesData) {
      const values = Object.values(categoriesData[category]['values'])
      values.forEach((value) => {
        value.percent = Math.round(
          (value.effectif / processedData.length) * 100,
        )
        value.quality = getEffectifQuality(value.effectif)
      })
      categoriesData[category]['maxVariablesQuality'] =
        values.length > 12
          ? 'danger'
          : values.length > 8
          ? 'warning'
          : 'success'

      categoriesData[category]['tooBigGapVariables'] = getBigGapModalities(
        Object.entries(categoriesData[category]['values']),
      )
    }

    return categoriesData
  }, [base.data?.modalities, respondents.data?.processedData, t])

  const quality = getGlobalQuality(data)

  return { data, quality }
}

function getEffectifQuality(number) {
  let quality = 'success'
  if (number < 5) quality = 'danger'
  if (number >= 5 && number < 10) quality = 'warning'

  return quality
}

function getCategoryList(respondentList) {
  const notListingCategories = ['ps_nom', 'ps_prenom']

  return respondentList.reduce((acc, respondent) => {
    const rhDataKeys = Object.keys(respondent.rhData)
    const filteredRhDataKeys = rhDataKeys.filter(
      (key) => !notListingCategories.includes(key),
    )

    const filteredKeys = filteredRhDataKeys.filter((key) => {
      if (respondent.rhData[key]?.hasOwnProperty('date'))
        return respondent.rhData[key]?.date !== undefined

      return respondent.rhData[key] !== undefined
    })

    filteredKeys.forEach((key) => acc.add(key))

    return acc
  }, new Set())
}

function getCategoriesCount(respondentList, categoriesList, modalities, t) {
  const categories = respondentList.reduce((acc, respondent) => {
    categoriesList.forEach((cat) => {
      let value
      if (respondent.rhData[cat]?.hasOwnProperty('date')) {
        value = respondent.rhData[cat]?.group || t('undefined')
      } else {
        value = t(respondent.rhData[cat])
      }

      if (!acc[cat]) {
        acc[cat] = {
          values: {},
          maxVariablesQuality: 'success',
          tooBigGapVariables: [],
        }
      }

      if (!acc[cat]['values'][value]) {
        acc[cat]['values'][value] = { effectif: 1 }
      } else {
        acc[cat]['values'][value]['effectif']++
      }
    })

    return acc
  }, {})

  const ageCategories = ['ps_age', 'ps_anciennete']
  ageCategories.forEach((cat) => {
    const map = cat === 'ps_age' ? 'age' : 'seniority'
    if (categories[cat] && modalities[map]) {
      const mapping = dateFilterMapping[map][modalities[map]].labels
      mapping.forEach((label) => {
        if (!Object.keys(categories[cat]['values']).includes(label)) {
          categories[cat]['values'][label] = { effectif: 0, quality: 'success' }
        }
      })
    }
  })

  return categories
}

function getBigGapModalities(categoriesList) {
  const maxEffectif = Math.max(
    ...categoriesList.map(([_, { effectif }]) => effectif),
  )

  // Récupère les modalités qui ont un effectif de plus de 80 % plus faible de l'effectif le plus élevé
  const bigGapModalities = categoriesList
    .filter(([_, { effectif }]) => effectif < maxEffectif * 0.2)
    .map(([category, _]) => category)

  return bigGapModalities
}

function getGlobalQuality(categoriesData) {
  const allQualities = Object.values(categoriesData).flatMap((categoryData) => [
    ...Object.values(categoryData.values).flatMap((v) => v.quality),
    categoryData.maxVariablesQuality,
    categoryData.tooBigGapVariables.length > 0 ? 'warning' : 'success',
  ])

  return allQualities.includes('danger')
    ? 'danger'
    : allQualities.includes('warning')
    ? 'warning'
    : 'success'
}
