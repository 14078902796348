import { http } from '@olystic/olysticapp'
import { schema, normalize } from 'normalizr'
import store from '../redux/store'
import { setAll as setAllQuestionnaire } from '../redux/reducers/questionnaires'
import { setAll as setAllSurvey } from '../redux/reducers/surveys'
import { setTitle } from '../redux/reducers/navigation'
import { gc } from './constant'

const surveySchema = new schema.Entity('surveys', {}, { idAttribute: '_id' })
const questionnaireSchema = new schema.Entity(
  'questionnaires',
  {},
  { idAttribute: '_id' },
)
const respondentSchema = new schema.Entity(
  'respondents',
  {},
  { idAttribute: '_id' },
)
const perimeterSchema = new schema.Entity(
  'perimeter',
  {
    survey: [surveySchema],
    questionnaires: [questionnaireSchema],
    respondents: [respondentSchema], // TODO deprecated
  },
  { idAttribute: '_id' },
)

const perimeter = {
  async getPerimetre() {
    const res = await http.get(`${gc}/perimetre`)
    store.dispatch(setTitle(res.data.name))
    const norm = normalize(res.data, perimeterSchema)
    norm.entities.questionnaires &&
      store.dispatch(setAllQuestionnaire(norm.entities.questionnaires))
    norm.entities.surveys && store.dispatch(setAllSurvey(norm.entities.surveys))
    return res
  },
}

export default perimeter
