import React, { useState } from 'react'
import PropTypes from 'prop-types'
// Redux
import { useDispatch } from 'react-redux'
import { removeMany, removeOne } from '../../../redux/reducers/waitingSurveys'
// Components
import { Button, Col, Row, Offcanvas } from 'react-bootstrap'
import ProgrammingValidationTable from './ProgrammingValidationTable'
import SuccesValidationModal from '../SuccesValidationModal/SuccesValidationModal'
import ErrorProgrammingModal from '../ErrorProgrammingModal/ErrorProgrammingModal'
import ConfirmValidationModal from '../ConfirmValidationModal/ConfirmValidationModal'
// icons
import { ReactComponent as NoDataLogo } from '../../../assets/icons/no-data-logo.svg'
// Translation
import { useTranslation } from 'react-i18next'
import { ConfirmDeleteModal } from '@olystic/design_system_3'

const INITIAL_STATE_VALID_MODAL = {
  showNoSelectedSurveyModal: false,
  showConfirmValidationModal: false,
  validatedSurveys: [],
  deletedSurveys: [],
}

export default function ProgrammingValidation({ show, onHide, data, columns }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedSurvey, setSelectedSurvey] = useState([])
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    surveys: [],
    plural: false,
  })
  const [validModal, setValidModal] = useState(INITIAL_STATE_VALID_MODAL)
  const [showSuccesModal, setShowSuccesModal] = useState(false)
  const [countSurveys, setCountSurveys] = useState({})

  function handleDeleteSurvey(id) {
    setDeleteModal({ ...deleteModal, show: true, surveys: [id] })
  }

  function handleDeleteAllSurveys() {
    setDeleteModal({
      show: true,
      surveys: data.map((d) => d.id),
      plural: data.length > 1 ? true : false,
    })
  }

  function handleConfirmeDelete(id) {
    if (!id) return

    if (id.length === 1) {
      dispatch(removeOne(...id))
    } else {
      dispatch(removeMany(id))
      // onHide()
    }
    setDeleteModal({ show: false, surveys: [], plural: false })
  }

  function handleValidSurvey() {
    const deletedSurveys = data.filter(
      (item) => selectedSurvey.map((s) => s.id).indexOf(item.id) === -1
    )

    if (!selectedSurvey.length)
      return setValidModal({ ...validModal, showNoSelectedSurveyModal: true })

    setValidModal({
      showNoSelectedSurveyModal: false,
      showConfirmValidationModal: true,
      validatedSurveys: selectedSurvey,
      deletedSurveys: deletedSurveys,
    })
  }

  function handleCloseModale() {
    onHide()
    setShowSuccesModal(true)
  }

  const emptyRender = (
    <Row className="mt-5">
      <Col sm="12" className="text-center">
        <NoDataLogo />
      </Col>
      <Col className="text-center mx-auto mt-5">
        <h4>{t('component.programmingSurveyModal.empty.title')}</h4>
        <p className="fs text-width mx-auto">
          {t('component.programmingSurveyModal.empty.description')}
        </p>
      </Col>
    </Row>
  )

  const tableRender = (
    <div className="mt-5">
      <h4 className="">{t('component.programmingSurveyModal.table.title')}</h4>
      <p className="fs text-width text-secondary mb-7">
        {t('component.programmingSurveyModal.table.description')}
      </p>

      <ProgrammingValidationTable
        data={data}
        columns={columns}
        onDeleteSurvey={handleDeleteSurvey}
        onSelectedSurveys={(ids) => setSelectedSurvey(ids)}
      />

      <div className="d-flex justify-content-center mt-4">
        <Button
          className="me-4"
          variant="danger"
          onClick={handleDeleteAllSurveys}
        >
          {t('component.programmingSurveyModal.table.cta1')}
        </Button>
        <Button variant="success" onClick={handleValidSurvey}>
          {t('component.programmingSurveyModal.table.cta2')}
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement="end"
        className={`w-100 ${
          !data.length ? ' w-lg-50 w-xxl-25' : 'w-xl-75 w-xxl-50 '
        } `}
      >
        <Offcanvas.Header closeButton className="ms-n3"></Offcanvas.Header>
        <Offcanvas.Body>
          {!data.length ? emptyRender : tableRender}
        </Offcanvas.Body>
      </Offcanvas>

      <ConfirmDeleteModal
        modalOpen={deleteModal.show}
        closeModal={() =>
          setDeleteModal({ show: false, surveys: [], plural: false })
        }
        plural={deleteModal.plural}
        contact={false}
        onConfirm={() => handleConfirmeDelete(deleteModal.surveys)}
      />

      <ErrorProgrammingModal
        show={validModal.showNoSelectedSurveyModal}
        onHide={() =>
          setValidModal({
            ...validModal,
            showNoSelectedSurveyModal: false,
          })
        }
      />

      <ConfirmValidationModal
        show={validModal.showConfirmValidationModal}
        onHide={() => setValidModal(INITIAL_STATE_VALID_MODAL)}
        validatedSurveys={validModal.validatedSurveys}
        deletedSurveys={validModal.deletedSurveys}
        closeProgrammingModal={() => handleCloseModale()}
        onCountSurveys={(surveys) => setCountSurveys(surveys)}
      />

      <SuccesValidationModal
        show={showSuccesModal}
        onHide={() => setShowSuccesModal(false)}
        countSurveys={countSurveys}
      />
    </>
  )
}

ProgrammingValidation.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
}

ProgrammingValidation.defaultProps = {
  data: [],
  columns: [],
}
