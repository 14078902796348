import React from "react"
import PropTypes from "prop-types"
// Components
import { Card } from "react-bootstrap"
import GanttListTable from "./Table/GanttListTable"
import { surveyProps } from "@olystic/common/proptypes/survey"
import { Link } from "react-router-dom"
import { ButtonWithIcon } from "@olystic/design_system_3"
import { downloadChart } from "../../../lib/functions"
import InteractionIcons from "../../cards/interactionIcons"
// Icons
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

export default function GanttList({ summary, description, data, columns }) {
  const { t } = useTranslation()
  const ref = React.createRef()

  return (
    <Card className="overflow-auto" ref={ref}>
      <Card.Header
        className="d-none d-xl-flex align-items-center justify-content-between px-4 bg-white border-start border-bottom"
        style={{ marginLeft: "15rem", width: "calc(100%-15rem)", height: "4.5rem" }}
      >
        <div></div>

        <Link to="/">
          <ButtonWithIcon icon={faPlus} variant="" className="p-0 text-primary">
            {t("component.gantt.cta")}
          </ButtonWithIcon>
        </Link>

        <InteractionIcons
          summary={summary}
          description={description}
          download={() => downloadChart(ref.current)}
        />
      </Card.Header>
      <Card.Body className="p-0">
        <GanttListTable data={data} columns={columns} />
      </Card.Body>
    </Card>
  )
}

GanttList.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(surveyProps).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
}
