import moment from 'moment'
import { dateFilterMapping } from './dateFilterMapping'

export function transformRH(base, data) {
  const processedData = data.map((row) => ({
    _id: row._id,
    suivi: row.suivi || '',
    matricule: row.matricule,
    email: row.email,
    rhData: {
      ps_nom: row.rhData?.ps_nom,
      ps_prenom: row.rhData?.ps_prenom,
      ps_status: row.rhData?.ps_status,
      ps_direction: row.rhData?.ps_direction,
      ps_age: ageGroup(
        row.rhData?.ps_age,
        dateFilterMapping['age'][base.modalities.age],
      ),
      ps_manager: manager(row.rhData?.ps_manager),
      ps_anciennete: ageGroup(
        row.rhData?.ps_anciennete,
        dateFilterMapping['seniority'][base.modalities.seniority],
      ),
      ps_metier: row.rhData?.ps_metier,
      ps_service: row.rhData?.ps_service,
      ps_site: row.rhData?.ps_site,
      ps_pays: row.rhData?.ps_pays,

      ps_agence: row.rhData?.ps_agence,
      ps_bu: row.rhData?.ps_bu,
      ps_classification: row.rhData?.ps_classification,
      ps_classification_niv_1: row.rhData?.ps_classification_niv_1,
      ps_classification_niv_2: row.rhData?.ps_classification_niv_2,
      ps_contrat: row.rhData?.ps_contrat,
      ps_departement: row.rhData?.ps_departement,
      ps_division: row.rhData?.ps_division,
      ps_emploi: row.rhData?.ps_emploi,
      ps_entreprise: row.rhData?.ps_entreprise,
      ps_etablissement: row.rhData?.ps_etablissement,
      ps_famille_metiers: row.rhData?.ps_famille_metiers,
      ps_genre: row.rhData?.ps_genre,
      ps_niveau_managerial: row.rhData?.ps_niveau_managerial,
      ps_perimetre: row.rhData?.ps_perimetre,
      ps_plaque: row.rhData?.ps_plaque,
      ps_region: row.rhData?.ps_region,
      ps_sexe: row.rhData?.ps_sexe,
      ps_societe: row.rhData?.ps_societe,
      ps_sortie: sortieGroup(row.rhData?.ps_sortie),
    },
  }))

  const options = processedData.reduce(
    (opt, item) => {
      opt['rhData.ps_agence'].add(item.rhData.ps_agence)
      opt['rhData.ps_bu'].add(item.rhData.ps_bu)
      opt['rhData.ps_classification'].add(item.rhData.ps_classification)
      opt['rhData.ps_classification_niv_1'].add(
        item.rhData.ps_classification_niv_1,
      )
      opt['rhData.ps_classification_niv_2'].add(
        item.rhData.ps_classification_niv_2,
      )
      opt['rhData.ps_contrat'].add(item.rhData.ps_contrat)
      opt['rhData.ps_departement'].add(item.rhData.ps_departement)
      opt['rhData.ps_direction'].add(item.rhData.ps_direction)
      opt['rhData.ps_division'].add(item.rhData.ps_division)
      opt['rhData.ps_emploi'].add(item.rhData.ps_emploi)
      opt['rhData.ps_entreprise'].add(item.rhData.ps_entreprise)
      opt['rhData.ps_etablissement'].add(item.rhData.ps_etablissement)
      opt['rhData.ps_famille_metiers'].add(item.rhData.ps_famille_metiers)
      opt['rhData.ps_genre'].add(item.rhData.ps_genre)
      opt['rhData.ps_metier'].add(item.rhData.ps_metier)
      opt['rhData.ps_niveau_managerial'].add(item.rhData.ps_niveau_managerial)
      opt['rhData.ps_nom'].add(item.rhData.ps_nom)
      opt['rhData.ps_pays'].add(item.rhData.ps_pays)
      opt['rhData.ps_perimetre'].add(item.rhData.ps_perimetre)
      opt['rhData.ps_plaque'].add(item.rhData.ps_plaque)
      opt['rhData.ps_prenom'].add(item.rhData.ps_prenom)
      opt['rhData.ps_region'].add(item.rhData.ps_region)
      opt['rhData.ps_service'].add(item.rhData.ps_service)
      opt['rhData.ps_sexe'].add(item.rhData.ps_sexe)
      opt['rhData.ps_site'].add(item.rhData.ps_site)
      opt['rhData.ps_societe'].add(item.rhData.ps_societe)
      opt['rhData.ps_status'].add(item.rhData.ps_status)
      return opt
    },
    {
      'rhData.ps_direction': new Set(),
      'rhData.ps_age': [
        'undefined',
        ...dateFilterMapping.age[base.modalities.age].labels,
      ],
      'rhData.ps_manager': ['Manager', 'Non-manager'],
      'rhData.ps_anciennete': [
        'undefined',
        ...dateFilterMapping.seniority[base.modalities.seniority].labels,
      ],
      'rhData.ps_metier': new Set(),
      'rhData.ps_service': new Set(),
      'rhData.ps_site': new Set(),
      'rhData.ps_pays': new Set(),
      'rhData.ps_agence': new Set(),
      'rhData.ps_bu': new Set(),
      'rhData.ps_classification': new Set(),
      'rhData.ps_classification_niv_1': new Set(),
      'rhData.ps_classification_niv_2': new Set(),
      'rhData.ps_contrat': new Set(),
      'rhData.ps_departement': new Set(),
      'rhData.ps_division': new Set(),
      'rhData.ps_emploi': new Set(),
      'rhData.ps_entreprise': new Set(),
      'rhData.ps_etablissement': new Set(),
      'rhData.ps_famille_metiers': new Set(),
      'rhData.ps_genre': new Set(),
      'rhData.ps_niveau_managerial': new Set(),
      'rhData.ps_nom': new Set(),
      'rhData.ps_perimetre': new Set(),
      'rhData.ps_plaque': new Set(),
      'rhData.ps_prenom': new Set(),
      'rhData.ps_region': new Set(),
      'rhData.ps_sexe': new Set(),
      'rhData.ps_societe': new Set(),
      'rhData.ps_status': new Set(),
      'rhData.ps_sortie': [
        'undefined',
        'Dans plus de 3 mois',
        'Dans 2 à 3 mois',
        'Dans 1 à 2 mois',
        'Dans moins de 1 mois',
        'Depuis moins de 1 mois',
        'Depuis 1 à 3 mois',
        'Depuis 3 à 6 mois',
        'Depuis plus de 6 mois',
      ],
    },
  )

  function makeOption(s) {
    return [...s].filter((i) => !!i).sort((a, b) => a.localeCompare(b))
  }

  options['rhData.ps_direction'] = makeOption(options['rhData.ps_direction'])
  options['rhData.ps_metier'] = makeOption(options['rhData.ps_metier'])
  options['rhData.ps_service'] = makeOption(options['rhData.ps_service'])
  options['rhData.ps_site'] = makeOption(options['rhData.ps_site'])
  options['rhData.ps_pays'] = makeOption(options['rhData.ps_pays'])

  options['rhData.ps_agence'] = makeOption(options['rhData.ps_agence'])
  options['rhData.ps_bu'] = makeOption(options['rhData.ps_bu'])
  options['rhData.ps_classification'] = makeOption(
    options['rhData.ps_classification'],
  )
  options['rhData.ps_classification_niv_1'] = makeOption(
    options['rhData.ps_classification_niv_1'],
  )
  options['rhData.ps_classification_niv_2'] = makeOption(
    options['rhData.ps_classification_niv_2'],
  )
  options['rhData.ps_contrat'] = makeOption(options['rhData.ps_contrat'])
  options['rhData.ps_departement'] = makeOption(
    options['rhData.ps_departement'],
  )
  options['rhData.ps_direction'] = makeOption(options['rhData.ps_direction'])
  options['rhData.ps_division'] = makeOption(options['rhData.ps_division'])
  options['rhData.ps_emploi'] = makeOption(options['rhData.ps_emploi'])
  options['rhData.ps_entreprise'] = makeOption(options['rhData.ps_entreprise'])
  options['rhData.ps_etablissement'] = makeOption(
    options['rhData.ps_etablissement'],
  )
  options['rhData.ps_famille_metiers'] = makeOption(
    options['rhData.ps_famille_metiers'],
  )
  options['rhData.ps_genre'] = makeOption(options['rhData.ps_genre'])
  options['rhData.ps_metier'] = makeOption(options['rhData.ps_metier'])
  options['rhData.ps_niveau_managerial'] = makeOption(
    options['rhData.ps_niveau_managerial'],
  )
  options['rhData.ps_nom'] = makeOption(options['rhData.ps_nom'])
  options['rhData.ps_pays'] = makeOption(options['rhData.ps_pays'])
  options['rhData.ps_perimetre'] = makeOption(options['rhData.ps_perimetre'])
  options['rhData.ps_plaque'] = makeOption(options['rhData.ps_plaque'])
  options['rhData.ps_prenom'] = makeOption(options['rhData.ps_prenom'])
  options['rhData.ps_region'] = makeOption(options['rhData.ps_region'])
  options['rhData.ps_service'] = makeOption(options['rhData.ps_service'])
  options['rhData.ps_sexe'] = makeOption(options['rhData.ps_sexe'])
  options['rhData.ps_site'] = makeOption(options['rhData.ps_site'])
  options['rhData.ps_societe'] = makeOption(options['rhData.ps_societe'])
  options['rhData.ps_status'] = makeOption(options['rhData.ps_status'])

  return { processedData, filterOptions: options }
}

export const defaultFilterOptions = {
  'rhData.ps_age': [],
  'rhData.ps_agence': [],
  'rhData.ps_anciennete': [],
  'rhData.ps_bu': [],
  'rhData.ps_classification': [],
  'rhData.ps_classification_niv_1': [],
  'rhData.ps_classification_niv_2': [],
  'rhData.ps_contrat': [],
  'rhData.ps_departement': [],
  'rhData.ps_direction': [],
  'rhData.ps_division': [],
  'rhData.ps_emploi': [],
  'rhData.ps_entreprise': [],
  'rhData.ps_etablissement': [],
  'rhData.ps_famille_metiers': [],
  'rhData.ps_genre': [],
  'rhData.ps_manager': [],
  'rhData.ps_metier': [],
  'rhData.ps_niveau_managerial': [],
  'rhData.ps_nom': [],
  'rhData.ps_pays': [],
  'rhData.ps_perimetre': [],
  'rhData.ps_plaque': [],
  'rhData.ps_prenom': [],
  'rhData.ps_region': [],
  'rhData.ps_service': [],
  'rhData.ps_sexe': [],
  'rhData.ps_site': [],
  'rhData.ps_societe': [],
  'rhData.ps_status': [],
  'rhData.ps_sortie': [],
}

export function calcAge(value) {
  if (value == null) return
  const date = moment(value)
  if (date.isValid()) {
    return moment().diff(date, 'year')
  }
}

export function calcDiffInMonth(value) {
  if (value == null) return
  const date = moment(value)
  if (date.isValid()) {
    return date.diff(undefined, 'months')
  }
}

function sortieGroup(date) {
  const diff = calcDiffInMonth(date)
  let group = 'undefined'
  if (diff != null) {
    if (diff < -1000) group = 'undefined'
    else if (diff < -6) group = 'Depuis plus de 6 mois'
    else if (diff <= -3) group = 'Depuis 3 à 6 mois'
    else if (diff <= -1) group = 'Depuis 1 à 3 mois'
    else if (diff <= 0) group = 'Dans moins de 1 mois'
    else if (diff <= 1) group = 'Dans 1 à 2 mois'
    else if (diff <= 2) group = 'Dans 2 à 3 mois'
    else group = 'Dans plus de 3 mois'
  }
  return { age: diff, group, date }
}

function ageGroup(date, groups) {
  const age = calcAge(date)
  let group = 'undefined'
  if (age != null) {
    const { labels, bins } = groups
    for (let i = 0; i < bins.length - 1; i++) {
      if (age >= bins[i] && age <= bins[i + 1]) {
        group = labels[i]
        break
      }
    }
  }
  return { age, group, date }
}

function manager(value) {
  if (value !== undefined) return value ? 'Manager' : 'Non-manager'
}
