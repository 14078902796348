import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  OlysticBar,
  getMenuData,
  useUpdateModal,
} from '@olystic/design_system_3'
import { useAuth } from '@olystic/olysticapp'

function MainNavigation() {
  const { t } = useTranslation('design-system-3')
  const { permissions } = useAuth()
  const { toggleShowUpdateModal } = useUpdateModal()

  const [header, navigationItems] = React.useMemo(() => {
    const menuData = getMenuData(t)
    const header = menuData.header
    const navigationItems = menuData.navigationItems.filter((item) =>
      permissions.includes(item.permission),
    )
    return [header, navigationItems]
  }, [permissions, t])

  return (
    <OlysticBar
      header={header}
      navigationItems={navigationItems}
      onShowUpdateModal={() => toggleShowUpdateModal({ update: true })}
    />
  )
}

export default MainNavigation
