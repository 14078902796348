import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'

export function useCommunication() {
  const communicationQuery = useQuery(['communication'], async () => {
    const res = await backend.communication.getCommunications()
    return res.data
  })

  return { communicationQuery }
}
