// Question type constant
export const SCALE = 0
export const CHARACTER = 1
export const UNIQUE = 2
export const MULTIPLE = 3
export const BLOC_UNIQUE = 4
export const BLOC_MULTIPLE = 5
export const BLOC_NUMERIQUE = 6
export const NUMERIC = 7
export const NPS = 8

// Type questions utilisateur
export const SCALE_USER = 100
export const CHARACTER_USER = 101
export const UNIQUE_USER = 102
export const MULTIPLE_USER = 103
export const NUMERIC_USER = 107
export const NPS_USER = 108

export const CHOICE = [UNIQUE, MULTIPLE, UNIQUE_USER, MULTIPLE_USER, NPS_USER]
export const BLOC = [BLOC_UNIQUE, BLOC_NUMERIQUE, BLOC_MULTIPLE]

export const ORDERED_QUESTION_TYPE = [
  UNIQUE,
  MULTIPLE,
  CHARACTER,
  BLOC_UNIQUE,
  BLOC_MULTIPLE,
  BLOC_NUMERIQUE,
  SCALE,
  NUMERIC,
  NPS,
]
export const ORDERED_USER_QUESTION_TYPE = [
  UNIQUE_USER,
  MULTIPLE_USER,
  CHARACTER_USER,
  NUMERIC_USER,
  SCALE_USER,
  NPS_USER,
]

export const TYPE_UNIQUE = 'unique'
export const TYPE_SCALE = 'scale'
export const TYPE_NUMERIC = 'numeric'
export const TYPE_MULTIPLE = 'multiple'
export const TYPE_UNIQUE_USER = 'unique-user'
export const TYPE_MULTIPLE_USER = 'multiple-user'
export const TYPE_NUMERIC_USER = 'numeric-user'
export const TYPE_SCALE_USER = 'scale-user'
export const TYPE_NPS = 'nps'
export const TYPE_NPS_USER = 'nps-user'

export const BLACKLIST_TYPE = [
  TYPE_SCALE,
  TYPE_NUMERIC,
  TYPE_MULTIPLE,
  TYPE_UNIQUE_USER,
  TYPE_MULTIPLE_USER,
  TYPE_NUMERIC_USER,
  TYPE_SCALE_USER,
  TYPE_NPS,
  TYPE_NPS_USER,
]

export const POLARITY = {
  POSITIVE: 'positive',
  NEUTRAL: 'neutral',
  NEGATIVE: 'negative',
}

export const MAPPING_COLORS = {
  green: '#27AE60',
  yellow: '#FFBA52',
  red: '#EB4040',
  gray: '#8B919F',
}

export const getNpsColor = (value) => {
  let color

  if (value < 5) color = 'red'
  if (value >= 5 && value <= 6) color = 'yellow'
  if (value >= 7 && value <= 8) color = 'gray'
  if (value > 8) color = 'green'

  return color
}

export const getNpsPercentColor = (value) => {
  let color

  if (value <= -30) color = 'red'
  else if (value <= -10) color = 'orange'
  else if (value <= 0) color = 'yellow'
  else if (value < 10) color = 'blue'
  else if (value <= 30) color = 'green'
  else color = 'darkGreen'

  return color
}
