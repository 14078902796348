import { useState } from "react"

export default function useModal(onSuccess) {
  const [show, setShow] = useState(false)

  return {
    onShow: () => setShow(true),
    modalProps: {
      show,
      onHide: () => setShow(false),
      onSuccess: (...props) => {
        setShow(false)
        onSuccess(...props)
      },
    },
  }
}
