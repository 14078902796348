/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'

export const questionnairesProps = PropTypes.shape({
  _id: PropTypes.string,
  archive: PropTypes.bool,
  label: PropTypes.string,
  code: PropTypes.bool,
  method: PropTypes.string,
  name: PropTypes.string,
  nbQuestions: PropTypes.number,
  notAvailable: PropTypes.string,
  notAvailableHeader: PropTypes.string,
  actif: PropTypes.bool,
  textDebut: PropTypes.string,
  textFin: PropTypes.string,
  type: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      indicators: PropTypes.array,
      keyIndicator: PropTypes.bool,
      model: PropTypes.string,
      polarity: PropTypes.string,
      questions: PropTypes.array,
      rank: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      variable: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.string,
      questions: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string,
      variable: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
})
