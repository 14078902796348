import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { surveyProps } from "@olystic/common/proptypes/survey"
// Components
import moment from "moment"
import Gantt from "./Gantt"
import {
  WIDTH,
  POSITION,
  totalNumberofMonths,
  numberOfMonthsBeforeNow,
  totalNumberOfDays,
} from "./constants"

GanttContainer.propTypes = {
  surveys: PropTypes.arrayOf(surveyProps).isRequired,
}

export default function GanttContainer({ surveys, info }) {
  const [scale, setScale] = React.useState(0)
  const [width, setWidth] = React.useState(WIDTH)
  const [position, setPosition] = React.useState(POSITION)
  const [pixelsByDay, setPixelsByDay] = React.useState(
    WIDTH / totalNumberOfDays
  )

  const min = -(width + POSITION)

  const months = useMemo(() => {
    let now = moment()
    let months = []
    months.push(now.subtract(numberOfMonthsBeforeNow, "months").format())
    for (let i = 1; i < totalNumberofMonths; i++) {
      months.push(now.add(1, "months").format())
    }
    return months
  }, [])

  React.useEffect(
    () => {
      setWidth(WIDTH + (WIDTH * scale) / 100)
      if (position <= min) {
        setPosition(min)
      }
      setPixelsByDay(
        WIDTH / totalNumberOfDays + ((WIDTH / totalNumberOfDays) * scale) / 100
      )
    },
    // Le state position est omis volontairement
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scale, min]
  )

  const data = React.useMemo(() => {
    const surveysWithDimensions = []
    const firstDayOfMonth = moment().startOf("month")
    const middleMonth = firstDayOfMonth.daysInMonth() / 2

    const currentSurveys = surveys.filter(
      (s) =>
        moment(s.dates.Clôture).isAfter(moment(months[0])) &&
        moment(s.dates.Lancement).isBefore(moment(months[months.length - 1]))
    )

    for (let survey of currentSurveys) {
      const lancement = moment(survey.dates.Lancement)
      const cloture = moment(survey.dates.Clôture)
      const diff =
        (lancement.diff(firstDayOfMonth, "days") + middleMonth) * pixelsByDay

      const positionSurvey = -POSITION + diff + ((WIDTH / 2) * scale) / 100
      const size = cloture.diff(lancement, "days") * pixelsByDay || pixelsByDay

      surveysWithDimensions.push({ ...survey, positionSurvey, size })
    }
    return surveysWithDimensions
  }, [surveys, scale, pixelsByDay, months])

  return (
    <Gantt
      scale={scale}
      onHandleScale={(e) => setScale(parseInt(e))}
      data={data}
      months={months}
      width={width}
      position={position}
      onPositionChange={(pos) => setPosition(pos)}
      minimum={min}
      pixelsByDay={pixelsByDay}
      summary={info.summary}
      description={info.description}
    />
  )
}
