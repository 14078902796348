import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const stepSlice = createSlice({
  name: "steps",
  initialState: {
    perimeter: null,
    firstConnection: true,
    popupGantt: true,
    rhFile: false,
    mailTechSupp: false,
    communications: false,
  },
  reducers: {
    setSteps: (state, { payload }) => {
      return _.pick(payload, ["perimeter", "firstConnection", "popupGantt", "rhFile", "mailTechSupp", "communications"])
    },
    updateSteps: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setSteps, updateSteps } = stepSlice.actions

export default stepSlice.reducer
