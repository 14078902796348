import React from 'react'
// Components
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
  RadioCustom,
  SubmitButton,
  TextInputFormik,
} from '@olystic/design_system_3'
// Icons
import { ReactComponent as IconBubbles } from './icon-bubbles.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import backend from '../../../services/backend'

const duration = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
]

const initialValues = {
  title: '',
  question: '',
}

// eslint-disable-next-line react/prop-types
function Label({ children }) {
  return (
    <div className="d-flex align-items-center">
      <span className="rounded-circle bg-primary p-1 me-2" />
      {children}
    </div>
  )
}

export default function FormFeedback() {
  const { t } = useTranslation()
  const [durationId, setDurationId] = React.useState(3)

  const feedbackSchema = yup.object().shape({
    title: yup
      .string()
      .max(35, t('component.quickFeedback.title.error.max'))
      .required(t('component.quickFeedback.title.error.required')),
    question: yup
      .string()
      .required(t('component.quickFeedback.question.error.required')),
  })

  const handleChange = (itemIndex) => {
    setDurationId(itemIndex)
  }

  async function handleSubmit(values, actions) {
    try {
      await backend.feedback.create({
        ...values,
        duration: durationId,
      })
      window.location = '/dashboard/feedbacks'
    } catch (e) {
      console.error(e)
    }
    actions.isSubmitting(false)
  }

  const list = [
    'component.quickFeedback_modal.list1',
    'component.quickFeedback_modal.list2',
    'component.quickFeedback_modal.list3',
    'component.quickFeedback_modal.list4',
    'component.quickFeedback_modal.list5',
    'component.quickFeedback_modal.list6',
  ]

  const description = (
    <>
      <div className="fs text-gray-500">
        <p>{t('component.quickFeedback_modal.description1')}</p>
        <p>{t('component.quickFeedback_modal.description2')}</p>
        <ol>
          {list.map((item, i) => (
            <li className="marker-primary" key={i}>
              {t(item)}
            </li>
          ))}
        </ol>
      </div>
    </>
  )

  return (
    <Card className="p-6 mx-auto" style={{ maxWidth: '75rem' }}>
      <Row className="gap-3">
        <Col sm={12} xl={2} className="text-center text-xl-start">
          <IconBubbles />
        </Col>
        <Col>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={feedbackSchema}
          >
            {(formik) => (
              <Form
                onSubmit={formik.handleSubmit}
                className="d-flex gap-3 flex-column"
              >
                <TextInputFormik
                  name="title"
                  label={
                    <Label>{t('component.quickFeedback.title.label')}</Label>
                  }
                  placeholder={t('component.quickFeedback.title.placeholder')}
                  autoComplete="off"
                />

                <TextInputFormik
                  name="question"
                  label={
                    <Label>{t('component.quickFeedback.question.label')}</Label>
                  }
                  placeholder={t(
                    'component.quickFeedback.question.placeholder'
                  )}
                  autoComplete="off"
                  as="textarea"
                  style={{ height: '7rem' }}
                />

                <Form.Group controlId="day" className="mb-8">
                  <Form.Label className="mb-4">
                    <Label>{t('component.quickFeedback.day.label')}</Label>
                  </Form.Label>
                  <div className="d-flex">
                    {duration.map((day) => (
                      <RadioCustom
                        key={day.id}
                        label={day.label}
                        checked={day.id === durationId}
                        id={`duration-${day.id}`}
                        onChange={() => handleChange(day.id)}
                      />
                    ))}
                  </div>
                </Form.Group>

                <div className="d-flex justify-content-between">
                  <Link to="/planifier-feedbacks">
                    <Button variant="secondary">{t('common.cancel')}</Button>
                  </Link>
                  <SubmitButton>
                    {t('component.quickFeedback.create_link')}
                  </SubmitButton>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>

      <div className="position-absolute top-0 end-0 mt-3 me-3 cursor-pointer fa-lg">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Popover className="position-fixed">
              <Popover.Header>
                {t('component.quickFeedback_modal.title')}
              </Popover.Header>
              <Popover.Body>{description}</Popover.Body>
            </Popover>
          }
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-secondary text-primary-hover"
          />
        </OverlayTrigger>
      </div>
    </Card>
  )
}
