import React from 'react'
import PropTypes from 'prop-types'
// Components
import { getDaysBeforeClosing, qualityColor } from '../../lib/functions'
import { Col, Row } from 'react-bootstrap'
import { useParticipation } from '../../hooks/useParticipation'
import { FormatNumber } from '@olystic/design_system_3'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../redux/reducers/surveys'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrakeWarning } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

ResponseRateCard.propTypes = {
  surveyId: PropTypes.string.isRequired,
}

export default function ResponseRateCard({ surveyId }) {
  const { t } = useTranslation()
  const survey = useSelector((state) =>
    surveySelector.selectById(state, surveyId),
  )
  const { rate } = useParticipation(surveyId)
  const variant = qualityColor(rate)

  return (
    <Row
      className={`m-0 rounded-2 bg-${variant}`}
      style={{ padding: '2px', maxWidth: '40rem', minheight: '11rem' }}
    >
      <Col
        sm={12}
        lg={4}
        className='position-relative bg-white center flex-column'
      >
        <span
          className={`position-absolute rounded-circle mt-3 ms-3 top-0 start-0 bg-${variant}`}
          style={{ padding: '0.35rem' }}
        />
        <h1 className={`text-${variant} m-0`}>{FormatNumber(rate, 1, '%')}</h1>
        <p className={`fs text-${variant}`}>
          {t('component.responseRateCard.sub', {
            count: getDaysBeforeClosing(survey.dates.Clôture),
          })}
        </p>
      </Col>
      <Col sm={12} lg={8} className='text-white d-flex gap-3 p-4'>
        <FontAwesomeIcon icon={faBrakeWarning} className='fa-2x mt-3' />
        <div>
          <h4 className='m-0 mb-2'>
            {t(`component.responseRateCard.summary.${variant}`)}
          </h4>
          <p className='m-0'>
            {t(`component.responseRateCard.description.${variant}`)}
          </p>
        </div>
      </Col>
    </Row>
  )
}
