import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import backend from '../../../../services/backend'

const queryOptions = {
  staleTime: 3600,
  retry: 0,
  refetchOnWindowFocus: true,
}

export function useSuivi(respondentId) {
  const queryClient = useQueryClient()
  const q = useQuery(
    ['suivi', respondentId],
    () => backend.rh.getSuivi(respondentId),
    queryOptions,
  )
  const mutation = useMutation({
    mutationFn: (suivi) => backend.rh.setSuivi(respondentId, suivi),
    onSuccess: (data) => {
      queryClient.setQueriesData(['suivi', respondentId], data)
    },
  })

  return {
    query: q,
    mutation,
  }
}
