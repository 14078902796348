export const ANNONCE = 'Annonce de la démarche'
const LANCEMENT = 'lancement'
const CLOTURE = 'cloture'
export const COMMUNICATION = 'Communication des résultats'
const RELANCE1 = 'Relance 1'
const RELANCE2 = 'Relance 2'
const RELANCE3 = 'Relance 3'

export const goodPratices = [
  {
    type: 'Long',
    steps: {
      [ANNONCE]: true,
      [LANCEMENT]: true,
      [RELANCE1]: true,
      [RELANCE2]: true,
      [RELANCE3]: true,
      [CLOTURE]: true,
      [COMMUNICATION]: true,
    },
  },
  {
    type: 'Court',
    steps: {
      [ANNONCE]: false,
      [LANCEMENT]: true,
      [RELANCE1]: true,
      [RELANCE2]: false,
      [RELANCE3]: false,
      [CLOTURE]: true,
      [COMMUNICATION]: true,
    },
  },
]
