import React from "react"
import style from "./CategoriesSelector.module.scss"
import PropTypes from "prop-types"

// Translation
import { useTranslation } from "react-i18next"
import { Scrollbar } from "@olystic/design_system_3"
import { Form } from "react-bootstrap"

CategoriesSelector.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategories: PropTypes.objectOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  maxSize: PropTypes.number.isRequired,
}

export default function CategoriesSelector({ categories, selectedCategories, onSelectionChange, maxSize }) {
  const { t } = useTranslation()
  const [selectAll, setSelectAll] = React.useState(true)

  React.useEffect(() => {
    setSelectAll(selectedCategories.size === maxSize)
  }, [maxSize, selectedCategories])

  const onCategoryChange = (type, checked) => {
    const newSelection = new Set(selectedCategories)
    if (checked) onSelectionChange(newSelection.add(type))
    else {
      newSelection.delete(type)
      onSelectionChange(newSelection)
    }
  }

  const onSelectAll = (checked) => {
    if (checked) onSelectionChange(new Set(categories))
    else onSelectionChange(new Set())
  }

  return (
    <div className={style.container}>
      <Scrollbar style={{ maxHeight: "23rem", marginRight: "-1.2rem" }}>
        <Form.Check
          checked={selectAll}
          onChange={() => onSelectAll(!selectAll)}
          label={t("component.participationRate.categories.selector_all")}
          className="mb-3"
        />

        {categories.map((category, i) => (
          <div key={i}>
            <Form.Check
              checked={selectedCategories.has(category)}
              onChange={(e) => onCategoryChange(category, e.target.checked)}
              label={t(`category.${category}`)}
              className="mb-3"
            />
          </div>
        ))}
      </Scrollbar>
    </div>
  )
}
