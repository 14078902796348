import React from 'react'
import backend from '../../../../services/backend'
import { mappingSurvey } from '@olystic/common/survey.mjs'
// Hooks
import useModal from '../../../../hooks/useModal'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
// Components
import Date from '../../../..//components/render/Date'
import DeleteBaseModal from '../DeleteBaseModal/DeleteBaseModal'
import Center from '../Layout/Center'
import { IconButton } from '@olystic/design_system_3'
import { Badge, Card } from 'react-bootstrap'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-duotone-svg-icons'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { faClock as falClock } from '@fortawesome/pro-light-svg-icons'

function RHSurveyItem({ base, onEdit, onDelete }) {
  const { t } = useTranslation()
  const count = useQuery(['rhbase', base._id, 'count'], () =>
    backend.rh.getRespondentCount(base._id),
  )
  const deleteBaseModal = useModal(() => onDelete(base))

  return (
    <Card className='scale-hover fs' style={{ width: '22rem' }}>
      <Card.Header className='border-bottom'>
        <div className='d-flex gap-2'>
          <FontAwesomeIcon icon={faUsers} />
          <div className='flex-grow-1'>
            {t('component.rhBaseItem.nbRespondent_interval', {
              postProcess: 'interval',
              count: count?.data?.count || 0,
            })}
          </div>
          {onDelete && (
            <IconButton
              icon={faTrash}
              onClick={() => deleteBaseModal.onShow()}
              className='text-danger'
            />
          )}
        </div>
      </Card.Header>
      <Card.Body className='text-center px-2 pt-4 pb-0'>
        <Center
          className='gap-2 cursor-pointer mb-3'
          onClick={() => onEdit(base)}
        >
          <div className='h4 text-center mb-0 text-underline-hover'>
            {base.name}
          </div>
        </Center>
        <div>
          <h4 className='fs-5 fw-normal text-blue-500'>
            {base.survey.questionnaire?.name}
          </h4>
          <p className='text-blue-500 fs-sm m-1'>
            {t('common.open')} : <Date>{base.survey.dates.Lancement}</Date> |{' '}
            {t('common.close')}: <Date>{base.survey.dates.Clôture}</Date>
          </p>
        </div>
      </Card.Body>
      <Card.Footer className='bg-dark p-1'>
        <Center className='gap-3 my-2'>
          <Badge bg={mappingSurvey(base.survey.questionnaire.type).color}>
            {t(
              `survey.types.${
                mappingSurvey(base.survey.questionnaire.type).title
              }`,
            )}
          </Badge>

          <Badge bg='purple'>
            {
              <Center>
                <FontAwesomeIcon icon={falClock} className='me-2' />
                {t(
                  `survey.defaultDuration.${
                    mappingSurvey(base.survey.questionnaire.type).duration
                  }`,
                )}
              </Center>
            }
          </Badge>
        </Center>
      </Card.Footer>
      <DeleteBaseModal base={base} {...deleteBaseModal.modalProps} />
    </Card>
  )
}

RHSurveyItem.defaultProps = {
  base: {
    id: '-1',
    count: 0,
    name: '--',
    createdAt: '--',
  },
}

export default RHSurveyItem
