import React from 'react'
import style from './CommBtn.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

export default function CommBtn({
  id,
  title,
  count,
  icon,
  activeView,
  onSelectionView,
}) {
  const { t } = useTranslation()

  return (
    <div
      className={classnames(style.container, {
        [style.activeView]: id === activeView,
      })}
      onClick={() => onSelectionView(id)}
    >
      <div className={style.icon}>{icon}</div>
      <div className={style.reference}>
        <p>
          {count ? (
            count
          ) : id === 'download' ? (
            <FontAwesomeIcon icon={faArrowDown} size="sm" />
          ) : (
            '?'
          )}
        </p>
      </div>
      <p className={style.summary}>
        {t(`component.commNavigation.cta.${title}`)}
      </p>
    </div>
  )
}

CommBtn.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  activeView: PropTypes.string.isRequired,
  onSelectionView: PropTypes.func.isRequired,
}
