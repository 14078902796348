import React from "react"
import PropTypes from "prop-types"
// Components
import { Button, Card } from "react-bootstrap"
import { datesToSurveyEvent, translateSurveyEvent } from "../../lib/surveyEvents"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt as farExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { surveySelector } from "../../redux/reducers/surveys"
import { Link } from "react-router-dom"

const MAPPING_STATE = {
  past: "success",
  now: "warning",
  future: "secondary",
}

export default function FollowUpCard({ surveyId }) {
  const { t } = useTranslation()

  const survey = useSelector((state) => surveySelector.selectById(state, surveyId))
  const surveyEvent = datesToSurveyEvent(survey.dates)

  return (
    <Card className="position-relative" style={{ minHeight: "8rem" }}>
      <Card.Body className="d-flex align-items-center p-4 flex-wrap">
        {surveyEvent.map((event, i) => (
          <div key={event.key} className="d-flex align-items-center me-3">
            {i !== 0 && (
              <div className={`me-3 bg-${MAPPING_STATE[event.state]}`} style={{ width: "1rem", height: "1px" }} />
            )}
            <div
              className={`rounded-circle me-3 bg-${MAPPING_STATE[event.state]}`}
              style={{ padding: "0.37rem" }}
            ></div>
            <div className="ms-2">
              <p className="m-0 fs">{translateSurveyEvent(t, event)}</p>
              <p className="text-blue-600 fs-sm m-0">{event.date.format("DD/MM/YY")}</p>
            </div>
          </div>
        ))}
      </Card.Body>
      <Link to="/suivi-questionnaires">
        <Button
          className="position-absolute top-0 end-0 p-0 text-secondary fs-xsm text-decoration-underline"
          variant=""
        >
          {t("component.currentRateCard.cta")}
          <FontAwesomeIcon icon={farExternalLinkAlt} className="fa-xs mx-1" />
        </Button>
      </Link>
    </Card>
  )
}

FollowUpCard.propTypes = {
  surveyEvent: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      date: PropTypes.objectOf(PropTypes.any).isRequired,
    })
  ).isRequired,
}
