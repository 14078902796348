import React from 'react'
import style from './ImportRHBase.module.scss'
// Components
import { Card, Col, Row } from 'react-bootstrap'
import { DATE_FORMAT } from '../../../../lib/date/useDateFormat'
import useRHData from '../../../../hooks/useRHData'
// Icons
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ButtonWithIcon } from '@olystic/design_system_3'
import classNames from 'classnames'

const getLink = (bool) => {
  switch (bool) {
    case false:
      return 'component.importRHBase.cta1'
    case true:
      return 'component.importRHBase.cta2'
    default:
      return 'component.importRHBase.cta3'
  }
}

export default function ImportRHBase({ upToDate, latestUpload, base }) {
  const { t } = useTranslation()
  const { respondents } = useRHData(base?._id)

  return (
    <Card className={style.container}>
      <Card.Body className='row align-items-center'>
        <Col
          sm={12}
          lg={6}
          xl={12}
          xxl={4}
          className='mb-3 mb-xxl-0 text-center'
        >
          <Link to={`/rh/${base?._id}`}>
            <ButtonWithIcon icon={farExternalLinkAlt}>
              {t(getLink(upToDate))}
            </ButtonWithIcon>
          </Link>
        </Col>
        <Col sm={12} lg={6} xl={12} xxl={8}>
          <Row className='align-items-center'>
            <Col
              className={classNames(
                'text-center mb-2 mb-xl-0',
                style.latestUpload,
              )}
            >
              <p className='mb-1 fs'>{t('component.importRHBase.label1')} :</p>
              <h4>
                {upToDate === undefined
                  ? '--/--/----'
                  : latestUpload.format(t(DATE_FORMAT))}
              </h4>
            </Col>
            <Col className='text-center'>
              <p className='mb-1 fs'>{t('component.importRHBase.label2')} :</p>
              <h4>
                {respondents ? respondents?.data?.processedData.length : '---'}
              </h4>
            </Col>
          </Row>
        </Col>
      </Card.Body>
    </Card>
  )
}
