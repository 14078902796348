/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react"

export const DeleteContext = createContext()

export default function DeleteProvider({ children }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [id, setId] = useState("")

  const toggleShowConfirmModal = (bool) => {
    setShowConfirmModal(bool)
  }

  const handleChangeId = (id) => {
    setId(id)
  }

  return (
    <DeleteContext.Provider value={{ id, handleChangeId, showConfirmModal, toggleShowConfirmModal }}>
      {children}
    </DeleteContext.Provider>
  )
}
