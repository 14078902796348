import React, { useState } from 'react'
import GlobalFilter from '../RHTable/components/GlobalFilter'
import { RHTable } from '../RHTable'
import RHImport from '../RHManagement/RHImport'
import { useRHTable } from '../../../../hooks/useRHTable'
import { useRHImport } from '../../../../hooks/useRHImport'
import { RespondentEditModal } from './RespondentEditModal'
import useModal from '../../../../hooks/useModal'
import CreateBaseModal from '../CreateBaseModal/CreateBaseModal'
import NoData from '../../../../components/NoData/NoData'
import HeaderLayout from '../Layout/HeaderLayout'
import ExportButton from './Buttons/ExportButton'
import ImportButton from './Buttons/ImportButton'
import DeleteAllButton from './Buttons/DeleteAllButton'
import { useEditRespondent } from '../../../../hooks/useEditRespondent'
import CreateRespondentButton from './Buttons/CreateRespondentButton'
import CreateGroupButton from './Buttons/CreateGroupButton'
import { useTranslation } from 'react-i18next'
import { ConfirmDeleteModal, SubscriptionModal } from '@olystic/design_system_3'
import { useSubscription } from '../../../../hooks/useSubscription'
import { RHStats } from '../RHStats/RHStats'

export function RespondentList({ base, rhData, onCreateGroup }) {
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState('')
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({
    show: false,
    id: '',
  })
  const [selectedRespondent, setSelectedRespondent] = useState(null)
  const { respondents, deleteManyRespondent } = rhData
  const { instance, categoriesFilter } = useRHTable(respondents.data || {})
  const { onImport, rhImportProps } = useRHImport(base.id)
  const editRespondent = useEditRespondent(rhData)
  const createBaseModal = useModal(handleCreateGroup)
  const subscriptionModal = useModal()
  const { canCreateGroup, type } = useSubscription()
  const hasSelection = !!instance.selectedFlatRows.length

  function handleShowConfirmDeleteModal(id, respondent) {
    if (respondent) setSelectedRespondent(respondent)
    setShowConfirmDeleteModal({ show: true, id })
  }

  function handleDelete(id) {
    if (id === 'selection') handleDeleteSelection()
    if (id === 'respondent') handleDeleteRespondent(selectedRespondent)
    setShowConfirmDeleteModal({ show: false, id: '' })
  }

  function handleDeleteRespondent(respondent) {
    deleteManyRespondent.mutate([respondent._id])
  }

  function handleCreateGroup({ name }) {
    onCreateGroup({
      name,
      respondents: instance.selectedFlatRows.map((row) => row.original._id),
    })
  }

  function handleDeleteSelection() {
    deleteManyRespondent.mutate(
      instance.selectedFlatRows.map((row) => row.original._id),
    )
  }

  function handleClickButton() {
    return canCreateGroup
      ? createBaseModal.onShow()
      : subscriptionModal.onShow()
  }

  return (
    <>
      <HeaderLayout>
        <HeaderLayout.Group>
          <CreateRespondentButton onEdit={editRespondent.onEdit} />
          <CreateGroupButton
            onCreate={handleClickButton}
            disabled={!hasSelection}
            canCreateGroup={canCreateGroup}
          />
        </HeaderLayout.Group>
        <HeaderLayout.Group>
          <GlobalFilter value={searchString} onChange={setSearchString} />
        </HeaderLayout.Group>

        <HeaderLayout.Group>
          <RHStats baseId={base.id} />
          <ImportButton onImport={onImport} />
          <ExportButton instance={instance} />
          <DeleteAllButton
            disabled={!hasSelection}
            onClick={() => handleShowConfirmDeleteModal('selection')}
          />
        </HeaderLayout.Group>
      </HeaderLayout>
      <RHTable
        instance={instance}
        globalFilter={searchString}
        loading={respondents.isLoading}
        noDataAction={
          <div className='w-50 mx-auto mt-5'>
            <NoData
              title={t('rh.no_data.title')}
              text={t('rh.no_data.text')}
              cta={<ImportButton variant='primary' onImport={onImport} />}
            />
          </div>
        }
        onEdit={editRespondent.onEdit}
        onDelete={(respondent) =>
          handleShowConfirmDeleteModal('respondent', respondent)
        }
      />
      <RHImport {...rhImportProps} />
      <RespondentEditModal
        {...editRespondent.modalProps}
        categories={categoriesFilter}
      />
      <CreateBaseModal type='group' {...createBaseModal.modalProps} />
      <SubscriptionModal {...subscriptionModal.modalProps} type={type} />
      <ConfirmDeleteModal
        modalOpen={showConfirmDeleteModal.show}
        closeModal={() => setShowConfirmDeleteModal(false)}
        plural={instance.selectedFlatRows.length > 1}
        contact={false}
        onConfirm={() => handleDelete(showConfirmDeleteModal.id)}
      />
    </>
  )
}
