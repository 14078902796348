import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RHLayout } from '../components/Layout/RHLayout'
import { Button, Nav } from 'react-bootstrap'
import { RHContainer } from '../components/Layout/RHContainer'
import { RespondentList } from '../components/RespondentsList/RespondentList'
import { useTranslation } from 'react-i18next'
import { useRHBase } from '../../../hooks/useRHBase'
import NoData from '../../../components/NoData/NoData'
import { VIEW_GROUP, VIEW_SONDES } from '../components/Layout/RHPage'
import useRHPageContext from '../../../hooks/useRHPageContext'
import { RHBaseList } from '../components/RHList/RHBaseList'
import debounce from 'lodash/debounce'
import RespondentGroupList from '../components/RespondentsList/RespondentGroupList'
import RespondentSurveyList from '../components/RespondentsList/RespondentSurveyList'
import useRHData from '../../../hooks/useRHData'
import Date from '../../../components/render/Date'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../../redux/reducers/surveys'
import TypeSurvey from '../../../lib/surveyDates/typeEnquetes'
import RespondentIndividuelleList from '../components/RespondentsList/RespondentIndividuelleList'
import ImportReportModal from '../components/RHManagement/ImportReportModal'
import RateCard from '../components/RateCard/RateCard'

function RHDetail() {
  const { t } = useTranslation()
  const {
    details: { view, setView },
  } = useRHPageContext()
  const { id } = useParams()
  const rhData = useRHData(id)
  const base = useRHBase(id)

  const navigate = useNavigate()
  const survey = useSelector((state) =>
    surveySelector.selectById(state, base.isSurvey),
  )

  function handleView(nextView) {
    setView(nextView)
  }

  function handleCreateGroup(payload) {
    base.createGroup.mutate(payload)
    setView(VIEW_GROUP)
  }

  function handleDeleteGroup(group) {
    base.deleteGroup.mutate(group)
  }

  function handleEdit(base) {
    setView(VIEW_SONDES)
    navigate(`/rh/${base._id}`)
  }

  function handleEditName(group) {
    base.updateGroup.mutate(group)
  }

  function handleBack() {
    if (base.isGroup) setView(VIEW_GROUP)
    navigate(-1)
  }

  return (
    <RHLayout>
      <RHLayout.Header
        title={base.base.data?.name}
        navigateBack
        onBack={handleBack}
      >
        {view === VIEW_SONDES && !base.isGroup && base.isSurvey && (
          <div className='d-xl-flex justify-content-xl-between'>
            <div>
              <h5>
                {t('rh.detail.survey_name', {
                  val: survey?.questionnaire?.name,
                })}
              </h5>
              <p className='fs'>
                {t('common.open')} : <Date>{survey?.dates.Lancement}</Date> |{' '}
                {t('common.close')}: <Date>{survey?.dates.Clôture}</Date>
              </p>
            </div>
            <div className='mb-4 mb-xl-n4 top-xl-0 position-relative'>
              <RateCard survey={survey} />
            </div>
          </div>
        )}
        <div className='d-flex justify-content-between'>
          {base.isSurvey || base.isGroup ? (
            <div />
          ) : (
            <Nav
              variant='pills'
              activeKey={view}
              onSelect={handleView}
              className='fs mt-4'
            >
              {[
                {
                  id: VIEW_SONDES,
                  name: t('rh.detail.tabs.tab1', {
                    number: rhData?.respondents?.data?.processedData?.length,
                  }),
                },
                {
                  id: VIEW_GROUP,
                  name: t('rh.detail.tabs.tab2', {
                    number: base?.groups?.data?.length,
                  }),
                },
              ].map((tab) => (
                <Nav.Item key={tab.id}>
                  <Nav.Link eventKey={tab.id}>{t(tab.name)}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          )}
          <div className='d-flex justify-content-end gap-3 align-items-center mb-3'>
            <ImportReport report={base.base.data?.report} />
          </div>
        </div>
      </RHLayout.Header>
      <RHLayout.Body>
        <RHContainer>
          {view === VIEW_SONDES && !base.isGroup && !base.isSurvey && (
            <RespondentList
              base={base}
              rhData={rhData}
              onCreateGroup={handleCreateGroup}
            />
          )}
          {view === VIEW_SONDES && base.isGroup && !base.isSurvey && (
            <RespondentGroupList
              base={base}
              rhData={rhData}
              onCreateGroup={handleCreateGroup}
            />
          )}
          {view === VIEW_SONDES &&
            !base.isGroup &&
            base.isSurvey &&
            (survey.questionnaire.type === TypeSurvey.INDIVIDUELLE ? (
              <RespondentIndividuelleList
                base={base}
                rhData={rhData}
                onCreateGroup={handleCreateGroup}
              />
            ) : (
              <RespondentSurveyList
                base={base}
                rhData={rhData}
                onCreateGroup={handleCreateGroup}
              />
            ))}
          {view === VIEW_GROUP && (
            <GroupView
              groups={base.groups}
              onDelete={handleDeleteGroup}
              onEdit={handleEdit}
              onGoToBase={() => setView(VIEW_SONDES)}
              onEditName={handleEditName}
            />
          )}
        </RHContainer>
      </RHLayout.Body>
    </RHLayout>
  )
}

function ImportReport({ report }) {
  const { t } = useTranslation()
  const [showReport, setShowReport] = useState(false)
  const haveReport = !!report
  return (
    <>
      <div className='text-primary'>
        {t('rh.detail.import.text1')} : <Date>{report?.date}</Date>
      </div>
      <Button
        onClick={() => setShowReport(true)}
        variant='secondary'
        disabled={!haveReport}
      >
        {t('rh.detail.import.cta')}
      </Button>
      <ImportReportModal
        closeModal={() => setShowReport(false)}
        data={report}
        modalOpen={showReport}
        goNextStep={() => {}}
      />
    </>
  )
}

function GroupView({ groups, onDelete, onEdit, onGoToBase, onEditName }) {
  const { t } = useTranslation()
  const [noData, setNodata] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(
    debounce((haveData) => {
      setNodata(!haveData)
    }, 300),
    [],
  )

  useEffect(() => callback(!!groups?.data?.length), [callback, groups])

  if (noData) {
    return (
      <div className='w-xxl-50 mx-auto mt-6'>
        <NoData
          title={t('rh.group_view.no_data.title')}
          text={t('rh.group_view.no_data.text')}
          cta={
            <Button onClick={onGoToBase}>
              {t('rh.group_view.no_data.cta')}
            </Button>
          }
        />
      </div>
    )
  }
  return (
    <RHBaseList
      items={groups.data || []}
      onDelete={onDelete}
      onEdit={onEdit}
      onEditName={onEditName}
    />
  )
}

export default RHDetail
