import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import {
  TYPE_COURT,
  TYPE_LONG,
  TYPE_INDIVIDUELLE,
  TYPE_FEEDBACK,
  TYPE_USER,
} from '@olystic/common/survey.mjs'
import { SurveyCounterCard } from '@olystic/design_system_3'
// Redux
import { useSelector } from 'react-redux'
import { surveySelector } from '../../../redux/reducers/surveys'

const counterSurveyByType = [
  { id: TYPE_LONG, count: 0 },
  { id: TYPE_COURT, count: 0 },
  { id: TYPE_FEEDBACK, count: 0 },
  { id: TYPE_INDIVIDUELLE, count: 0 },
  { id: TYPE_USER, count: 0 },
]

export default function SurveyCounterCardContainer() {
  const surveys = useSelector((state) => surveySelector.selectAll(state))

  const firstSurvey = surveys.sort((a, b) =>
    moment(a?.dates.Lancement).diff(moment(b?.dates.Lancement)),
  )[0]

  const data = React.useMemo(() => {
    const surveysByType = counterSurveyByType.map((a) => ({ ...a }))

    for (let survey of surveys) {
      surveysByType.find(
        (obj) => obj.id === survey.questionnaire.type,
      ).count += 1
    }

    return {
      details: surveysByType,
      total: {
        count: surveys.length,
        date: moment(firstSurvey?.dates?.Lancement)
          .locale('fr')
          .format('MMMM YYYY'),
      },
    }
  }, [firstSurvey?.dates?.Lancement, surveys])

  return <SurveyCounterCard numberOfSurvey={data} />
}
