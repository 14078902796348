import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import { Dropdown, Tab, Tabs } from "react-bootstrap"
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList as farList } from "@fortawesome/pro-regular-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

NavProgressSurvey.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSurveyId: PropTypes.string.isRequired,
  onChangeSurvey: PropTypes.func.isRequired,
}

export default function NavProgressSurvey({ surveys, selectedSurveyId, onChangeSurvey }) {
  const { t } = useTranslation()
  const [firstSurvey, secondSurvey, ...others] = surveys
  const visibleSurveys = [firstSurvey, secondSurvey]

  const iconClasses = classnames("fa-lg text-primary-hover", {
    "text-primary": others.find((o) => o._id === selectedSurveyId),
  })

  return (
    <div className="d-flex align-items-center gap-2">
      <p className="text-primary fs-lg m-0 me-4">{t("component.navProgressSurvey.title")} :</p>

      <Tabs variant="pills" activeKey={selectedSurveyId} onSelect={onChangeSurvey} className="fs">
        {visibleSurveys.map((survey) => (
          <Tab
          tabClassName="py-3"
            key={survey._id}
            eventKey={survey._id}
            title={`${survey.name} ${survey.target ? "- " + survey.target : ""}`}
          />
        ))}
      </Tabs>

      {others.length > 0 && (
        <Dropdown className="d-flex align-items-center ps-3 py-1 cursor-pointer border-start">
          <Dropdown.Toggle as="div" bsPrefix="dropdown-toggle-nav-survey">
            <FontAwesomeIcon icon={farList} className={iconClasses} />
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: "18.75rem" }}>
            {others.map((survey) => (
              <Dropdown.Item
                key={survey._id}
                onClick={() => onChangeSurvey(survey._id)}
                active={selectedSurveyId === survey._id}
              >
                {survey.name} {survey.target && "- " + survey.target}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}
