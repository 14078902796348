/* TYPES */

// defini par le backend
export const TYPE_LONG = 'Long'
export const TYPE_COURT = 'Court'
export const TYPE_FEEDBACK = 'feedback'
export const TYPE_INDIVIDUELLE = 'Individuelle'
export const TYPE_USER = 'Utilisateur'
// Liste des différents types de survey
const SURVEY_TYPES = [TYPE_LONG, TYPE_COURT, TYPE_INDIVIDUELLE, TYPE_USER]

const TYPE_LONG_INFO = { title: TYPE_LONG, color: 'info', duration: '3 weeks' }
const TYPE_COURT_INFO = {
  title: TYPE_COURT,
  color: 'warning',
  duration: '1 week',
}
const TYPE_FEEDBACK_INFO = {
  title: TYPE_FEEDBACK,
  color: 'purple',
  duration: '3 days',
}
const TYPE_INDIVIDUELLE_INFO = {
  title: TYPE_INDIVIDUELLE,
  color: 'success',
  duration: '1 month',
}
const TYPE_USER_INFO = {
  title: TYPE_USER,
  color: 'pink',
  duration: '1 week',
}
const TYPE_DEFAULT = { title: ' ', color: 'gray', duration: '3 weeks' }

const MAPPING_TYPE_SURVEY = {
  [TYPE_LONG]: TYPE_LONG_INFO,
  [TYPE_COURT]: TYPE_COURT_INFO,
  [TYPE_FEEDBACK]: TYPE_FEEDBACK_INFO,
  [TYPE_INDIVIDUELLE]: TYPE_INDIVIDUELLE_INFO,
  [TYPE_USER]: TYPE_USER_INFO,
}

export function mappingSurvey(type) {
  return MAPPING_TYPE_SURVEY[type] || TYPE_DEFAULT
}

export function surveyType() {
  return SURVEY_TYPES.map((s) => {
    let type = mappingSurvey(s)
    return {
      id: s,
      title: type.title,
      color: type.color,
      duration: type.duration,
    }
  })
}
