import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

const adapter = createEntityAdapter({
  selectId: (item) => item._id,
})

const slice = createSlice({
  name: "respondents",
  initialState: adapter.getInitialState(),
  reducers: {
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    setAll: adapter.setAll,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
  },
})

export const { addOne, addMany, setAll, removeOne, removeMany, updateOne, updateMany, upsertOne, upsertMany } =
  slice.actions

export const selector = adapter.getSelectors((state) => state[slice.name])

export default slice.reducer
