import React from "react"
import PropTypes from "prop-types"
// Components
import moment from "moment"
import 'moment/locale/fr'
import { capitalizeFirstLetter } from "../../../lib/functions"
import useScheduler from "../../../hooks/useScheduler"


SchedulerMonths.propTypes = {
  width: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      month: PropTypes.object,
      width: PropTypes.number,
    })
  ).isRequired,
  onPositionChange: PropTypes.func.isRequired,
  minimum: PropTypes.number.isRequired,
}

export default function SchedulerMonths({ width, position, months, onPositionChange, minimum }) {
  const [currentMouseMoveHandler, setCurrentMouseMoveHandler] = React.useState(() => () => {})

  const { height } = useScheduler()

  let currentPosition = 0

  function handleMouseDown(event) {
    currentPosition = event.clientX
    setCurrentMouseMoveHandler(() => handleMouseMove)
  }

  function handleMouseMove(event) {
    let scroll = position + (event.clientX - currentPosition)
    if (scroll <= 0 && scroll >= minimum) {
      onPositionChange(scroll)
    }
  }

  function handleMouseUp() {
    setCurrentMouseMoveHandler(() => () => {})
  }

  return (
    <div
      className="overflow-hidden position-relative bg--linear-gradient cursor-grab"
      style={{ minHeight: "10rem", height: height / 16 + "rem" }}
    >
      <div
        className="h-100 d-flex position-absolute user-select-none"
        style={{ width: width + "px", left: position + "px" }}
        onMouseMove={(e) => currentMouseMoveHandler(e)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {months.map((m, i) => (
          <div
            className={`${i < months.length - 1 && "border-end-blue"}`}
            key={i}
            style={{ width: `${m.width}px` }}
          >
            <div
              className="opacity-75 bg-dark fs-sm d-flex align-items-center justify-content-center"
              style={{ height: "2rem" }}
            >
              <p className="m-0 text-white">{capitalizeFirstLetter(moment(m.month).locale('fr').format("MMMM"))}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
