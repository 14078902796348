import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'

export function useParticipation(surveyId) {
  const [rate, setRate] = useState(null)
  const [rh, setRH] = useState(null)

  const {
    isLoading,
    data: participation,
    isSuccess,
    isError,
    refetch,
    isFetching,
  } = useQuery(
    ['participation', surveyId],
    () => backend.responseRates.getResponseRates(surveyId),
    { refetchOnWindowFocus: false },
  )

  useEffect(() => {
    if (isSuccess) {
      const lastUpdate = participation?.daily[participation.daily.length - 1]
      const lastRate =
        (lastUpdate?.nbResponse / lastUpdate?.nbRespondent) * 100 || 0
      setRate(lastRate)
      setRH({
        respondent: lastUpdate?.nbRespondent,
        response: lastUpdate?.nbResponse,
      })
    }
  }, [isSuccess, participation])

  return {
    isLoading,
    participation,
    isError,
    refetch,
    rate,
    rh,
    isSuccess,
    isFetching,
  }
}
