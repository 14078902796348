import React from "react"
import { ButtonWithIcon } from "@olystic/design_system_3"
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine"
import { useExportRespondent } from "../../../../../hooks/useExportRespondent"
import { useTranslation } from "react-i18next"

function ExportButton({ instance }) {
  const { t } = useTranslation()
  const exportRespondent = useExportRespondent(instance)
  return (
    <ButtonWithIcon
      variant="secondary"
      icon={faArrowDownToLine}
      onClick={exportRespondent}
      disabled={!instance.rows?.length}
    >
      {t("common.export")}
    </ButtonWithIcon>
  )
}

export default ExportButton
