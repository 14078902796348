import React from 'react'
import { useRHBase } from '../../../../hooks/useRHBase'
import Button from 'react-bootstrap/Button'
import { RespondentSelectionList } from './RespondentSelectionList'
import { useRHTable } from '../../../../hooks/useRHTable'
import useRHData from '../../../../hooks/useRHData'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

function RespondentSelectModal({ baseId, show, onHide, onSuccess }) {
  const { t } = useTranslation()
  const base = useRHBase(baseId)
  const rhData = useRHData(baseId)
  const { instance } = useRHTable(rhData.respondents.data || {})

  function handleSelect() {
    onSuccess(instance.selectedFlatRows)
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName={'modal-autoWidth'}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('component.respondentSelectModal.title', {
            val: base.base?.data?.name,
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RespondentSelectionList
          table={instance}
          loading={rhData.respondents.isLoading}
          rhData={rhData}
          base={base}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelect}>
          {t('component.respondentSelectModal.cta')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RespondentSelectModal
