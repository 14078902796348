import SurveyType from './typeEnquetes'
import { addMonths, differenceInCalendarDays } from 'date-fns'
import { isValid, formatISO, addBusinessDays } from 'date-fns'
import tz from 'date-fns-tz'

const TypeInfo = {
  [SurveyType.COURT]: {
    duration: 5, // in business days
    firstRelance: 2,
    relanceInterval: 2,
    maxRelance: 2,
  },
  [SurveyType.UTILISATEUR]: {
    duration: 5, // in business days
    firstRelance: 2,
    relanceInterval: 2,
    maxRelance: 2,
  },
  [SurveyType.LONG]: {
    duration: 15, // in business days
    firstRelance: 3,
    relanceInterval: 5,
    maxRelance: 3,
  },
  [SurveyType.INDIVIDUELLE]: {
    duration: 20, // in business days
    firstRelance: -1,
    relanceInterval: 0,
    maxRelance: 0,
  },
}

const createRelances = (updatedDates, start, endDate, relances) => {
  if (relances.firstRelance === -1) return
  let n = 1
  let relanceDate = addBusinessDays(start, relances.firstRelance)
  if (differenceInCalendarDays(relanceDate, endDate) === 0) return
  updatedDates[`Relance${n}`] = formatISO(relanceDate)
  while (n < relances.maxRelance) {
    ++n
    relanceDate = addBusinessDays(relanceDate, relances.relanceInterval)
    if (differenceInCalendarDays(relanceDate, endDate) === 0) break
    updatedDates[`Relance${n}`] = formatISO(relanceDate)
  }
}

export function getStandardSurveyDates(
  startDate,
  typeModel,
  zone = 'Europe/Paris',
) {
  const updatedDates = {}
  // let lancement = parseISO(startDate.replace(/T\d{2}:\d{2}:\d{2}/, 'T10:00:00'))
  // const end = parseISO(startDate.replace(/T\d{2}:\d{2}:\d{2}/, 'T22:00:00'))
  let lancement = tz.zonedTimeToUtc(startDate.slice(0, 10) + 'T10:00:00', zone)
  let end = tz.zonedTimeToUtc(startDate.slice(0, 10) + 'T22:00:00', zone)

  if (typeModel === SurveyType.INDIVIDUELLE) {
    end = addMonths(end, 1)
    return {
      Lancement: lancement,
      Clôture: end,
    }
  }

  if (!isValid(lancement)) throw new Error('Invalid launch date')

  updatedDates.Lancement = formatISO(lancement)

  const typeInfo = TypeInfo[typeModel]
  if (!typeInfo) throw new Error('Unknown survey type')

  const endDate = addBusinessDays(end, typeInfo.duration)
  updatedDates.Clôture = formatISO(endDate)
  createRelances(updatedDates, lancement, endDate, typeInfo)

  return updatedDates
}

export function setDatesTime(dates, zone = 'Europe/Paris') {
  const newDates = {}
  for (const key of Object.keys(dates)) {
    let ds = dates[key].slice(0, 10)
    if (key === 'Clôture') ds += 'T22:00:00'
    else ds += 'T10:00:00'
    newDates[key] = tz.zonedTimeToUtc(ds, zone).toISOString()
  }
  return newDates
}
