import { format, formatDistance, formatDistanceStrict, formatRelative } from "date-fns"
import { fr, enGB, es } from "date-fns/locale"
import { useAuth } from "@olystic/olysticapp"

export const DATE_FORMAT = "date_format"

export function useDateFormat() {
  const { user } = useAuth()

  const localeMap = {
    en: enGB,
    fr: fr,
    es: es,
  }

  const userLocal = localeMap[user.language] || fr

  return {
    format(date, formatStr, options) {
      return format(date, formatStr, { locale: userLocal, ...options })
    },
    formatDistance(date, formatStr, options) {
      return formatDistance(date, formatStr, { locale: userLocal, ...options })
    },
    formatDistanceStrict(date, formatStr, options) {
      return formatDistanceStrict(date, formatStr, { locale: userLocal, ...options })
    },
    formatRelative(date, formatStr, options) {
      return formatRelative(date, formatStr, { locale: userLocal, ...options })
    },
  }
}
