import { http } from '@olystic/olysticapp'
import { api } from './constant'

const settings = {
  async search(query) {
    const params = new URLSearchParams(query)
    return await http.get(`${api}/settings/search/?${params}`)
  },
}

export default settings
