/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// Redux
import { useSelector } from 'react-redux'
import { waitingSurveySelector } from '../../../redux/reducers/waitingSurveys'
// Components
import { mappingSurvey } from '@olystic/common/survey.mjs'
import moment from 'moment'
import ProgrammingValidation from './ProgrammingValidation'
// Translation
import { useTranslation } from 'react-i18next'
import { Badge } from 'react-bootstrap'

ProgrammingValidationContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default function ProgrammingValidationContainer({ show, onHide }) {
  const { t } = useTranslation()
  const surveys = useSelector((state) => waitingSurveySelector.selectAll(state))

  const data = useMemo(() => {
    const data = surveys.map((d) => ({
      id: d._id,
      name: d.name,
      type: d.questionnaire.type,
      questionnaireId: d.questionnaire.id,
      dates: d.dates,
      launch: d.dates.Lancement,
      end: d.dates.Clôture,
      respondentGroup: d.respondentGroup,
    }))

    return data
  }, [surveys])

  const columns = useMemo(() => {
    return [
      {
        Header: t('component.programmingSurveyModal.table.col1'),
        accessor: 'type',

        Cell: ({ cell: { value } }) => {
          return (
            <Badge bg={mappingSurvey(value).color}>
              {t(`survey.types.${mappingSurvey(value).title}`)}
            </Badge>
          )
        },
      },
      {
        Header: t('component.programmingSurveyModal.table.col2'),
        accessor: 'name',
      },
      {
        Header: t('component.programmingSurveyModal.table.col3'),
        accessor: 'launch',
        Cell: ({ cell: { value } }) => {
          return moment(value).format('DD/MM/YYYY')
        },
      },
      {
        Header: t('component.programmingSurveyModal.table.col4'),
        accessor: 'end',
        Cell: ({ cell: { value } }) => {
          return moment(value).format('DD/MM/YYYY')
        },
      },
      {
        Header: t('component.programmingSurveyModal.table.col5'),
        accessor: 'respondentGroup.name',
        disableSortBy: true,
      },
    ]
  }, [t])

  return (
    <ProgrammingValidation
      show={show}
      onHide={onHide}
      data={data}
      columns={columns}
    />
  )
}
