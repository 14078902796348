import React from "react"
import PropTypes from "prop-types"
// Components
import { FormGroup, InputGroup, Form } from "react-bootstrap"
import { useFormikContext } from "formik"
// Translation
import { useTranslation } from "react-i18next"

ObjectCommMsg.propTypes = {
  editMode: PropTypes.bool,
  onChangeObject: PropTypes.func,
}

ObjectCommMsg.defaultProps = {
  editMode: false,
  onChangeObject: null,
}

export default function ObjectCommMsg({ editMode, onChangeObject, isLoading }) {
  const { t } = useTranslation()
  const { handleChange, handleBlur, touched, errors, values } = useFormikContext()

  React.useEffect(() => {
    onChangeObject(values.object)
  }, [onChangeObject, values.object])

  return (
    <div>
      <p className="my-3 user-select-none fs-lg">{t("component.communicationMessageCard.form.object.label")} :</p>
      {isLoading && <p>loading...</p>}
      <div>
        {editMode ? (
          <FormGroup controlId="object">
            <InputGroup>
              <Form.Control
                name="object"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.object}
                type="text"
                placeholder={t("component.communicationMessageCard.form.object.placeholder")}
                autoComplete="off"
                isInvalid={touched.object && errors.object}
                className="fs text-blue m-0 user-select-none"
              />
              <Form.Control.Feedback type="invalid">{t(errors.object)}</Form.Control.Feedback>
            </InputGroup>
          </FormGroup>
        ) : (
          <p className="fs-lg text-secondary m-0 user-select-none">{values.object}</p>
        )}
      </div>
    </div>
  )
}
