import React from 'react'
import { Route, Routes } from 'react-router-dom'
import GroundControlPage from '../../components/layout/groundControlPage'
import { useAuth } from '@olystic/olysticapp'
import { ACCESS_QUESTIONNAIRE } from '@olystic/common/user.mjs'
import { Error, Protected } from '@olystic/design_system_3'
// Pages
import Scheduler from '../../pages/scheduler'
import Feedback from '../../pages/feedback'
import Calendar from '../../pages/calendar'
import FollowUp from '../../pages/followUp'
import Parametrage from '../../pages/parametrage'
import Communications from '../../pages/communications'
import RHPage from '../RH/components/Layout/RHPage'
import RHHome from '../RH/pages/RHHome'
import RHDetail from '../RH/pages/RHDetail'
import QuickFeedback from '../../pages/quickFeedback'
import Confirm from '../../pages/confirm'

function Router() {
  const { permissions } = useAuth()

  return (
    <Routes>
      <Route path='unauthorized'>
        <Route path='' element={<Error status={403} />} />
      </Route>
      <Route
        element={
          <Protected
            isAllowed={permissions.includes(ACCESS_QUESTIONNAIRE)}
            redirectPath='/unauthorized'
          />
        }
      >
        <Route path='/' element={<GroundControlPage />}>
          <Route path='planifier-feedbacks' element={<Feedback />} />
          <Route path='creer-feedback' element={<QuickFeedback />} />
          <Route path='calendrier-questionnaires' element={<Calendar />} />
          <Route path='suivi-questionnaires' element={<FollowUp />} />
          <Route path='parametrage-technique' element={<Parametrage />} />

          <Route path='communications'>
            <Route path='confirm/:id' element={<Confirm />} />
            <Route path='' element={<Communications />} />
          </Route>

          <Route path='rh' element={<RHPage />}>
            <Route path=':id' element={<RHDetail />} />
            <Route path='' element={<RHHome />} />
          </Route>

          <Route path='/' element={<Scheduler />} />
          <Route path='*' element={<Error status={404} />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Router
