import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import style from "./CommunicationMessageCard.module.scss"
// Components
import { FormGroup, InputGroup, Form } from "react-bootstrap"
import { ButtonWithIcon } from "@olystic/design_system_3"
import { useFormikContext } from "formik"
// Icons
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

MessageCommMsg.propTypes = {
  editMode: PropTypes.bool,
  onChangeMessage: PropTypes.func,
}

MessageCommMsg.defaultProps = {
  editMode: false,
  onChangeMessage: null,
}

export default function MessageCommMsg({ editMode, onChangeMessage }) {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = React.useState(true)
  const { handleChange, handleBlur, touched, errors, values } = useFormikContext()
  const readMoreAvailable = values.message.length > 400

  React.useEffect(() => {
    onChangeMessage(values.message)
  }, [onChangeMessage, values.message])

  const onReadMoreClick = (e) => {
    e.preventDefault()
    setCollapsed(!collapsed)
  }

  const bToBr = (text) => {
    text = text.replace(/(<([^>]+)>)/gi, "")
    let textArr = text.split("\n")
    return (
      <span>
        {textArr.map((t, i) => {
          return (
            <span key={i} style={{ display: "block" }}>
              {t}
            </span>
          )
        })}
      </span>
    )
  }

  return (
    <div>
      <p className="my-3 user-select-none fs-lg">{t("component.communicationMessageCard.form.message.label")} :</p>
      <div>
        {editMode ? (
          <FormGroup controlId="message">
            <InputGroup>
              <Form.Control
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                type="text"
                as="textarea"
                placeholder={t("component.communicationMessageCard.form.message.placeholder")}
                autoComplete="off"
                isInvalid={touched.message && errors.message}
                className={style.textArea}
              />
              <Form.Control.Feedback type="invalid">{t(errors.message)}</Form.Control.Feedback>
            </InputGroup>
          </FormGroup>
        ) : (
          <>
            <div className={classnames(style.messageCtn, { [style.messageCtnCollapsed]: collapsed })}>
              <p className="fs-lg text-secondary">{bToBr(values.message)}</p>
            </div>
            {readMoreAvailable && (
              <ButtonWithIcon
                variant=""
                icon={collapsed ? faChevronDown : faChevronUp}
                className="fs-sm center mx-auto text-info border-info rounded-4 px-5 py-1 mt-2"
                onClick={onReadMoreClick}
              >
                {collapsed
                  ? t("component.communicationMessageCard.read_more")
                  : t("component.communicationMessageCard.read_less")}
              </ButtonWithIcon>
            )}
          </>
        )}
      </div>
    </div>
  )
}
