import moment from "moment"

// elimine les evenements qui sont apres la cloture
export function fixRelance(dates) {
  const end = moment(dates.Clôture)
  return Object.entries(dates).reduce((newDates, [prop, date]) => {
    if (moment(date).isSameOrBefore(end, "days")) newDates[prop] = date
    return newDates
  }, {})
}
